import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ActingSubmitButton } from 'components/buttons';
import { InputTime, InputText, InputSelect } from 'components/formElements';
import { Form, Error } from 'components/layout';
import { isEmpty, isNull, noop, attendanceActionOptions } from 'utils/index';

const defaultState = {
  values: {
    time: '',
    actionDetails: '',
    action: '',
    reason: '',
  },
  fullName: '',
  date: '',
  didSubmit: false,
};

class UpdateTimesheetEntryForm extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    cancelForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    isLoading: PropTypes.bool,
    data: PropTypes.shape(),
    studentMembers: PropTypes.arrayOf(PropTypes.shape()),
    staffMembers: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.string,
  };

  static defaultProps = {
    isSubmitting: false,
    isLoading: false,
    data: {},
    studentMembers: [],
    staffMembers: [],
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable react/no-did-update-set-state */
    const { data: oldData } = prevProps;
    const { data } = this.props;
    if (!data || isEmpty(data)) return;
    if (data !== oldData) {
      const { firstName, lastName, action, actionDetails, reason, m } = data;
      this.setState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          time: m.format('HH:mm'),
          actionDetails,
          reason: reason || '',
          action,
        },
        fullName: `${firstName} ${lastName}`,
        date: m.format('ddd MM/DD/Y'),
      }));
    }
    /* eslint-enable react/no-did-update-set-state */
  }

  onSubmit = () => {
    const { submitForm, data } = this.props;
    const { values } = this.state;
    const { date } = data;
    submitForm({
      ...values,
      date,
      isManualEntry: true,
    });
  };

  onFieldChange = (e) => {
    const { id: formId } = this.props;
    const { id, value } = e.target;
    const fieldId = id.replace(`${formId}-`, '');

    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [fieldId]: value,
      },
    }));
  };

  reset = () => {
    this.setState(() => ({
      ...defaultState,
    }));
  };

  render() {
    const { id, isLoading, isSubmitting, error, cancelForm } = this.props;
    const { values, date, fullName } = this.state;
    const { time, actionDetails, reason, action } = values;

    const submitProps = {
      btnClassName: 'button',
      actionBtnClassName: 'button button--icon--loading',
      btnText: 'Update Entry',
      actionText: isLoading ? 'Loading...' : 'Saving...',
      isActing: isSubmitting || isLoading,
    };

    return (
      <Form onSubmit={this.onSubmit} className="form--edit-entry">
        {!isSubmitting && !!error && <Error error={error} className="form-error-msg" />}
        <fieldset className="fieldset" disabled={isLoading}>
          <InputText id={`${id}-memberId`} labelText="Name" value={fullName} onChange={noop} isDisabled />
          <div className="wrapper--flex">
            <InputText id={`${id}-date`} labelText="Date" value={date} onChange={noop} isDisabled />
            <InputTime id={`${id}-time`} labelText="Time" onChange={this.onFieldChange} value={time} isRequired />
          </div>
          {/* Allow action to be changed if there is no details */}
          {isNull(actionDetails) && (
            <InputSelect
              id={`${id}-action`}
              labelText="Action"
              options={[{ label: '', value: '' }].concat(attendanceActionOptions)}
              value={action}
              onChange={this.onFieldChange}
              isRequired
            />
          )}
          {/* Action cannot be changed if there is details */}
          {!isNull(actionDetails) && (
            <InputText id={`${id}-action`} labelText="Action" value={action} onChange={noop} isDisabled />
          )}
          {/* Action details can be changed if it exists */}
          {!isNull(actionDetails) && (
            <InputText
              id={`${id}-actionDetails`}
              labelText="Action Details"
              value={actionDetails}
              onChange={this.onFieldChange}
              isRequired
            />
          )}
          <InputText
            id={`${id}-reason`}
            labelText="Reason for Manual Edit"
            value={reason}
            onChange={this.onFieldChange}
          />
        </fieldset>
        <div className="form-actions">
          <Button onClick={cancelForm} className="button--text--cancel">
            Cancel
          </Button>
          <ActingSubmitButton {...submitProps} />
        </div>
      </Form>
    );
  }
}

export default UpdateTimesheetEntryForm;
