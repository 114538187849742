import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getCenterSchedule, updateCenterSchedule } from 'actions';
import { ScheduleForm } from 'components/form';
import { PageFocusview } from 'components/layout';
import { ROUTE_ADMIN_SCHEDULE, SCHEDULE_TYPE_CENTER } from 'constants/index';

const AdminScheduleUpdate = () => {
  const dispatch = useDispatch();

  const { isRequesting, didRequestWithSuccess } = useSelector((state) => state.centerSchedule.update);
  const { isRequesting: isFetching, data: schedule } = useSelector((state) => state.centerSchedule.get);
  useEffect(() => {
    if (!Object.keys(schedule).length) dispatch(getCenterSchedule());
  }, [schedule]);

  if (didRequestWithSuccess) {
    return <Redirect to={ROUTE_ADMIN_SCHEDULE} />;
  }

  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle="Edit Operating Hours"
      closeButtonUrl={ROUTE_ADMIN_SCHEDULE}
      showCloseButton
    >
      {!isFetching ? (
        <ScheduleForm
          submitFn={(values) => dispatch(updateCenterSchedule(values))}
          schedule={schedule}
          isSubmitting={isRequesting}
          cancelUrl={ROUTE_ADMIN_SCHEDULE}
          scheduleType={SCHEDULE_TYPE_CENTER}
        />
      ) : (
        <div className="loading" />
      )}
    </PageFocusview>
  );
};

export default AdminScheduleUpdate;
