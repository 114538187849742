import React, { Component } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import omit from 'lodash/fp/omit';
import { SingleDatePicker } from 'react-dates';
import { SingleDatePickerPhrases } from 'react-dates/src/defaultPhrases';
import SingleDatePickerShape from 'react-dates/src/shapes/SingleDatePickerShape';
import { DATE_PICKER_OPEN_DIRECTION } from 'constants/index';

const HORIZONTAL_ORIENTATION = 'horizontal';
const ANCHOR_LEFT = 'left';

/* eslint-disable react/default-props-match-prop-types */
class SingleDatePickerWrapper extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    initialDate: momentPropTypes.momentObj,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    labelText: PropTypes.string,
    hintText: PropTypes.string,
    openDirection: PropTypes.string,
    appendToBody: PropTypes.bool,
    ...omit(
      ['initialDate', 'required', 'disabled', 'onDateChange', 'onFocusChange', 'openDirection', 'appendToBody'],
      SingleDatePickerShape,
    ),
  };

  static defaultProps = {
    initialDate: null,
    isRequired: false,
    isDisabled: false,
    hintText: null,
    labelText: 'Date',
    onChange: () => {},
    appendToBody: true,
    // input related props
    id: 'date',
    placeholder: 'Date',
    screenReaderInputMessage: '',
    showClearDate: true,
    showDefaultInputIcon: true,
    customInputIcon: null,
    inputIconPosition: 'after',
    block: false,
    small: false,
    regular: true,
    noBorder: false,
    verticalSpacing: undefined,
    keepFocusOnInput: false,
    // calendar presentation and interaction related props
    renderMonthText: null,
    orientation: HORIZONTAL_ORIENTATION,
    anchorDirection: ANCHOR_LEFT,
    openDirection: DATE_PICKER_OPEN_DIRECTION.Down,
    horizontalMargin: 0,
    withPortal: false,
    withFullScreenPortal: false,
    initialVisibleMonth: null,
    numberOfMonths: 1,
    keepOpenOnDateSelect: false,
    reopenPickerOnClearDate: true,
    isRTL: false,
    hideKeyboardShortcutsPanel: true,
    // navigation related props
    navPrev: null,
    navNext: null,
    onPrevMonthClick: () => {},
    onNextMonthClick: () => {},
    onClose: () => {},
    // day presentation and interaction related props
    renderCalendarDay: undefined,
    renderDayContents: null,
    enableOutsideDays: false,
    isDayBlocked: () => false,
    isOutsideRange: () => false,
    isDayHighlighted: () => false,
    // internationalization
    displayFormat: () => moment.localeData().longDateFormat('L'),
    monthFormat: 'MMMM YYYY',
    phrases: SingleDatePickerPhrases,
  };

  constructor(props) {
    super(props);

    this.state = {
      focused: null,
      date: props.initialDate,
    };
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable react/no-did-update-set-state */
    const { initialDate: newDate } = this.props;
    const { initialDate: oldDate } = prevProps;
    if (newDate !== oldDate && newDate) {
      this.setState((prevState) => ({
        ...prevState,
        date: newDate,
      }));
    }
    /* eslint-enable react/no-did-update-set-state */
  }

  onDateChange = (date) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        date,
      }),
      () => {
        const { onChange } = this.props;
        onChange({ date });
      },
    );
  };

  onFocusChange = ({ focused }) => {
    this.setState((prevState) => ({
      ...prevState,
      focused,
    }));
  };

  reset = () => {
    this.setState(() => ({
      focused: null,
      date: null,
    }));
  };

  render() {
    const { isRequired, isDisabled, id, labelText, hintText, openDirection } = this.props;
    const { date, focused } = this.state;
    const props = omit(
      ['id', 'initialDate', 'isRequired', 'isDisabled', 'labelText', 'hintText', 'onChange'],
      this.props,
    );
    const classNames = ['form-element', `form-element--date`];
    if (isRequired) classNames.push('form-element--required');
    if (isDisabled) classNames.push('form-element--disabled');
    if (openDirection === DATE_PICKER_OPEN_DIRECTION.Up) {
      classNames.push('direction--up');
    }

    return (
      <div className={classNames.join(' ')}>
        <label className="label" htmlFor={id}>
          {labelText}
          {isRequired && <span className="required">*</span>}
        </label>
        {hintText && <span className="form-element-hint">{hintText}</span>}
        <SingleDatePicker
          {...props}
          id={id}
          focused={focused}
          date={date}
          onDateChange={this.onDateChange}
          onFocusChange={this.onFocusChange}
          required={isRequired}
          disabled={isDisabled}
        />
      </div>
    );
  }
}
/* eslint-enable */

export default SingleDatePickerWrapper;
