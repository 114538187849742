import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import TableActions from './TableActions';

class Table extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    headerLabels: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        className: PropTypes.string.isRequired,
      }),
    ).isRequired,
    rows: PropTypes.arrayOf(PropTypes.node),
    resultsRow: PropTypes.node,
    className: PropTypes.string,
    tableActions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        classNames: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
      }),
    ),
  };

  static defaultProps = {
    className: 'table',
    rows: [],
    resultsRow: null,
    tableActions: [],
  };

  render() {
    const { className, id, headerLabels, rows, resultsRow, tableActions } = this.props;
    return (
      <div className={`${className} table--${id}`}>
        {!!resultsRow && resultsRow}

        <TableHeader headerLabels={headerLabels} />
        {!!rows.length && rows}

        {!!tableActions && <TableActions actions={tableActions} />}
      </div>
    );
  }
}

export default Table;
