import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';
import { getContacts } from './contactList';

const API_ID = 'CONTACT_UPDATE_';

const contactUpdateRequest = (memberId) => ({
  type: `${API_ID}${API_REQUEST}`,
  payload: {
    id: memberId,
  },
});

const contactUpdateSuccess = (memberId) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id: memberId,
  },
});

const contactUpdateReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const contactUpdateFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const updateContact = (id, data) => (dispatch) => {
  dispatch(contactUpdateRequest());
  const { memberId, ...rest } = data;
  api
    .updateContact(id, {
      ...rest,
    })
    .then(() => {
      dispatch(contactUpdateSuccess(memberId));
      dispatch(getContacts(memberId));
    })
    .then(() => dispatch(contactUpdateReset()))
    .catch((err) => {
      dispatch(contactUpdateFailure(err));
    });
};
