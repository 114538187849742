import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  ROUTE_STUDENT_VIEW,
  SCHEDULE_TYPE_PERSON,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_ADULT_VIEW,
  TYPE_STUDENT,
  TYPE_ADULT,
  TYPE_STAFF,
} from 'constants/index';
import { useSubscription } from 'utils/useSubscription';
import { subscribeToSchedules, unsubscribeFromSchedules, updateSchedule } from 'actions';
import { ScheduleForm } from 'components/form';
import { PageFocusview } from 'components/layout';
import scheduleDefaultDocData from '__data/scheduleDefaultDocData.js';

const addScheduleData = {
  EffectiveDate: null,
  IsArchived: false,
  ...scheduleDefaultDocData,
};

const closeUrl = (recordType, id) => {
  if (recordType === TYPE_STUDENT) {
    return `${ROUTE_STUDENT_VIEW}${id}/schedule/`;
  }
  if (recordType === TYPE_STAFF) {
    return `${ROUTE_STAFFMEMBER_VIEW}${id}/schedule/`;
  }
  return `${ROUTE_ADULT_VIEW}${id}/schedule/`;
};

const ScheduleUpdate = (props) => {
  const { id, scheduleId } = useParams();
  const dispatch = useDispatch();
  const { originType } = props;
  useSubscription(
    {
      subscribeFn: subscribeToSchedules,
      unsubscribeFn: unsubscribeFromSchedules,
      selectorKey: 'schedules',
    },
    false,
    [id],
  );
  const updateScheduleData = useSelector((state) => {
    if (Object.keys(state.schedules.items).length > 0) return state.schedules.items[scheduleId];
    return addScheduleData;
  });
  const { isRequesting, didRequestWithSuccess, requestError } = useSelector((state) => state.schedule.update);

  if (!requestError && didRequestWithSuccess) {
    if (originType === TYPE_STUDENT) {
      return <Redirect to={`${ROUTE_STUDENT_VIEW}${id}/schedule`} />;
    }
    if (originType === TYPE_STAFF) {
      return <Redirect to={`${ROUTE_STAFFMEMBER_VIEW}${id}/schedule`} />;
    }
  }
  const wrapperClassNames = [];
  if (originType === TYPE_STAFF || originType === TYPE_STUDENT) {
    wrapperClassNames.push('width--m');
  } else wrapperClassNames.push('width--s');

  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={wrapperClassNames}
      pageTitle="Edit Schedule"
      closeButtonUrl={closeUrl(originType, id)}
      showCloseButton
    >
      {!didRequestWithSuccess ? (
        <ScheduleForm
          submitFn={(values) => dispatch(updateSchedule(scheduleId, { ...values, memberId: id }))}
          schedule={updateScheduleData}
          isSubmitting={isRequesting}
          cancelUrl={closeUrl(originType, id)}
          scheduleType={SCHEDULE_TYPE_PERSON}
          originType={originType}
        />
      ) : (
        <div className="loading" />
      )}
    </PageFocusview>
  );
};

ScheduleUpdate.propTypes = {
  originType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
};

export default ScheduleUpdate;
