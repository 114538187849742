import {
  TYPE_STUDENT,
  TYPE_STAFF,
  TYPE_ADULT,
  TYPE_STAFF_PATH,
  TYPE_ADULT_PATH,
  TYPE_STUDENT_PATH,
  // PROFILE_ARCHIVED,
  PROFILE_ACTIVE,
  PROFILE_INACTIVE,
} from 'constants/index';
import { round2Precision, isEmpty } from './common';

export const getDisplayHours = (totalHours) => {
  if (totalHours === 0 || totalHours === Infinity) return '0.0';
  const rounded = round2Precision(totalHours, 1);
  return parseFloat(`${rounded}`) % 1 === 0 ? `${rounded}.0` : rounded;
};

export const getProfileStatus = (doc) => {
  if (isEmpty(doc)) return null;
  const { isActive } = doc;
  // if (isArchived) return PROFILE_ARCHIVED;
  return isActive ? PROFILE_ACTIVE : PROFILE_INACTIVE;
};

export const getFormattedAddress = (addr) => {
  if (!addr) return 'N/A';
  const { address, address2, city, zip, state } = addr;
  const fields = [];
  if (address) fields.push(address);
  if (address2) fields.push(address2);
  if (city) fields.push(city);
  if (zip && state) fields.push(`${zip}, ${state}`);
  if (zip && !state) fields.push(zip);
  if (!zip && state) fields.push(state);
  return fields.length ? fields.join(', ') : 'N/A';
};

const dayOfWeekOrder = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

export const sortDaysOfWeek = (a, b) => dayOfWeekOrder[a] - dayOfWeekOrder[b];

export const getRecordTypeFromPath = (type) => {
  switch (type) {
    case TYPE_STAFF_PATH:
      return TYPE_STAFF;
    case TYPE_ADULT_PATH:
      return TYPE_ADULT;
    case TYPE_STUDENT_PATH:
    default:
      return TYPE_STUDENT;
  }
};

export const getRecordPathFromType = (type) => {
  switch (type) {
    case TYPE_STAFF:
      return TYPE_STAFF_PATH;
    case TYPE_ADULT:
      return TYPE_ADULT_PATH;
    case TYPE_STUDENT:
    default:
      return TYPE_STUDENT_PATH;
  }
};
