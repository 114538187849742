import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const API_ID = 'SCHEDULE_ADD_';

const scheduleAddRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const scheduleAddSuccess = (memberId) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id: memberId,
  },
});

const scheduleAddReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const scheduleAddFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const addSchedule = (memberId, data, type) => (dispatch) => {
  dispatch(scheduleAddRequest());
  const { IsInEffect, IsLocked, ...rest } = data;
  api
    .addSchedule({
      ...rest,
      memberId,
      type,
    })
    .then(() => {
      dispatch(scheduleAddSuccess(memberId));
    })
    .then(() => dispatch(scheduleAddReset()))
    .catch((err) => {
      dispatch(scheduleAddFailure(err));
    });
};
