import React from 'react';
import { Route, Switch, Redirect, useParams, useLocation } from 'react-router-dom';
import { subscribeToAdults, unsubscribeFromAdults } from 'actions';
import { PageFocusview, ProfileInfo } from 'components/layout';
import { Tabs, TabContent, TabsNav } from 'components/tabs';
import { PersonalInformationTab, RelatedPartiesTab } from 'components/memberProfiles';
import {
  ROUTE_ADULT_PROFILE,
  ROUTE_ADULT_VIEW,
  PROFILE_TYPE_ADULT,
  TYPE_ADULT,
  ROUTE_ADULT_LIST,
} from 'constants/index';
import { getProfileStatus, isEmpty } from 'utils';
import { useSubscription } from 'utils/useSubscription';

export const AdultView = () => {
  const location = useLocation();
  const { id } = useParams();

  if (!id) return <Redirect to={ROUTE_ADULT_LIST} />;

  const { items, size } = useSubscription({
    subscribeFn: subscribeToAdults,
    unsubscribeFn: unsubscribeFromAdults,
    selectorKey: 'adults',
  });

  const record = size ? items[id] : {};

  const pageTitle = !isEmpty(record) ? `${record.lastName}, ${record.firstName}` : '';
  const profileInfo = <ProfileInfo profileType={PROFILE_TYPE_ADULT} profileStatus={getProfileStatus(record)} />;

  const tabsNavItems = [
    {
      key: 'personalInfo',
      tabUrl: `${ROUTE_ADULT_VIEW}${id}/`,
      tabLabel: 'Personal Information',
      isActive: location.pathname === `${ROUTE_ADULT_VIEW}${id}/`,
    },
    {
      key: 'relatedParties',
      tabUrl: `${ROUTE_ADULT_VIEW}${id}/related-parties/`,
      tabLabel: 'Related Parties',
      isActive: location.pathname.includes('related-parties'),
    },
  ];

  const tabsNav = <TabsNav navItems={tabsNavItems} />;

  return (
    <PageFocusview
      pageTitle={pageTitle}
      pageHeaderClassNames={['header--highlighted']}
      pageTitleChild={profileInfo}
      showCloseButton
      closeButtonUrl={ROUTE_ADULT_LIST}
    >
      <Tabs tabsNav={tabsNav}>
        <Switch>
          <Route exact path={ROUTE_ADULT_PROFILE}>
            <TabContent id="personalInfo" isShowing={location.pathname === `${ROUTE_ADULT_VIEW}${id}/`}>
              <PersonalInformationTab record={record} recordType={TYPE_ADULT} />
            </TabContent>
          </Route>
          <Route exact path={`${ROUTE_ADULT_PROFILE}related-parties/`}>
            <TabContent id="relatedParties" isShowing={location.pathname.includes('related-parties')}>
              <RelatedPartiesTab record={record} recordType={TYPE_ADULT} />
            </TabContent>
          </Route>
        </Switch>
      </Tabs>
    </PageFocusview>
  );
};
