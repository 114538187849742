import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import config from '../conf';
import rootReducer from '../reducers';

const isProd = config.env === 'production';

export default function configureStore(initialState) {
  const logger = createLogger();
  const middlewares = [thunk].concat(!isProd ? [logger] : []);
  const middleware = composeWithDevTools(applyMiddleware(...middlewares));

  const store = createStore(rootReducer, initialState, middleware);

  if (!isProd && module.hot) {
    module.hot.accept('../reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}
