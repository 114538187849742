import { combineReducers } from 'redux';
import { createAPIReducer } from 'utils/index';

const UPDATE_PREFIX = 'SCHEDULE_UPDATE_';
const DELETE_PREFIX = 'SCHEDULE_DELETE_';
const ADD_PREFIX = 'SCHEDULE_ADD_';

const initialAPIState = {
  isRequesting: false,
  didRequestWithSuccess: false,
  requestError: null,
};

export default combineReducers({
  add: createAPIReducer(ADD_PREFIX, initialAPIState),
  update: createAPIReducer(UPDATE_PREFIX, initialAPIState),
  del: createAPIReducer(DELETE_PREFIX, initialAPIState),
});
