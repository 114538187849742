import { createReducer } from 'utils/index';
import {
  TIMESHEET_QUERY_SUBSCRIBE,
  TIMESHEET_QUERY_SUCCESS,
  TIMESHEET_QUERY_FAILURE,
  TIMESHEET_QUERY_UNSUBSCRIBE,
  TIMESHEET_ALL_EXPAND_TOGGLE,
  TIMESHEET_ROW_EXPAND_TOGGLE,
  CHANGE_TENANT,
} from 'constants/index';

const initialState = {
  items: [],
  fetchError: null,
  shouldResubscribe: false,
  searchParams: {},
  isAllMainExpanded: false,
  isAllXeptionExpanded: false,
  toggleExpandId: null,
  toggleExpand: false,
};

export default createReducer(initialState, {
  [TIMESHEET_QUERY_SUBSCRIBE]: () => ({
    ...initialState,
  }),
  [TIMESHEET_QUERY_UNSUBSCRIBE]: () => ({
    ...initialState,
  }),
  [TIMESHEET_QUERY_SUCCESS]: (state, { items, searchParams }) => ({
    ...state,
    items,
    searchParams,
    fetchError: null,
  }),
  [TIMESHEET_QUERY_FAILURE]: (state, { message }) => ({
    ...state,
    items: [],
    fetchError: message,
  }),
  [CHANGE_TENANT]: () => ({
    ...initialState,
    shouldResubscribe: true,
  }),
  [TIMESHEET_ALL_EXPAND_TOGGLE]: (state, { tableId, toggleValue }) => {
    const key = tableId === 'MAIN' ? 'isAllMainExpanded' : 'isAllXeptionExpanded';
    return {
      ...state,
      [key]: toggleValue,
      toggleExpandId: null,
      toggleExpand: null,
    };
  },
  [TIMESHEET_ROW_EXPAND_TOGGLE]: (state, { rowId, toggleValue }) => ({
    ...state,
    toggleExpandId: rowId,
    toggleExpand: toggleValue,
  }),
});
