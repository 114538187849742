export default {
  Monday: {
    IsSelected: false,
    TimeIn: '',
    TimeOut: '',
  },
  Tuesday: {
    IsSelected: false,
    TimeIn: '',
    TimeOut: '',
  },
  Wednesday: {
    IsSelected: false,
    TimeIn: '',
    TimeOut: '',
  },
  Thursday: {
    IsSelected: false,
    TimeIn: '',
    TimeOut: '',
  },
  Friday: {
    IsSelected: false,
    TimeIn: '',
    TimeOut: '',
  },
  Saturday: {
    IsSelected: false,
    TimeIn: '',
    TimeOut: '',
  },
  Sunday: {
    IsSelected: false,
    TimeIn: '',
    TimeOut: '',
  },
};
