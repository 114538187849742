import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  ROUTE_AUTH,
  ROUTE_ADMIN,
  ROUTE_CHOOSE_TENANT,
  ROUTE_TIMESHEET,
  ROUTE_STAFF_LIST,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_STAFFMEMBER_CREATE,
  ROUTE_STAFFMEMBER_UPDATE,
  ROUTE_STUDENT_LIST,
  ROUTE_STUDENT_VIEW,
  ROUTE_STUDENT_CREATE,
  ROUTE_STUDENT_UPDATE,
  ROUTE_ADULT_LIST,
  ROUTE_ADULT_VIEW,
  ROUTE_ADULT_CREATE,
  ROUTE_ADULT_UPDATE,
  ROUTE_RELATIONSHIP_UPDATE,
  ROUTE_RELATIONSHIP_LIST,
  ROUTE_RELATIONSHIP_ADD,
  ROUTE_RELATIONSHIP_SELECT_TYPE,
} from 'constants/index';
import { LauncherGraphic } from 'components/graphic/LauncherGraphic';
import { withStyles } from 'utils/withStyles';
import { withAuth } from 'utils/withAuth';
import { withSelectTenant } from 'utils/withSelectTenant';
import {
  Auth,
  Admin,
  SelectTenant,
  NoMatch,
  Students,
  Student,
  StudentAdd,
  StudentUpdate,
  StaffMembers,
  StaffMember,
  StaffMemberAdd,
  StaffMemberUpdate,
  Adults,
  Adult,
  AdultAdd,
  AdultUpdate,
  RelationshipAdd,
  RelationshipSelect,
  RelationshipList,
  RelationshipUpdate,
  Timesheets,
} from './index';
import 'assets/css/loader-styles.css';

export const AppLauncher = () => {
  const isAppLoading = useSelector((state) => state.ui.appLauncher.isAppLoading);
  if (isAppLoading) {
    return (
      <div className="loader--main">
        <LauncherGraphic />
        <h4 data-text="Loading EasyAdmin">Loading EasyAdmin</h4>
      </div>
    );
  }
  return (
    <Router>
      <Switch>
        <Route path={ROUTE_AUTH} exact component={withStyles(Auth)} />
        <Route path={ROUTE_ADMIN} component={withAuth(withSelectTenant(withStyles(Admin)))} />
        <Route path={ROUTE_CHOOSE_TENANT} exact component={withAuth(withStyles(SelectTenant))} />

        <Route path={ROUTE_TIMESHEET} exact component={withAuth(withSelectTenant(withStyles(Timesheets)))} />

        <Route path={ROUTE_STUDENT_LIST} exact component={withAuth(withSelectTenant(withStyles(Students)))} />
        <Route path={ROUTE_STUDENT_CREATE} exact component={withAuth(withSelectTenant(withStyles(StudentAdd)))} />
        <Route path={ROUTE_STUDENT_UPDATE} exact component={withAuth(withSelectTenant(withStyles(StudentUpdate)))} />
        <Route path={ROUTE_STUDENT_VIEW} component={withAuth(withSelectTenant(withStyles(Student)))} />

        <Route path={ROUTE_ADULT_LIST} exact component={withAuth(withSelectTenant(withStyles(Adults)))} />
        <Route path={ROUTE_ADULT_CREATE} exact component={withAuth(withSelectTenant(withStyles(AdultAdd)))} />
        <Route path={ROUTE_ADULT_UPDATE} exact component={withAuth(withSelectTenant(withStyles(AdultUpdate)))} />
        <Route path={ROUTE_ADULT_VIEW} component={withAuth(withSelectTenant(withStyles(Adult)))} />

        <Route path={ROUTE_STAFF_LIST} exact component={withAuth(withSelectTenant(withStyles(StaffMembers)))} />
        <Route
          path={ROUTE_STAFFMEMBER_CREATE}
          exact
          component={withAuth(withSelectTenant(withStyles(StaffMemberAdd)))}
        />
        <Route
          path={ROUTE_STAFFMEMBER_UPDATE}
          exact
          component={withAuth(withSelectTenant(withStyles(StaffMemberUpdate)))}
        />
        <Route path={ROUTE_STAFFMEMBER_VIEW} component={withAuth(withSelectTenant(withStyles(StaffMember)))} />

        <Route
          exact
          path={ROUTE_RELATIONSHIP_LIST}
          component={withAuth(withSelectTenant(withStyles(RelationshipList)))}
        />
        <Route
          exact
          path={ROUTE_RELATIONSHIP_SELECT_TYPE}
          component={withAuth(withSelectTenant(withStyles(RelationshipSelect)))}
        />
        <Route
          exact
          path={ROUTE_RELATIONSHIP_ADD}
          component={withAuth(withSelectTenant(withStyles(RelationshipAdd)))}
        />
        <Route
          exact
          path={ROUTE_RELATIONSHIP_UPDATE}
          component={withAuth(withSelectTenant(withStyles(RelationshipUpdate)))}
        />

        <Route component={withStyles(NoMatch)} />
      </Switch>
    </Router>
  );
};
