import { combineReducers } from 'redux';
import { LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE } from 'constants/index';
import { createReducer, createAPIReducer } from 'utils/index';

const API_ID = 'CONTACT_';

const initialAPIState = {
  isRequesting: false,
  didRequestWithSuccess: false,
  requestError: null,
  id: null,
};

const initialListState = {
  items: {},
  fetchError: null,
  isFetching: false,
};

export default combineReducers({
  add: createAPIReducer(`${API_ID}ADD_`, initialAPIState),
  update: createAPIReducer(`${API_ID}UPDATE_`, initialAPIState),
  del: createAPIReducer(`${API_ID}DELETE_`, initialAPIState),
  list: createReducer(initialListState, {
    [`${API_ID}${LIST_REQUEST}`]: () => ({
      ...initialListState,
      isFetching: true,
    }),
    [`${API_ID}${LIST_SUCCESS}`]: (state, { items }) => ({
      ...state,
      items,
      isFetching: false,
    }),
    [`${API_ID}${LIST_FAILURE}`]: (_state, { message }) => ({
      items: {},
      fetchError: message,
      isFetching: false,
    }),
  }),
});
