import { createListReducer } from 'utils/index';

const apiID = 'ABSENCE_';

const initialState = {
  items: {},
  fetchError: null,
  isFetching: false,
};

export default createListReducer(apiID, initialState);
