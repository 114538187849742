import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getTenants, updateTenant } from 'actions';
import { PageFocusview } from 'components/layout';
import { AdminInfoForm } from 'components/form';
import { ROUTE_ADMIN } from 'constants/index';

const AdminInfoUpdate = () => {
  const dispatch = useDispatch();

  const { selectedTenant, userTenants } = useSelector((state) => state.auth);
  const isAdmin = userTenants.includes('*');
  const { items: tenants } = useSelector((s) => s.tenants);

  const { isRequesting, didRequestWithSuccess } = useSelector((s) => s.tenant.update);

  useEffect(() => {
    if (!tenants.length) dispatch(getTenants(userTenants, isAdmin));
  }, [tenants]);

  if (didRequestWithSuccess) {
    return <Redirect to={ROUTE_ADMIN} />;
  }

  const currentTenant = tenants.length ? tenants.find((it) => it.id === selectedTenant) : null;

  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle="Edit Center Information"
      closeButtonUrl={ROUTE_ADMIN}
      showCloseButton
    >
      {currentTenant ? (
        <AdminInfoForm
          submitFn={(id, values) => dispatch(updateTenant(id, values))}
          tenant={currentTenant}
          isSubmitting={isRequesting}
          cancelUrl={ROUTE_ADMIN}
        />
      ) : (
        <div className="loading" />
      )}
    </PageFocusview>
  );
};

export default AdminInfoUpdate;
