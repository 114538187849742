import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components/table';

class TableTimesheet extends PureComponent {
  static propTypes = {
    headerLabels: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        className: PropTypes.string.isRequired,
      }),
    ).isRequired,
    id: PropTypes.string,
    rows: PropTypes.arrayOf(PropTypes.node),
    resultsRow: PropTypes.node,
    className: PropTypes.string,
    tableOptions: PropTypes.arrayOf(PropTypes.shape()),
    tableActions: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    id: 'timesheets',
    className: 'table--accordion',
    rows: [],
    resultsRow: null,
    tableOptions: [],
    tableActions: [],
  };

  render() {
    return <Table {...this.props} />;
  }
}

export default TableTimesheet;
