import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeToAdults, unsubscribeFromAdults, notifySms } from 'actions';
import { Form, SubPageHeader, Error } from 'components/layout';
import { TabContent } from 'components/tabs';
import { InputTextarea } from 'components/formElements';
import { ActingSubmitButton } from 'components/buttons';
import { onFieldChange } from 'utils/index';
import { useSubscription } from 'utils/useSubscription';
import { ROUTE_ADMIN_NOTIFY } from 'constants/index';

const sendSMS = (dispatch, action, targets, message) => dispatch(action(targets, message));

const initialFormState = {
  msgTxt: null,
};

const clearForm = (setFormState) => setFormState(initialFormState);

const AdminNotify = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isSending, error } = useSelector((state) => state.sms);
  const [formState, setFormState] = useState(initialFormState);
  const { selectedTenant } = useSelector((state) => state.auth);
  const { items: tenants } = useSelector((state) => state.tenants);
  const { items, size, keys, fetchError } = useSubscription({
    subscribeFn: subscribeToAdults,
    unsubscribeFn: unsubscribeFromAdults,
    selectorKey: 'adults',
  });
  const smsTargets = size ? keys.filter((it) => !!items[it].mobile).map((it) => items[it].mobile) : [];
  const tenant = tenants.find((it) => it.id === selectedTenant);
  const tenantName = tenant ? tenant.name : null;
  const maxLength = tenantName ? 160 - `${tenantName}: `.length : 160;

  const submitProps = {
    btnClassName: 'button',
    actionBtnClassName: 'button button--icon--loading',
    btnText: 'Send SMS',
    actionText: 'Sending...',
    isActing: isSending,
  };

  return (
    <TabContent id="adminNotify" isShowing={location.pathname === ROUTE_ADMIN_NOTIFY}>
      <SubPageHeader title="SMS Notifications" />
      {!!fetchError && <Error error={fetchError} />}
      {!!error && <Error error={error} />}
      <Form
        onSubmit={() => {
          sendSMS(dispatch, notifySms, smsTargets, formState.msgTxt);
          clearForm();
        }}
        className="form--sms"
      >
        <p>
          SMS messages will be delivered to <strong>all</strong> registered, active adults.
        </p>
        <InputTextarea
          id="msgTxt"
          labelText="SMS Text"
          hintText={`Maximum length: ${maxLength}`}
          isRequired
          isDisabled={isSending}
          onChange={(e) => onFieldChange(formState, setFormState, e)}
          maxLength={maxLength}
        />
        <div className="form-actions">
          <ActingSubmitButton {...submitProps} />
        </div>
      </Form>
    </TabContent>
  );
};

export default AdminNotify;
