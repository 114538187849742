import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';
import { getContacts } from './contactList';

const API_ID = 'CONTACT_ADD_';

const contactAddRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const contactAddSuccess = (memberId) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id: memberId,
  },
});

const contactAddReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const contactAddFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const addContact = (memberId, data, type) => (dispatch) => {
  dispatch(contactAddRequest());
  api
    .addContact({
      ...data,
      memberId,
      type,
    })
    .then(() => {
      dispatch(contactAddSuccess(memberId));
      dispatch(getContacts(memberId));
    })
    .then(() => dispatch(contactAddReset()))
    .catch((err) => {
      dispatch(contactAddFailure(err));
    });
};
