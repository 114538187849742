import { api } from 'index';
import { RATIO_LIST_REQUEST, RATIO_LIST_SUCCESS, RATIO_LIST_FAILURE } from 'constants/index';

const ratioListRequest = () => ({
  type: RATIO_LIST_REQUEST,
});

const ratioListSuccess = ({ items }) => ({
  type: RATIO_LIST_SUCCESS,
  payload: {
    items,
  },
});

const ratioListFailure = (message) => ({
  type: RATIO_LIST_FAILURE,
  payload: {
    message,
  },
});

export const getRatios = (state) => (dispatch) => {
  dispatch(ratioListRequest);
  api
    .listRatios(state)
    .then(({ docs }) => {
      const items = docs
        .map((it) => ({ refId: it.id, ...it.data() }))
        .sort((a, b) => a.minMonths - b.minMonths)
        .reduce((acc, next) => {
          return {
            ...acc,
            [next.refId]: next,
          };
        }, {});
      dispatch(
        ratioListSuccess({
          items,
        }),
      );
    })
    .catch((err) => {
      dispatch(ratioListFailure(err));
    });
};
