import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_STUDENT, TYPE_STAFF, TYPE_ADULT } from 'constants/index';
import { EmergencyContacts } from 'components/memberProfiles';

const EmergencyContactsTab = (props) => {
  const { recordType, record } = props;
  // const [fetchError] = useState(record.emergencyContacts ? null : 'No entries available for this party');
  return <EmergencyContacts recordType={recordType} record={record} />;
};

EmergencyContactsTab.propTypes = {
  recordType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
    relationships: PropTypes.shape(),
    emergencyContacts: PropTypes.shape(),
  }).isRequired,
};

export default EmergencyContactsTab;
