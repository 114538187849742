import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'components/buttons';
import { insertIf } from 'utils/index';
import { Toggle } from 'components/formElements';

class PageActions extends PureComponent {
  static propTypes = {
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        classNames: PropTypes.string,
        text: PropTypes.string,
        isVisible: PropTypes.bool.isRequired,
        isLink: PropTypes.bool,
        onClick: PropTypes.func,
        url: PropTypes.string,
        referrer: PropTypes.string,
        type: PropTypes.string,
        toggleProps: PropTypes.object,
      }),
    ),
  };

  static defaultProps = {
    actions: [],
  };

  render() {
    const { actions } = this.props;

    const children = actions
      .filter((it) => it.isVisible)
      .map((it) => {
        const toProps = {
          pathname: it.url,
          ...insertIf(it.isLink, 'state', { referrer: it.referrer }),
        };
        return (
          <li key={it.key}>
            {!it.isLink && it.type !== 'toggle' && (
              <Button className={it.classNames} onClick={it.onClick}>
                {it.text}
              </Button>
            )}
            {it.isLink && it.type !== 'toggle' && (
              <Link className={it.classNames} to={toProps}>
                {it.text}
              </Link>
            )}
            {it.type === 'toggle' && (
              <Toggle
                id={it.toggleProps.id}
                className={it.toggleProps.className}
                labelText={it.toggleProps.label}
                isChecked={it.toggleProps.toggleValue}
                onChange={it.toggleProps.toggleAction}
                isHorizontal={it.toggleProps.isHorizontal}
              />
            )}
          </li>
        );
      });
    return (
      <div className="page-actions">
        <ul>{children}</ul>
      </div>
    );
  }
}

export default PageActions;
