import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/buttons';

class TableRowTimesheet extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    doExpand: PropTypes.func.isRequired,
    doCollapse: PropTypes.func.isRequired,
    openByDefault: PropTypes.bool,
    isFlagged: PropTypes.bool,
    isExpanded: PropTypes.bool,
    children: PropTypes.node,
    totalCount: PropTypes.string,
  };

  static defaultProps = {
    openByDefault: false,
    isFlagged: false,
    isExpanded: false,
    children: null,
    totalCount: '0.0',
  };

  constructor(props) {
    super(props);
    const { openByDefault } = props;
    this.state = {
      isExpanded: openByDefault,
    };
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps) {
    const { isExpanded: externalExpand } = this.props;
    const { isExpanded: externalExpandPrev } = prevProps;
    const { isExpanded: internalExpand } = this.state;
    if (externalExpand !== externalExpandPrev && externalExpand !== internalExpand) {
      this.setState(() => ({ isExpanded: externalExpand }));
    }
  }

  toggle = () => {
    const { id, doExpand, doCollapse } = this.props;
    const { isExpanded: currentStatus } = this.state;
    const shouldExpand = !currentStatus;
    this.setState(
      () => ({
        isExpanded: shouldExpand,
      }),
      () => {
        if (shouldExpand) {
          doExpand(id, shouldExpand);
        } else {
          doCollapse(id, shouldExpand);
        }
      },
    );
  };

  render() {
    const { fullName, children, totalCount, isFlagged, isExpanded } = this.props;
    const rowClassNames = ['table-row'];
    if (isExpanded) rowClassNames.push('expanded');
    if (isFlagged) rowClassNames.push('flag--attention');
    return (
      <ul className={rowClassNames.join(' ')}>
        <li className="tc--name">
          <Button className="button--min" btnType="button" onClick={this.toggle} />
          <span>{fullName}</span>
        </li>
        <li className="tc--data">{!!children && children}</li>
        <li className="tc--total">{totalCount}</li>
      </ul>
    );
  }
}

export default TableRowTimesheet;
