import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { AppLauncher } from './AppLauncher';

const Root = (props) => {
  const { store } = props;
  return (
    <Provider store={store}>
      <AppLauncher />
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.shape().isRequired,
};

export default Root;
