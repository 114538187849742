import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const API_ID = 'TENANT_UPDATE_';

const tenantUpdateRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const tenantUpdateSuccess = (id) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id,
  },
});

const tenantUpdateFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const resetTenantApi = () => ({
  type: `${API_ID}${API_RESET}`,
});

export const updateTenant = (tenantId, data) => (dispatch) => {
  dispatch(tenantUpdateRequest());
  api
    .updateTenantInfo(tenantId, data)
    .then(() => {
      dispatch(tenantUpdateSuccess());
    })
    .catch((err) => {
      dispatch(tenantUpdateFailure(err));
    });
};
