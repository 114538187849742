import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { subscribeToSchedules, unsubscribeFromSchedules, getClassrooms } from 'actions';
import { sortDaysOfWeek } from 'utils';
import { useSubscription } from 'utils/useSubscription';
import { Error, SubPageHeader, PageActions } from 'components/layout';
import {
  ROUTE_STUDENT_VIEW,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_ADULT_VIEW,
  TYPE_STUDENT,
  TYPE_STAFF,
  TYPE_ADULT,
  TYPE_SCHEDULE,
} from 'constants/index';
import { Table, TableRowSchedule, TableRowScheduleData } from 'components/table';

const getAddScheduleLinkUrl = (recordType, id) => {
  if (recordType === TYPE_STUDENT) {
    return `${ROUTE_STUDENT_VIEW}${id}/schedule/add`;
  }
  if (recordType === TYPE_STAFF) {
    return `${ROUTE_STAFFMEMBER_VIEW}${id}/schedule/add`;
  }
  return `${ROUTE_ADULT_VIEW}${id}/schedule/add`;
};

const headerLabels = [{ label: 'Effective Date', className: 'effective-date' }];
const headerLabelsInnerTable = [
  { label: 'Day', className: 'day' },
  { label: 'Time In', className: 'time-in' },
  { label: 'Time Out', className: 'time-out' },
  { label: 'Classroom', className: 'classroom' },
];

const keysToExclude = ['EffectiveDate', 'memberId', 'isArchived', 'type', 'id'];

const getScheduleRows = (keys, items, originType, classrooms) =>
  keys
    .filter((it) => items[it].type === TYPE_SCHEDULE)
    // .sort((a, b) => items[a].EffectiveDate.localeCompare(items[b].EffectiveDate))
    .map((it) => {
      const { EffectiveDate, IsInEffect, IsLocked } = items[it];
      const innerRows = Object.keys(items[it])
        .filter((k) => !keysToExclude.includes(k))
        .filter((k) => items[it][k].IsSelected)
        .sort(sortDaysOfWeek)
        .map((sch) => {
          const d = items[it][sch];
          const { Classroom, ...rest } = d;
          const scheduleData = { ...rest, Day: sch, Classroom: classrooms[Classroom].nickName };
          return <TableRowScheduleData {...scheduleData} key={`${it}-${sch}`} />;
        });
      const innerTable = <Table rows={innerRows} id="schedule" headerLabels={headerLabelsInnerTable} />;
      const effectiveDayData = {
        EffectiveDate,
        IsInEffect,
        IsLocked,
        scheduleId: it,
        children: innerTable,
        originType,
      };
      return <TableRowSchedule {...effectiveDayData} key={it} />;
    });

const Schedules = (props) => {
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { recordType } = props;
  const { items, keys, size, fetchError } = useSubscription(
    {
      subscribeFn: subscribeToSchedules,
      unsubscribeFn: unsubscribeFromSchedules,
      selectorKey: 'schedules',
    },
    true,
    [id],
  );
  const { items: classrooms, fetchError: crFetchError, isFetching: isCRFetching } = useSelector(
    (state) => state.classroom.list,
  );
  const crSize = Object.keys(classrooms).length;

  useEffect(() => {
    if (!size && !isCRFetching) {
      dispatch(getClassrooms());
    }
  }, [crSize, isCRFetching]);

  const actions = [
    {
      key: 'Add-Schedule',
      classNames: 'button--action button--icon--add',
      text: 'Add Schedule',
      isVisible: true,
      isLink: true,
      url: getAddScheduleLinkUrl(recordType, id),
      referrer: location.pathname,
    },
  ];
  const tableProps = {
    id: 'schedules',
    className: 'table--accordion',
    rows: getScheduleRows(keys, items, recordType, classrooms),
    resultsRow: null,
    tableOptions: [],
    tableActions: [],
    headerLabels,
  };
  return (
    <>
      <SubPageHeader title="Schedules" actions={<PageActions actions={actions} />} />
      {!!fetchError && <Error error={fetchError} />}
      {!!crFetchError && <Error error={crFetchError} />}
      {!!size && <Table {...tableProps} />}
    </>
  );
};

Schedules.propTypes = {
  recordType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
};

export default Schedules;
