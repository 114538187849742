import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Button } from 'components/buttons';
import { Modal } from 'components/modal';
import { Error, Form } from 'components/layout';
import { InputText } from 'components/formElements';

export const ReasonForChangeModal = (props) => {
  const { doAction, onClose, isShowing, hide, isRequesting, error, title, text } = props;

  const [reason, updateReason] = useState('');

  if (!isShowing) {
    document.body.classList.remove('has-modal');
  }

  return isShowing
    ? ReactDOM.createPortal(
        <Modal isShowing={isShowing} modalTitle={title} onClose={onClose}>
          {!!error && <Error error={error} />}
          <Form
            onSubmit={() => {
              doAction(reason);
              updateReason('');
            }}
          >
            <InputText
              id="reason"
              value={reason}
              labelText={text}
              isRequired
              onChange={(e) => updateReason(e.target.value)}
            />
            <div className="modal-actions">
              <Button
                className="button--text--cancel"
                onClick={() => {
                  hide();
                }}
                isDisabled={isRequesting}
              >
                Cancel
              </Button>
              <Button className="button--action--neg" isDisabled={isRequesting} btnType="submit">
                Save
              </Button>
            </div>
          </Form>
        </Modal>,
        document.body,
      )
    : null;
};

ReasonForChangeModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  doAction: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onClose: PropTypes.func,
};

ReasonForChangeModal.defaultProps = {
  error: null,
  onClose: null,
};
