import moment from 'moment';
import isAfterDay from 'react-dates/src/utils/isAfterDay';
import humanizeDuration from 'humanize-duration';
import config from 'conf/index';

/**
 * Create DateTime object from date string
 * @param str {String} date string
 * @param fmt {String?} optional overriding date format, defaults to yyyy-MM-dd
 * @returns {moment.Moment} Moment object representing the date
 */
export const stringToMoment = (str, fmt = null) => moment(str, fmt || config.formats.momentDateSystem);

/**
 * Converts datetime to app's time zone and
 * formats to the app's default Date format
 * @param datetime {moment.Moment} ISO datetime string
 * @returns {string} The formatted date
 */
export const getFormattedDate = (m) => m.format(config.formats.momentDate);

/**
 * Converts datetime to app's time zone and
 * formats to the app's default Time format
 * @param datetime {moment.Moment} ISO datetime string
 * @returns {string} The formatted date
 */
export const getFormattedTime = (m) => m.format(config.formats.momentTime);

/**
 * Converts datetime to app's time zone and
 * formats to the app's default Time format
 * @param datetime {moment.Moment} ISO datetime string
 * @returns {string} The formatted date
 */
export const getTodayAsServerDate = () => moment().format(config.formats.momentDateSystem);

export const getAge = (dob) => {
  if (!dob) return 'N/A';
  const today = moment();
  const isProjected = isAfterDay(dob, today);
  if (isProjected) return 0;
  const d = moment.duration(today.diff(dob));
  return humanizeDuration(d.asMilliseconds(), { largest: 2 });
};
