import { api } from 'index';
import {
  TIMESHEET_QUERY_SUBSCRIBE,
  TIMESHEET_QUERY_SUCCESS,
  TIMESHEET_QUERY_FAILURE,
  TIMESHEET_QUERY_UNSUBSCRIBE,
} from 'constants/index';
import { sortByString, insertIf } from 'utils/index';

let listUnsubscriber = null;

export const timesheetSubscribe = () => ({
  type: TIMESHEET_QUERY_SUBSCRIBE,
});

export const timesheetUnsubscribe = () => ({
  type: TIMESHEET_QUERY_UNSUBSCRIBE,
});

const timesheetSuccess = ({ items, searchParams }) => ({
  type: TIMESHEET_QUERY_SUCCESS,
  payload: {
    items,
    searchParams,
  },
});

const timesheetFailure = (message) => ({
  type: TIMESHEET_QUERY_FAILURE,
  payload: {
    message,
  },
});

export const subscribeToTimesheets = (params) => (dispatch) => {
  dispatch(timesheetSubscribe());
  const { startDate, endDate, person, type, isSubsidized } = params;
  listUnsubscriber = api.subscribeToTimesheetQuery(
    {
      startDate,
      endDate,
      type,
      memberId: person,
      ...insertIf(isSubsidized, 'hasSubsidy', isSubsidized),
    },
    ({ docs, size }) => {
      if (size) {
        const items = docs
          .map((it) => {
            const { id, firstName, lastName, ...rest } = it.data();
            return {
              id: it.id,
              fullName: `${lastName}, ${firstName}`,
              firstName,
              lastName,
              ...rest,
            };
          })
          .sort((a, b) => sortByString(a.fullName, b.fullName));
        dispatch(
          timesheetSuccess({
            items,
            searchParams: {
              startDate,
              endDate,
              type,
              memberId: person,
            },
          }),
        );
      } else {
        dispatch(timesheetFailure('No timesheet entries match your search criteria.'));
      }
    },
  );
};

export const unsubscribeFromTimesheets = () => (dispatch) => {
  dispatch(timesheetUnsubscribe());
  api.unsubscribeListener(listUnsubscriber);
  listUnsubscriber = null;
};
