import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Modal extends Component {
  static propTypes = {
    isShowing: PropTypes.bool.isRequired,
    modalTitle: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    onOpen: null,
    onClose: null,
  };

  constructor(props) {
    super(props);
    const { isShowing } = props;
    this.state = {
      isShowing: isShowing || false,
    };
  }

  // componentDidMount() {
  //   const { isShowing } = this.props;
  //   isShowing ? this.openModal() : this.closeModal();
  // }

  closeModal = () => {
    this.setState(
      {
        isShowing: false,
      },
      () => {
        const { onClose } = this.props;
        if (onClose) onClose();
      },
    );
  };

  openModal = () => {
    this.setState(
      {
        isShowing: true,
      },
      () => {
        const { onOpen } = this.props;
        if (onOpen) onOpen();
      },
    );
  };

  render() {
    const { modalTitle, children } = this.props;
    const { isShowing } = this.state;
    const classNames = ['modal'];

    if (isShowing) {
      classNames.push('modal--open');
      document.body.classList.add('has-modal');
    } else {
      document.body.classList.remove('has-modal');
    }

    return (
      <div className={`${classNames.join(' ')}`}>
        <div className="modal-content">
          <div className="modal-header">
            <h2>{modalTitle}</h2>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className="modal-close" type="button" onClick={this.closeModal} />
          </div>
          <div className="modal-inner">{children}</div>
        </div>
      </div>
    );
  }
}

export default Modal;
