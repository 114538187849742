import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Logo } from 'components/graphic';

class Header extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  render() {
    const { children } = this.props;
    const classes = `header--global`;
    return (
      <header className={classes}>
        <div className="wrapper--center">
          <Logo />
          {children}
        </div>
      </header>
    );
  }
}

export default Header;
