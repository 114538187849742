import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const inputTypeMapping = {
  text: 'text',
  email: 'email',
  tel: 'tel',
  date: 'date',
  time: 'time',
  search: 'text',
  number: 'number',
};

class InputField extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    wrapperClassName: PropTypes.string,
    placeholder: PropTypes.string,
    labelText: PropTypes.string,
    hintText: PropTypes.string,
    errorText: PropTypes.string,
    value: PropTypes.string,
    pattern: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    hasGlobalError: PropTypes.bool,
    onChange: PropTypes.func,
    hasMinMax: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    maxLength: PropTypes.number,
  };

  static defaultProps = {
    wrapperClassName: null,
    placeholder: null,
    labelText: null,
    hintText: null,
    errorText: null,
    value: undefined,
    pattern: null,
    isRequired: false,
    isDisabled: false,
    hasGlobalError: false,
    onChange: null,
    hasMinMax: false,
    min: 0,
    max: 0,
    maxLength: 0,
  };

  render() {
    const {
      id,
      wrapperClassName,
      type,
      placeholder,
      labelText,
      hintText,
      errorText,
      value,
      isRequired,
      isDisabled,
      hasGlobalError,
      onChange,
      pattern,
      hasMinMax,
      min,
      max,
      maxLength,
    } = this.props;
    /* eslint-disable jsx-a11y/label-has-for */
    const classNames = ['form-element', `form-element--${type}`];
    if (wrapperClassName) classNames.push(wrapperClassName);
    if (hasGlobalError || errorText) classNames.push('form-element--error');
    if (isRequired) classNames.push('form-element--required');
    if (isDisabled) classNames.push('form-element--disabled');
    const inputProps = {
      id,
      name: id,
      type: inputTypeMapping[type] || 'text',
      required: isRequired,
      disabled: isDisabled,
      onChange,
      placeholder: placeholder || undefined,
      value,
      pattern: pattern || undefined,
      ...(hintText && { 'aria-describedby': `${id}-hint` }),
      ...(hasMinMax && min && { min }),
      ...(hasMinMax && max && { max }),
      ...(!!maxLength && { maxLength }),
    };
    return (
      <div className={`${classNames.join(' ')}`}>
        {labelText && (
          <label className="label" htmlFor={id} id={`${id}-label`}>
            {labelText}
            {isRequired && <span className="required">*</span>}
          </label>
        )}
        {hintText && (
          <span id={`${id}-hint`} className="form-element-hint">
            {hintText}
          </span>
        )}
        {errorText && <span className="form-element-error-msg">{errorText}</span>}
        <input {...inputProps} />
      </div>
    );
    /* eslint-enable */
  }
}

export default InputField;
