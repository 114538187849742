import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { addTimesheet, addTimesheets } from 'actions';
import { Modal } from 'components/modal';
import { AddTimesheetEntryForm } from 'components/form';
import { getMemberDetails } from 'utils/index';
import { ACTION_OTHER, CHECKED_IN, CHECKED_OUT } from 'constants/index';
import conf from 'conf/index';

class AddTimesheetEntry extends PureComponent {
  static propTypes = {
    doAddRecord: PropTypes.func.isRequired,
    doAddRecords: PropTypes.func.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    didRequestWithSuccess: PropTypes.bool.isRequired,
    isShowing: PropTypes.bool.isRequired,
    studentMembers: PropTypes.shape(),
    staffMembers: PropTypes.shape(),
    requestError: PropTypes.string,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    studentMembers: {},
    staffMembers: {},
    requestError: null,
    onClose: null,
  };

  componentDidUpdate(prevProps) {
    const { isShowing: willShow, didRequestWithSuccess } = this.props;
    const { isShowing } = prevProps;
    if (didRequestWithSuccess && willShow) {
      this.modal.closeModal();
      return;
    }
    if (isShowing === willShow) return;
    if (!isShowing && willShow) {
      this.modal.openModal();
    } else if (isShowing && !willShow) {
      this.modal.closeModal();
    }
  }

  onCancel = () => {
    this.modal.closeModal();
  };

  onConfirm = (data) => {
    const { doAddRecord, doAddRecords, studentMembers, staffMembers } = this.props;
    const { memberId, date, time, startTime, endTime, action, actionDetails, isManualEntry, reason, type } = data;
    const member = getMemberDetails(memberId, type, staffMembers, studentMembers);
    const { firstName, lastName, hasSubsidy } = member;
    const [year, month, day] = date.split('-');
    const initialData = {
      memberId,
      firstName,
      lastName,
      isManualEntry,
      actionDetails,
      reason,
      type,
      hasSubsidy: hasSubsidy || false,
    };

    if (startTime && endTime && action === ACTION_OTHER) {
      const [sHour, sMinute] = startTime.split(':');
      const [eHour, eMinute] = endTime.split(':');
      const start = DateTime.fromObject({
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        day: parseInt(day, 10),
        hour: parseInt(sHour, 10),
        minute: parseInt(sMinute, 10),
        zone: conf.timeZone,
      });
      const end = DateTime.fromObject({
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        day: parseInt(day, 10),
        hour: parseInt(eHour, 10),
        minute: parseInt(eMinute, 10),
        zone: conf.timeZone,
      });
      const result = [
        {
          ...initialData,
          action: CHECKED_IN,
          date: start.toFormat(conf.formats.date),
          time: start.toFormat(conf.formats.time),
          timeStamp: start.toMillis(),
          dateTime: start.toUTC().toISO(),
        },
        {
          ...initialData,
          action: CHECKED_OUT,
          date: end.toFormat(conf.formats.date),
          time: end.toFormat(conf.formats.time),
          timeStamp: end.toMillis(),
          dateTime: end.toUTC().toISO(),
        },
      ];
      doAddRecords(result);
    } else {
      const [hour, minute] = time.split(':');
      const dt = DateTime.fromObject({
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        day: parseInt(day, 10),
        hour: parseInt(hour, 10),
        minute: parseInt(minute, 10),
        zone: conf.timeZone,
      });
      const result = {
        ...initialData,
        action,
        date: dt.toFormat(conf.formats.date),
        time: dt.toFormat(conf.formats.time),
        timeStamp: dt.toMillis(),
        dateTime: dt.toUTC().toISO(),
      };
      doAddRecord(result);
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
    this.form.reset();
  };

  onOpen = () => {
    this.form.reset();
  };

  render() {
    const { isShowing, isRequesting, requestError } = this.props;
    return (
      <Modal
        isShowing={isShowing}
        modalTitle="Add Missing Timesheet Entry"
        onClose={this.onClose}
        onOpen={this.onOpen}
        ref={(ref) => {
          this.modal = ref;
        }}
      >
        <AddTimesheetEntryForm
          id="AddTimesheetEntry"
          cancelForm={this.onCancel}
          submitForm={this.onConfirm}
          isSubmitting={isRequesting}
          error={requestError}
          ref={(ref) => {
            this.form = ref;
          }}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  doAddRecord: addTimesheet,
  doAddRecords: addTimesheets,
};

const mapStateToProps = ({ timesheet }) => {
  const { add } = timesheet;
  const { isRequesting, didRequestWithSuccess, requestError } = add;
  return {
    isRequesting,
    didRequestWithSuccess,
    requestError,
  };
};

export { AddTimesheetEntry };
export default connect(mapStateToProps, mapDispatchToProps)(AddTimesheetEntry);
