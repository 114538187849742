import React from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { subscribeToStudents, unsubscribeFromStudents, subscribeToAdults, unsubscribeFromAdults } from 'actions';
import { useSubscription } from 'utils/useSubscription';
import { getRecordTypeFromPath, getRecordPathFromType, isEmpty } from 'utils/index';
import {
  TYPE_STUDENT,
  ROUTE_RELATIONSHIP_SELECT_TYPE,
  LIST_TYPE_RELATED,
  WORKFLOW_CREATE,
  // TYPE_ADULT,
  // ROUTE_ADULT_CREATE_ID,
  // TYPE_STAFF,
  // ROUTE_STAFFMEMBER_CREATE_ID,
  // ROUTE_STUDENT_CREATE_ID,
} from 'constants/index';
import { PageFocusview } from 'components/layout';
import { ListAdd, ListItemRelatedParty } from 'components/lists';

const getSubtitle = (type, record) => {
  if (!record) return null;
  const { fullName } = record;
  return type === TYPE_STUDENT ? `Link parents/guardians to ${fullName}` : `Link children to ${fullName}`;
};

const getLinkUrl = (type, recordId) => {
  return ROUTE_RELATIONSHIP_SELECT_TYPE.replace(':id', recordId).replace(':type', getRecordPathFromType(type));
};

// const getBackUrl = (recordType, recordId) => {
//   switch (recordType) {
//     case TYPE_ADULT:
//       return ROUTE_ADULT_CREATE_ID.replace(':id', recordId);
//     case TYPE_STAFF:
//       return ROUTE_STAFFMEMBER_CREATE_ID.replace(':id', recordId);
//     case TYPE_STUDENT:
//     default:
//       return ROUTE_STUDENT_CREATE_ID.replace(':id', recordId);
//   }
// };

// @TODO: in the future, this will go to the "EmergencyContactList" component
// for now, if they skip, it takes them to the party profile page
const getSkipURL = (id, typePath) => `/${typePath}/${id}`;

const getRelatedPartyList = (adults, students, record) => {
  if (!record || isEmpty(students) || isEmpty(adults)) return [];
  const { relationships } = record;
  if (!relationships) return [];
  const relatedParties = Object.keys(relationships).map((id) => {
    const relData = relationships[id];
    const { rel, relType } = relData;
    const targetData = relType === TYPE_STUDENT ? students[id] : adults[id];
    const { fullName } = targetData;
    return <ListItemRelatedParty key={id} fullName={fullName} rel={rel} />;
  });
  return relatedParties;
};

const RelationshipList = () => {
  const { type, id } = useParams();
  const recordType = getRecordTypeFromPath(type);

  const skipUrl = getSkipURL(id, type);
  // const backUrl = getBackUrl(type, id);

  // if we got here without an id or record type, return to origin
  if (!id || !type) return <Redirect to={skipUrl} />;

  const { items: adults } = useSubscription({
    subscribeFn: subscribeToAdults,
    unsubscribeFn: unsubscribeFromAdults,
    selectorKey: 'adults',
  });

  const { items: students } = useSubscription({
    subscribeFn: subscribeToStudents,
    unsubscribeFn: unsubscribeFromStudents,
    selectorKey: 'students',
  });

  const record = recordType === TYPE_STUDENT ? students[id] : adults[id];

  const subtitle = getSubtitle(recordType, record);

  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle="Link related parties"
      pageSubtitle={subtitle}
      showCloseButton
      closeButtonUrl={skipUrl}
      // showBackButton
      // backButtonUrl={backUrl}
    >
      <ListAdd
        listType={LIST_TYPE_RELATED}
        action="Add"
        itemType="Related Party"
        linkUrl={getLinkUrl(recordType, id)}
        linkState={{
          mode: WORKFLOW_CREATE,
        }}
      >
        {record && record.relationships && getRelatedPartyList(adults, students, record)}
      </ListAdd>
      <div className="form-actions">
        <Link className="button--text" to={skipUrl}>
          Skip for Now
        </Link>
      </div>
    </PageFocusview>
  );
};

export default RelationshipList;
