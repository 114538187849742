import { combineReducers } from 'redux';
import { createMenuReducer } from 'utils/index';

const initialState = {
  isOpen: false,
};

export default combineReducers({
  GLOBAL: createMenuReducer('GLOBAL', initialState),
  USER: createMenuReducer('USER', initialState),
});
