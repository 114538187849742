import { createReducer } from 'utils/index';
import { TENANT_LIST_REQUEST, TENANT_LIST_SUCCESS, TENANT_LIST_FAILURE } from 'constants/index';

const initialState = {
  items: [],
  fetchError: null,
  isFetching: false,
};

export default createReducer(initialState, {
  [TENANT_LIST_REQUEST]: () => ({
    ...initialState,
    isFetching: true,
  }),
  [TENANT_LIST_SUCCESS]: (state, { items }) => ({
    ...state,
    items,
    isFetching: false,
  }),
  [TENANT_LIST_FAILURE]: (_state, { message }) => ({
    items: [],
    fetchError: message,
    isFetching: false,
  }),
});
