import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import { isInclusivelyAfterDay } from 'react-dates';
import { Button, ActingSubmitButton } from 'components/buttons';
import { InputSelect, InputTime, InputText, AdultSelect, StudentSelect } from 'components/formElements';
import { Form, Error } from 'components/layout';
import { SingleDatePickerWrapper } from 'components/dates';
import { insertIf, isEmpty, reportTypeOptions, timeSheetActionOptions } from 'utils/index';
import { ACTION_OTHER, TYPE_STUDENT } from 'constants/index';
import config from 'conf';

const defaultState = {
  values: {
    memberId: '',
    date: '',
    time: '',
    startTime: '',
    endTime: '',
    action: '',
    actionDetails: '',
    isManualEntry: true,
    type: '',
    reason: '',
  },
  initialDate: null,
  didSubmit: false,
};

class AddTimesheetEntryForm extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    cancelForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    isLoading: PropTypes.bool,
    isInline: PropTypes.bool,
    data: PropTypes.shape({
      m: momentPropTypes.momentObj,
      memberId: PropTypes.string,
      type: PropTypes.string,
    }),
    error: PropTypes.string,
  };

  static defaultProps = {
    isSubmitting: false,
    isLoading: false,
    isInline: false,
    data: {},
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable react/no-did-update-set-state */
    const { data: oldData } = prevProps;
    const { data } = this.props;
    if (!data || isEmpty(data)) return;
    if (data !== oldData) {
      const { memberId, m, type } = data;
      this.setState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          type,
          memberId,
          date: m.format(config.formats.momentDateSystem),
        },
        initialDate: m.clone(),
      }));
    }
    /* eslint-enable react/no-did-update-set-state */
  }

  onSubmit = () => {
    const { submitForm } = this.props;
    const { values } = this.state;
    submitForm(values);
  };

  onFieldChange = (e) => {
    const { id: formId } = this.props;
    const { id, value } = e.target;
    const fieldId = id.replace(`${formId}-`, '');

    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [fieldId]: value,
        // reset action details, start time, end time, if action IS NOT OTHER
        ...insertIf(fieldId === 'action' && value !== ACTION_OTHER, 'actionDetails', null),
        ...insertIf(fieldId === 'action' && value !== ACTION_OTHER, 'startTime', ''),
        ...insertIf(fieldId === 'action' && value !== ACTION_OTHER, 'endTime', ''),
        // reset time if action IS OTHER
        ...insertIf(fieldId === 'action' && value === ACTION_OTHER, 'time', ''),
      },
    }));
  };

  onDateChange = (fieldId, { date }) => {
    if (date === undefined) return;
    const value = date ? date.format(config.formats.momentDateSystem) : null;
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [fieldId]: value,
      },
      changedFields: {
        ...prevState.changedFields,
        [fieldId]: true,
      },
    }));
  };

  reset = () => {
    this.setState(
      () => ({
        ...defaultState,
      }),
      () => {
        this.datePicker.reset();
      },
    );
  };

  render() {
    const { id, isLoading, isSubmitting, error, cancelForm, isInline } = this.props;
    const { values, initialDate } = this.state;
    const { memberId, type, time, action, actionDetails, startTime, endTime, reason } = values;

    const submitProps = {
      btnClassName: 'button',
      actionBtnClassName: 'button button--icon--loading',
      btnText: 'Save Entry',
      actionText: isLoading ? 'Loading...' : 'Saving...',
      isActing: isSubmitting || isLoading,
    };

    return (
      <Form onSubmit={this.onSubmit} className="form--missing-entry">
        {!isSubmitting && !!error && <Error error={error} className="form-error-msg" />}
        <fieldset className="fieldset" disabled={isLoading}>
          <div className="wrapper--flex">
            <InputSelect
              id="type"
              labelText="Entry type"
              options={reportTypeOptions}
              value={type}
              onChange={this.onFieldChange}
              isDisabled={isInline}
              isRequired
            />
            {type === TYPE_STUDENT ? (
              <StudentSelect
                id={`${id}-memberId`}
                labelText="Name"
                value={memberId}
                onChange={this.onFieldChange}
                isDisabled={!type || isInline}
                isRequired
                showInactiveParties
              />
            ) : (
              <AdultSelect
                id={`${id}-memberId`}
                labelText="Name"
                value={memberId}
                onChange={this.onFieldChange}
                isDisabled={!type || isInline}
                isRequired
                filterStaffMembers
                showInactiveParties
              />
            )}
          </div>
          <div className="wrapper--flex">
            <SingleDatePickerWrapper
              id={`${id}-date`}
              labelText="Date"
              hintText="Please use this format: MM/DD/YYYY"
              placeholder={null}
              onClose={(d) => this.onDateChange('date', d)}
              onChange={(d) => this.onDateChange('date', d)}
              initialDate={initialDate}
              isOutsideRange={(d) => isInclusivelyAfterDay(d, moment())}
              displayFormat="ddd MM/DD/Y"
              numberOfMonths={1}
              isRequired
              isDisabled={isInline}
              block
              ref={(ref) => {
                this.datePicker = ref;
              }}
            />
            <InputSelect
              id={`${id}-action`}
              labelText="Action"
              options={timeSheetActionOptions}
              value={action}
              onChange={this.onFieldChange}
              isRequired
            />
          </div>
          {/* Show Time Input if Action is not 'Other' */}
          {action !== ACTION_OTHER && (
            <div className="wrapper--flex">
              <InputTime id={`${id}-time`} labelText="Time" onChange={this.onFieldChange} value={time} isRequired />
            </div>
          )}
          {/* Otherwise show start time and end time */}
          {!!action && action === ACTION_OTHER && (
            <div className="wrapper--flex">
              <InputTime
                id={`${id}-startTime`}
                labelText="Start Time"
                onChange={this.onFieldChange}
                value={startTime}
                isRequired
              />
              <InputTime
                id={`${id}-endTime`}
                labelText="End Time"
                onChange={this.onFieldChange}
                value={endTime}
                isRequired
              />
              <div>
                <InputText
                  id={`${id}-actionDetails`}
                  labelText="Action Details"
                  value={actionDetails}
                  onChange={this.onFieldChange}
                  isRequired
                />
              </div>
            </div>
          )}

          <InputText
            id={`${id}-reason`}
            labelText="Reason for Manual Entry"
            value={reason}
            onChange={this.onFieldChange}
          />
        </fieldset>
        <div className="form-actions">
          <Button onClick={cancelForm} className="button--text--cancel">
            Cancel
          </Button>
          <ActingSubmitButton {...submitProps} />
        </div>
      </Form>
    );
  }
}

export default AddTimesheetEntryForm;
