import { api } from 'index';
import axios from 'axios';
import conf from 'conf';
import { NOTIFY_SMS_REQUEST, NOTIFY_SMS_SUCCESS, NOTIFY_SMS_FAILURE, NOTIFY_SMS_RESET } from 'constants/index';

const smsUrl = conf.api.urls.notify.sms;

const notifySmsRequest = () => ({
  type: NOTIFY_SMS_REQUEST,
});

const notifySmsSuccess = (result) => ({
  type: NOTIFY_SMS_SUCCESS,
  payload: {
    result,
  },
});

const notifySmsReset = () => ({
  type: NOTIFY_SMS_RESET,
});

const notifySmsFailure = (message) => ({
  type: NOTIFY_SMS_FAILURE,
  payload: {
    message,
  },
});

export const notifySms = (targets, message) => (dispatch, getState) => {
  dispatch(notifySmsRequest());
  const {
    auth: { selectedTenant },
  } = getState();

  api.auth.currentUser.getIdToken().then((token) => {
    const client = axios.create({
      headers: {
        ...conf.xhr.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    client
      .post(`${smsUrl}?tenant=${selectedTenant}`, { targets, message })
      .then(() => {
        dispatch(notifySmsSuccess(true));
      })
      .catch((e) => {
        dispatch(notifySmsFailure(e.message));
      })
      .finally(() => dispatch(notifySmsReset()));
  });
};
