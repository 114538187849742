import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getTenants, resetTenantApi } from 'actions';
import { PageActions, SubPageHeader } from 'components/layout';
import { InformationList } from 'components/lists';
import { TabContent } from 'components/tabs';
import { ROUTE_ADMIN } from 'constants/index';
import { getFormattedAddress } from 'utils/index';

const getListData = (tenant) => {
  const listData = [];

  if (tenant) {
    const { name, address, address2, city, zip, state, mobile, phone, email } = tenant;
    listData.push({
      label: 'Center Name',
      value: name,
    });
    listData.push({
      label: 'Address',
      value: getFormattedAddress({ address, address2, city, zip, state }),
    });
    listData.push({
      label: 'Mobile Number (SMS notifications will be sent to this number)',
      value: mobile || 'N/A',
    });
    listData.push({
      label: 'Phone Number',
      value: phone || 'N/A',
    });
    listData.push({
      label: 'Email Address',
      value: email || 'N/A',
    });
  }

  return listData;
};

const AdminInfo = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { selectedTenant, userTenants } = useSelector((state) => state.auth);
  const tenants = useSelector((state) => state.tenants.items);
  const isAdmin = userTenants.includes('*');
  const { didRequestWithSuccess } = useSelector((state) => state.tenant.update);
  const currentTenant = tenants.find((it) => it.id === selectedTenant);

  useEffect(() => {
    if (!tenants.length || didRequestWithSuccess) {
      dispatch(getTenants(userTenants, isAdmin));
    }
    if (didRequestWithSuccess) dispatch(resetTenantApi());
  }, [tenants, didRequestWithSuccess]);

  const actions = [
    {
      key: 'edit',
      classNames: 'button',
      text: 'Edit',
      isVisible: true,
      isLink: true,
      url: '/admin/info/update',
      referrer: location.pathname,
    },
  ];

  return (
    <TabContent id="adminInfo" isShowing={location.pathname === ROUTE_ADMIN}>
      <SubPageHeader title="Center Information" actions={<PageActions actions={actions} />} />
      <InformationList listData={getListData(currentTenant)} />
    </TabContent>
  );
};

export default AdminInfo;
