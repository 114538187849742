export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const TENANT_LIST_REQUEST = 'TENANT_LIST_REQUEST';
export const TENANT_LIST_SUCCESS = 'TENANT_LIST_SUCCESS';
export const TENANT_LIST_FAILURE = 'TENANT_LIST_FAILURE';

export const RATIO_LIST_REQUEST = 'RATIO_LIST_REQUEST';
export const RATIO_LIST_SUCCESS = 'RATIO_LIST_SUCCESS';
export const RATIO_LIST_FAILURE = 'RATIO_LIST_FAILURE';

export const STAFF_LIST_SUBSCRIBE = 'STAFF_LIST_SUBSCRIBE';
export const STAFF_LIST_SUCCESS = 'STAFF_LIST_SUCCESS';
export const STAFF_LIST_FAILURE = 'STAFF_LIST_FAILURE';
export const STAFF_LIST_UNSUBSCRIBE = 'STAFF_LIST_UNSUBSCRIBE';

export const ADULT_LIST_SUBSCRIBE = 'ADULT_LIST_SUBSCRIBE';
export const ADULT_LIST_SUCCESS = 'ADULT_LIST_SUCCESS';
export const ADULT_LIST_FAILURE = 'ADULT_LIST_FAILURE';
export const ADULT_LIST_UNSUBSCRIBE = 'ADULT_LIST_UNSUBSCRIBE';

export const STUDENT_LIST_SUBSCRIBE = 'STUDENT_LIST_SUBSCRIBE';
export const STUDENT_LIST_SUCCESS = 'STUDENT_LIST_SUCCESS';
export const STUDENT_LIST_FAILURE = 'STUDENT_LIST_FAILURE';
export const STUDENT_LIST_UNSUBSCRIBE = 'STUDENT_LIST_UNSUBSCRIBE';

export const TIMESHEET_QUERY_SUBSCRIBE = 'TIMESHEET_QUERY_SUBSCRIBE';
export const TIMESHEET_QUERY_SUCCESS = 'TIMESHEET_QUERY_SUCCESS';
export const TIMESHEET_QUERY_FAILURE = 'TIMESHEET_QUERY_FAILURE';
export const TIMESHEET_QUERY_UNSUBSCRIBE = 'TIMESHEET_QUERY_UNSUBSCRIBE';

export const LIST_REQUEST = 'LIST_REQUEST';
export const LIST_SUBSCRIBE = 'LIST_SUBSCRIBE';
export const LIST_SUCCESS = 'LIST_SUCCESS';
export const LIST_FAILURE = 'LIST_FAILURE';
export const LIST_UNSUBSCRIBE = 'LIST_UNSUBSCRIBE';

export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_FAILURE = 'API_FAILURE';
export const API_RESET = 'API_RESET';

export const GEN_REPORT_REQUEST = 'GEN_REPORT_REQUEST';
export const GEN_REPORT_SUCCESS = 'GEN_REPORT_SUCCESS';
export const GEN_REPORT_FAILURE = 'GEN_REPORT_FAILURE';
export const GEN_REPORT_RESET = 'GEN_REPORT_RESET';

export const NOTIFY_SMS_REQUEST = 'NOTIFY_SMS_REQUEST';
export const NOTIFY_SMS_SUCCESS = 'NOTIFY_SMS_SUCCESS';
export const NOTIFY_SMS_FAILURE = 'NOTIFY_SMS_FAILURE';
export const NOTIFY_SMS_RESET = 'NOTIFY_SMS_RESET';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';
