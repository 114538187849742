import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addAdult } from 'actions';
import { ROUTE_RELATIONSHIP_LIST, ROUTE_ADULT_LIST, TYPE_ADULT, MODE_CREATE } from 'constants/index';
import { PageFocusview } from 'components/layout';
import { FormAdultPersonalInformation } from 'components/form';
import { getRecordPathFromType } from 'utils';

const AddAdult = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { error, isRequesting, didRequestWithSuccess, id } = useSelector((s) => s.adult.add);

  if (didRequestWithSuccess && id) {
    // replace history so browser back button
    // doesn't send us back to this page without an id
    history.replace(ROUTE_RELATIONSHIP_LIST.replace(':id', id).replace(':type', getRecordPathFromType(TYPE_ADULT)));
  }

  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle="Personal Information"
      closeButtonUrl={ROUTE_ADULT_LIST}
      showCloseButton
    >
      <FormAdultPersonalInformation
        origin={ROUTE_ADULT_LIST}
        record={{}}
        type={TYPE_ADULT}
        mode={MODE_CREATE}
        onSubmit={(values) => dispatch(addAdult(values))}
        isSubmitting={isRequesting}
        error={error}
      />
    </PageFocusview>
  );
};

export default AddAdult;
