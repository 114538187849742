import React from 'react';
import { Route, Switch, Redirect, useParams, useLocation } from 'react-router-dom';
import { subscribeToAdults, unsubscribeFromAdults } from 'actions';
import { PageFocusview, ProfileInfo } from 'components/layout';
import { Tabs, TabContent, TabsNav } from 'components/tabs';
import { PersonalInformationTab, RelatedPartiesTab, SchedulesTab } from 'components/memberProfiles';
import {
  ROUTE_STAFF_LIST,
  ROUTE_STAFFMEMBER_PROFILE,
  ROUTE_STAFFMEMBER_VIEW,
  PROFILE_TYPE_STAFF,
  TYPE_STAFF,
} from 'constants/index';
import { getProfileStatus, isEmpty } from 'utils';
import { useSubscription } from 'utils/useSubscription';

export const StaffMemberView = () => {
  const location = useLocation();
  const { id } = useParams();

  if (!id) return <Redirect to={ROUTE_STAFF_LIST} />;

  const { items, size } = useSubscription({
    subscribeFn: subscribeToAdults,
    unsubscribeFn: unsubscribeFromAdults,
    selectorKey: 'adults',
  });

  const record = size ? items[id] : {};

  const pageTitle = !isEmpty(record) ? `${record.lastName}, ${record.firstName}` : '';
  const profileInfo = <ProfileInfo profileType={PROFILE_TYPE_STAFF} profileStatus={getProfileStatus(record)} />;

  const tabsNavItems = [
    {
      key: 'personalInfo',
      tabUrl: `${ROUTE_STAFFMEMBER_VIEW}${id}/`,
      tabLabel: 'Personal Information',
      isActive: location.pathname === `${ROUTE_STAFFMEMBER_VIEW}${id}/`,
    },
    {
      key: 'relatedParties',
      tabUrl: `${ROUTE_STAFFMEMBER_VIEW}${id}/related-parties/`,
      tabLabel: 'Related Parties',
      isActive: location.pathname.includes('related-parties'),
    },
    {
      key: 'staff-member-schedule',
      tabUrl: `${ROUTE_STAFFMEMBER_VIEW}${id}/schedule/`,
      tabLabel: 'Schedules',
      isActive: location.pathname.includes('schedule'),
    },
  ];

  const tabsNav = <TabsNav navItems={tabsNavItems} />;

  return (
    <PageFocusview
      pageTitle={pageTitle}
      pageHeaderClassNames={['header--highlighted']}
      pageTitleChild={profileInfo}
      showCloseButton
      closeButtonUrl={ROUTE_STAFF_LIST}
    >
      <Tabs tabsNav={tabsNav}>
        <Switch>
          <Route exact path={ROUTE_STAFFMEMBER_PROFILE}>
            <TabContent id="personalInfo" isShowing={location.pathname === `${ROUTE_STAFFMEMBER_VIEW}${id}/`}>
              <PersonalInformationTab record={record} recordType={TYPE_STAFF} />
            </TabContent>
          </Route>
          <Route exact path={`${ROUTE_STAFFMEMBER_PROFILE}related-parties/`}>
            <TabContent id="relatedParties" isShowing={location.pathname.includes('related-parties')}>
              <RelatedPartiesTab record={record} recordType={TYPE_STAFF} />
            </TabContent>
          </Route>
          <Route exact path={`${ROUTE_STAFFMEMBER_PROFILE}schedule/`}>
            <SchedulesTab recordType={TYPE_STAFF} />
          </Route>
        </Switch>
      </Tabs>
    </PageFocusview>
  );
};
