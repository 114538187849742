import React /* , { useState } */ from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { unlinkMember } from 'actions';
import { useDispatch } from 'react-redux';
import { getFormattedPin, getFormattedBilling, boolToYesNo, isEmpty, getRecordPathFromType } from 'utils/index';
import { useModal } from 'utils/useModal';
import {
  TYPE_ADULT,
  TYPE_STAFF,
  TYPE_STUDENT,
  REL_TYPE_OTHER,
  BILLING_TYPE_FIXED,
  BILLING_TYPE_PERC,
  PERMISSION_LABELS,
  ROUTE_RELATIONSHIP,
} from 'constants/index';
import { Button } from 'components/buttons';
import { ConfirmModal } from 'components/modal';
import { TableRow } from 'components/table';

const getUpdatePartyLinkUrl = (typeA, typeB, idA, idB) => {
  return `${ROUTE_RELATIONSHIP}/update/${getRecordPathFromType(typeA)}/${getRecordPathFromType(typeB)}/${idA}/${idB}`;
};

const TableRowRelated = (props) => {
  const dispatch = useDispatch();
  const { data } = props;
  const { relId, ownerId, fullName, rel, details, isPrimary, permissions, billing, pin, relType, referrerType } = data;
  const perms = permissions
    ? Object.keys(permissions)
        .filter((it) => permissions[it])
        .map((it) => PERMISSION_LABELS[it])
        .join(', ')
    : 'N/A';
  const { isShowing, toggle } = useModal();

  return !isEmpty(data) ? (
    <>
      <TableRow key={relId}>
        <li className="tc--name">{fullName}</li>
        <li className="tc--relationship">{rel === REL_TYPE_OTHER ? details : rel}</li>
        <li className="tc--primary-guardian">{boolToYesNo(isPrimary)}</li>
        <li className="tc--permissions">{perms}</li>
        <li className="tc--billing">{getFormattedBilling(billing)}</li>
        <li className="tc--pin">{getFormattedPin(pin)}</li>
        <li className="tc--edit">
          {relType === referrerType ? (
            ''
          ) : (
            <Link className="button--text" to={getUpdatePartyLinkUrl(referrerType, relType, ownerId, relId)}>
              Edit
            </Link>
          )}
        </li>
        <li className="tc--remove">
          <Button className="button--text--neg" onClick={toggle}>
            Remove
          </Button>
        </li>
      </TableRow>
      <ConfirmModal
        isShowing={isShowing}
        title="Remove relationship?"
        content={`This will remove the relationship and cannot be undone. You will have to add a new relationship
          if you need to link these two parties again.`}
        isRequesting={false}
        doAction={() => {
          unlinkMember(ownerId, relId)(dispatch);
          toggle();
        }}
        hide={toggle}
        confirmLabel="Remove Relationship"
        cancelLabel="Cancel"
      />
    </>
  ) : null;
};

TableRowRelated.propTypes = {
  data: PropTypes.shape({
    relId: PropTypes.string,
    ownerId: PropTypes.string,
    pin: PropTypes.string,
    rel: PropTypes.string,
    relType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]),
    referrerType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]),
    fullName: PropTypes.string,
    details: PropTypes.string,
    isPrimary: PropTypes.bool,
    permissions: PropTypes.shape({
      attendance: PropTypes.bool,
      billing: PropTypes.bool,
    }),
    billing: PropTypes.shape({
      type: PropTypes.oneOf([BILLING_TYPE_FIXED, BILLING_TYPE_PERC, '']),
      amount: PropTypes.number,
    }),
  }).isRequired,
};

export default TableRowRelated;
