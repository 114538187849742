import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/fp/omit';
import moment from 'moment';
import { isInclusivelyAfterDay } from 'react-dates';
import { ActingSubmitButton } from 'components/buttons';
import { InputSelect, Toggle, AdultSelect, StudentSelect } from 'components/formElements';
import { Form, Error } from 'components/layout';
import { DateRangePickerWrapper } from 'components/dates';
import { reportTypeOptions, insertIf } from 'utils/index';
import config from 'conf';
import { TYPE_STAFF, TYPE_STUDENT, TYPE_ADULT } from 'constants/index';

class TimesheetSearchForm extends PureComponent {
  static propTypes = {
    submitForm: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    error: null,
  };

  constructor(props) {
    super(props);
    const initialStart = null;
    const initialEnd = null;
    this.state = {
      initialStart,
      initialEnd,
      startDate: null,
      endDate: null,
      type: '',
      person: '',
      isSubsidized: false,
    };
  }

  onFieldChange = (e) => {
    const { id, value, type, checked } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: type === 'checkbox' ? checked : value,
      ...insertIf(id === 'type' && value === TYPE_STAFF, 'isSubsidized', false),
    }));
  };

  onSubmit = () => {
    const { submitForm } = this.props;
    submitForm(omit(['initialStart', 'initialEnd'], this.state));
  };

  onDateRangeChange = ({ startDate, endDate }) => {
    const start = startDate ? startDate.format(config.formats.momentDateSystem) : null;
    const end = endDate ? endDate.format(config.formats.momentDateSystem) : null;
    this.setState((prevState) => ({
      ...prevState,
      startDate: start,
      endDate: end,
    }));
  };

  reset = () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        startDate: null,
        endDate: null,
        type: null,
        person: null,
        isSubsidized: false,
      }),
      () => {
        this.dateRangePicker.reset();
      },
    );
  };

  render() {
    const { isSubmitting, error } = this.props;
    const { person, type, initialStart, initialEnd, isSubsidized } = this.state;
    const isSubmitDisabled = isSubmitting;
    const submitProps = {
      btnClassName: 'button',
      actionBtnClassName: 'button button--icon--loading',
      btnText: 'Get Timesheets',
      actionText: 'Loading...',
      isActing: isSubmitting,
      isDisabled: isSubmitDisabled,
    };
    const isMemberDisabled = !type;
    return (
      <Form onSubmit={this.onSubmit} className="form--generate-results" autoComplete={false}>
        {!isSubmitting && !!error && <Error error={error} className="form-error-msg" />}
        <DateRangePickerWrapper
          labelText="Date Range"
          onClose={this.onDateRangeChange}
          initialStartDate={initialStart}
          initialEndDate={initialEnd}
          isOutsideRange={(day) => isInclusivelyAfterDay(day, moment().add(1, 'day'))}
          minimumNights={0}
          displayFormat="MMM D Y"
          isRequired
          ref={(ref) => {
            this.dateRangePicker = ref;
          }}
          appendToBody={false}
        />
        <InputSelect
          id="type"
          labelText="Timesheet Type"
          options={reportTypeOptions}
          value={type}
          onChange={this.onFieldChange}
          isRequired
        />
        {type === TYPE_ADULT ? (
          <AdultSelect
            id="person"
            labelText="Name"
            value={person}
            onChange={this.onFieldChange}
            isDisabled={isMemberDisabled}
            filterStaffMembers
          />
        ) : (
          <StudentSelect
            id="person"
            labelText="Name"
            value={person}
            onChange={this.onFieldChange}
            isDisabled={isMemberDisabled}
          />
        )}
        <Toggle
          id="isSubsidized"
          labelText="Subsidized"
          isChecked={isSubsidized}
          isDisabled={type !== TYPE_STUDENT}
          onChange={this.onFieldChange}
        />
        <div className="form-actions">
          <ActingSubmitButton {...submitProps} />
        </div>
      </Form>
    );
  }
}

export default TimesheetSearchForm;
