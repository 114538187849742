import { combineReducers } from 'redux';
import { createAPIReducer } from 'utils/index';

const initialAPIState = {
  isRequesting: false,
  didRequestWithSuccess: false,
  requestError: null,
  id: null,
};

export default combineReducers({
  link: createAPIReducer('MEMBER_LINK_', initialAPIState),
  unlink: createAPIReducer('MEMBER_UNLINK_', initialAPIState),
});
