import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET, TYPE_STUDENT } from 'constants/index';

const API_ID = 'STUDENT_ADD_';

const studentAddRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const studentAddSuccess = (id) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id,
  },
});

const studentAddReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const studentAddFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const addStudent = (data) => (dispatch) => {
  dispatch(studentAddRequest());
  const docRef = api.createMemberDoc();
  api
    .addMember(docRef, TYPE_STUDENT, data)
    .then(() => {
      dispatch(studentAddSuccess(docRef.id));
    })
    .then(() => dispatch(studentAddReset()))
    .catch((err) => {
      dispatch(studentAddFailure(err));
    });
};
