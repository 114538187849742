import React from 'react';
import { Redirect, useParams, useLocation } from 'react-router-dom';
import { getRecordTypeFromPath } from 'utils';
import { PageFocusview } from 'components/layout';
import { ActionLink } from 'components/links';
import {
  ROUTE_HOME,
  TYPE_STUDENT,
  WORKFLOW_CREATE,
  ROUTE_RELATIONSHIP_LIST,
  ROUTE_RELATIONSHIP,
  TYPE_ADULT_PATH,
  TYPE_STUDENT_PATH,
} from 'constants/index';

const getAdultLinkDesc = (type) => {
  if (type === TYPE_STUDENT) {
    return 'A parent/guardian or anyone that who has billing or check in/out permission for the child';
  }
  return 'Usually a relative, or current or former spouse';
};

const getChildLinkDesc = (type) => {
  if (type === TYPE_STUDENT) {
    return 'Usually a sibling';
  }
  return 'A child for which this person has billing or check in/out permission';
};

const getCreateUrl = (id, typePath) => ROUTE_RELATIONSHIP_LIST.replace(':id', id).replace(':type', typePath);

const getProfileUrl = (id, typePath) => `/${typePath}/${id}/`;

const getReturnUrl = (id, typePath, mode) => {
  if (mode && mode === WORKFLOW_CREATE) return getCreateUrl(id, typePath);
  return getProfileUrl(id, typePath);
};

export const RelationshipSelect = () => {
  const { type, id } = useParams();
  const location = useLocation();

  const recordType = getRecordTypeFromPath(type);

  if (!id || !type) return <Redirect to={ROUTE_HOME} />;

  const { state } = location || {};
  const { mode } = state || null;

  const returnUrl = getReturnUrl(id, type, mode);

  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle="Select party type to link"
      showCloseButton
      closeButtonUrl={returnUrl}
    >
      <ActionLink
        linkText="Adult"
        linkDesc={getAdultLinkDesc(recordType)}
        url={`${ROUTE_RELATIONSHIP}/add/${type}/${TYPE_ADULT_PATH}/${id}/`}
        linkState={{
          mode,
        }}
        isButton={false}
      />
      <ActionLink
        linkText="Child"
        linkDesc={getChildLinkDesc(recordType)}
        url={`${ROUTE_RELATIONSHIP}/add/${type}/${TYPE_STUDENT_PATH}/${id}/`}
        linkState={{
          mode,
        }}
        isButton={false}
      />
    </PageFocusview>
  );
};
