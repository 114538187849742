import { createReducer } from 'utils/index';
import {
  ADULT_LIST_SUBSCRIBE,
  ADULT_LIST_SUCCESS,
  ADULT_LIST_FAILURE,
  ADULT_LIST_UNSUBSCRIBE,
  CHANGE_TENANT,
} from 'constants/index';

const initialState = {
  items: {},
  fetchError: null,
  shouldResubscribe: false,
};

export default createReducer(initialState, {
  [ADULT_LIST_SUBSCRIBE]: () => ({
    ...initialState,
  }),
  [ADULT_LIST_UNSUBSCRIBE]: () => ({
    ...initialState,
  }),
  [ADULT_LIST_SUCCESS]: (state, { items }) => ({
    ...state,
    items,
    fetchError: null,
  }),
  [ADULT_LIST_FAILURE]: (state, { message }) => ({
    ...state,
    items: {},
    fetchError: message,
  }),
  [CHANGE_TENANT]: () => ({
    ...initialState,
    shouldResubscribe: true,
  }),
});
