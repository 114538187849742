import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { EmergencyContactForm } from 'components/form';
import { PageFocusview, Error } from 'components/layout';
import { addContact } from 'actions';
import {
  ROUTE_STUDENT_VIEW,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_ADULT_VIEW,
  TYPE_STUDENT,
  TYPE_ADULT,
  TYPE_STAFF,
} from 'constants/index';

const closeUrl = (recordType, id) => {
  if (recordType === TYPE_STUDENT) {
    return `${ROUTE_STUDENT_VIEW}${id}/emergency-contacts/`;
  }
  if (recordType === TYPE_STAFF) {
    return `${ROUTE_STAFFMEMBER_VIEW}${id}/emergency-contacts/`;
  }
  return `${ROUTE_ADULT_VIEW}${id}/emergency-contacts/`;
};

const EmergencyContactAdd = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { originType } = props;
  const { didRequestWithSuccess, isRequesting, requestError } = useSelector((state) => state.contact.add);

  if (!requestError && didRequestWithSuccess) {
    return <Redirect to={closeUrl(originType, id)} />;
  }
  // show the error if there is one
  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle="Create an emergency contact"
      closeButtonUrl={closeUrl(originType, id)}
      showCloseButton
    >
      {!!requestError && <Error error={requestError} />}
      <EmergencyContactForm
        submitFn={(values) => dispatch(addContact(id, values, 'entry'))}
        isSubmitting={isRequesting}
        cancelUrl={closeUrl(originType, id)}
      />
    </PageFocusview>
  );
};

EmergencyContactAdd.propTypes = {
  originType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
};

export default EmergencyContactAdd;
