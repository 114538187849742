import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TabContent } from 'components/tabs';
import { ROUTE_ADMIN_SCHEDULE, ROUTE_ADMIN_SCHEDULE_UPDATE } from 'constants/index';
import { SubPageHeader, PageActions } from 'components/layout';
import { useDispatch, useSelector } from 'react-redux';
import { getCenterSchedule } from 'actions';
import { getFormattedTime, stringToMoment } from 'utils/index';
import { InformationList } from 'components/lists';

const scheduleDayValid = (day) => {
  if (!day.IsSelected) return 'Closed';
  if (day.IsSelected && (day.TimeIn === '' || day.TimeOut === '')) return 'N/A';
  return `${getFormattedTime(stringToMoment(day.TimeIn, 'hh:mm'))} -
    ${getFormattedTime(stringToMoment(day.TimeOut, 'hh:mm'))}`;
};

const listData = (data) => {
  const list = Object.keys(data).map((label) => {
    const day = data[label];
    return { label, value: scheduleDayValid(day) };
  });
  return list;
};

const AdminSchedule = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { data, isRequesting } = useSelector((state) => state.centerSchedule.get);
  const { didRequestWithSuccess } = useSelector((state) => state.centerSchedule.update);
  const actions = [
    {
      key: 'edit',
      classNames: 'button',
      text: 'Edit',
      isVisible: true,
      isLink: true,
      url: ROUTE_ADMIN_SCHEDULE_UPDATE,
      referrer: location.pathname,
    },
  ];
  useEffect(() => {
    if (!Object.keys(data).length || didRequestWithSuccess) {
      dispatch(getCenterSchedule());
    }
  }, [data, didRequestWithSuccess, dispatch]);

  return (
    <TabContent id="adminSchedule" isShowing={location.pathname === ROUTE_ADMIN_SCHEDULE}>
      <SubPageHeader title="Operating Hours" actions={<PageActions actions={actions} />} />
      {!isRequesting && !!Object.keys(data).length && <InformationList listData={listData(data)} />}
      {isRequesting && <div className="loading" />}
    </TabContent>
  );
};

export default AdminSchedule;
