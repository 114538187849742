const { toString } = Object.prototype;

/**
 * Check that a value is null.
 * @param s {*} value to test
 * @returns {boolean} True if is null, else false
 */
export const isNull = (s) => s === null;

/**
 * Gets the `toStringTag` of `value`.
 *
 * @author [jdalton](https://github.com/lodash/lodash/blob/master/.internal/getTag.js)
 *
 * @private
 * @param {*} value The value to query.
 * @returns {string} Returns the `toStringTag`.
 */
const getTag = (value) => {
  if (value === null) {
    return value === undefined ? '[object Undefined]' : '[object Null]';
  }
  return toString.call(value);
};

/**
 * Checks if `value` is the
 * [language type](http://www.ecma-international.org/ecma-262/7.0/#sec-ecmascript-language-types)
 * of `Object`. (e.g. arrays, functions, objects, regexes, `new Number(0)`, and `new String('')`)
 *
 * @author [jdalton](https://github.com/lodash/lodash/blob/master/isObject.js)
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is an object, else `false`.
 */
export const isObject = (value) => {
  if (value === undefined) return false;
  const type = typeof value;
  return value !== null && (type === 'object' || type === 'function');
};

/**
 * Checks if `value` is classified as a `Function` object.
 *
 * @author [jdalton](https://github.com/lodash/lodash/blob/master/isFunction.js)
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a function, else `false`.
 */
export const isFunction = (value) => {
  if (!isObject(value)) return false;
  const tag = getTag(value);
  return (
    tag === '[object Function]' ||
    tag === '[object AsyncFunction]' ||
    tag === '[object GeneratorFunction]' ||
    tag === '[object Proxy]'
  );
};

/**
 * Checks if `value` is a `String`
 *
 * @param {*} value
 * @returns {boolean}
 */
export const isString = (value) => typeof value === 'string';

/**
 * Checks if `value` is an `Array`
 *
 * @param {*} value
 * @returns {boolean}
 */
export const isArray = (value) => Array.isArray(value);

/**
 * Checks if `value` is empty
 *
 * @param {null|undefined|String|Array|Object} value
 * @returns {boolean}
 */
export const isEmpty = (value) => {
  switch (true) {
    case value === null: {
      return true;
    }
    case value === undefined: {
      return true;
    }
    case isString(value): {
      return value === '';
    }
    case isArray(value): {
      return value.length === 0;
    }
    case isObject(value) && !isFunction(value): {
      return Object.keys(value).length === 0;
    }
    case typeof value === 'boolean': {
      return false;
    }
    default: {
      return false;
    }
  }
};

/**
 * Capitalizes only the first letter of a word
 * Lower-cases all other letters
 * @param s The string to capitalize
 * @returns {string} The capitalized word
 */
export const capFirst = (s) => {
  if (!s || !s.length) return s;
  if (s.length === 1) return s.toUpperCase();
  return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase();
};

/**
 * Sorting function to provide to `Array.sort()`
 * Compares 2 items and returns sorting integer
 *
 * @param a First item
 * @param b Second item
 * @returns {number} 1, -1, 0, if a is greater than, less than, or equal to b
 */
export const sortByString = (a, b) => a.localeCompare(b);

/**
 * Rounds value provided to specified precision (defaults to 0)
 * @param value
 * @param precision
 * @returns {number}
 */
export const round2Precision = (value, precision = 0) => {
  const multiplier = 10 ** precision;
  return Math.round(value * multiplier) / multiplier;
};

/**
 * Generates a random number between 0 and 9999999999
 * (Note, not truly random, used for ids in React lists of children)
 * @returns {number}
 */
export const getInterimId = () => Math.floor(Math.random() * Math.floor(9999999999));

export const getSavedTenant = (config) => {
  let selectedTenant = null;

  // try catch to handle incognito browser mode. localstorage throws
  try {
    selectedTenant = localStorage.getItem(config.tenant.key);
  } catch (e) {
    selectedTenant = null;
  }

  return selectedTenant;
};
