import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isAfterDay from 'react-dates/src/utils/isAfterDay';
import { ReasonForChangeModal, ConfirmModal } from 'components/modal';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  updateAdult,
  deleteAdult,
  deleteStudent,
  subscribeToStudents,
  unsubscribeFromStudents,
  subscribeToAdults,
  unsubscribeFromAdults,
} from 'actions';
import { useSubscription } from 'utils/useSubscription';
import { getFormattedDate, stringToMoment, getAge, isEmpty } from 'utils/index';
import { useModal } from 'utils/useModal';
import { SubPageHeader, PageActions } from 'components/layout';
import { InformationList } from 'components/lists';
import {
  ROUTE_STUDENT_UPDATE,
  ROUTE_ADULT_UPDATE,
  ROUTE_STAFFMEMBER_UPDATE,
  TYPE_ADULT,
  TYPE_STAFF,
  TYPE_STUDENT,
} from 'constants/index';

const getListData = (recordType, record, dispatch, toggle) => {
  const isAdult = recordType === TYPE_ADULT;
  const isStaffMember = recordType === TYPE_STAFF;
  const isStudent = recordType === TYPE_STUDENT;
  const today = moment();
  const {
    id,
    address,
    address2,
    city,
    state,
    zip,
    email,
    mobile,
    homePhone,
    workPhone,
    dateOfBirth,
    startDate,
    endDate,
    classRoom,
    lockReason,
    isLocked,
  } = record;
  const dob = dateOfBirth ? stringToMoment(dateOfBirth) : null;
  const dobFormat = dob ? getFormattedDate(dob) : 'N/A';
  const isProjectedDob = dob ? isAfterDay(dob, today) : false;
  const age = getAge(dob);
  const sd = startDate ? stringToMoment(startDate) : null;
  const startD = sd ? getFormattedDate(sd) : 'N/A';
  const ed = endDate ? stringToMoment(endDate) : null;
  const endD = ed ? getFormattedDate(ed) : 'N/A';
  const cityStateZip = [city, state, zip].filter((it) => !isEmpty(it));
  const addressFmt = `${address ? `${address}` : ''}${address2 ? `, ${address2}` : ''}${
    address || address2 ? ', ' : ''
  }${!isEmpty(cityStateZip) ? cityStateZip.join(' ') : ''}`;
  const listData = [];
  if (isStaffMember || isStudent) {
    listData.push({
      label: `Date of Birth${isProjectedDob ? ' (Projected)' : ''}`,
      value: dobFormat,
    });
  }
  if (isStudent) {
    listData.push({
      label: 'Age',
      value: age,
    });
  }
  if (isAdult || isStaffMember) {
    listData.push({
      label: 'Email',
      value: email || 'N/A',
    });
    listData.push({
      label: 'Mobile',
      value: mobile || 'N/A',
    });
    listData.push({
      label: 'Home Phone',
      value: homePhone || 'N/A',
    });
    listData.push({
      label: 'Work Phone',
      value: workPhone || 'N/A',
    });
  }
  listData.push({
    label: 'Address',
    value: addressFmt || 'N/A',
  });

  if (isStaffMember || isStudent) {
    listData.push({
      label: 'Start Date',
      value: startD,
    });
    listData.push({
      label: 'End Date',
      value: endD,
    });
  }
  if (isStudent) {
    listData.push({
      label: 'Classroom',
      value: classRoom || 'N/A',
    });
  }
  if (isAdult || isStaffMember) {
    const lockStatus = isEmpty(isLocked) ? false : isLocked;
    listData.push({
      label: 'Locked',
      value: lockStatus,
      isToggle: true,
      toggleAction: () =>
        lockStatus ? dispatch(updateAdult(id, { isLocked: !lockStatus, lockReason: null })) : toggle(),
      toggleClassnames: 'form-element form-element--toggle form-element--error',
    });
    listData.push({
      label: 'Reason',
      value: lockReason || 'N/A',
      className: 'li--locked-reason',
    });
  }

  return listData;
};

const getUpdateUrl = (recordType, id) => {
  if (recordType === TYPE_STUDENT) {
    return ROUTE_STUDENT_UPDATE.replace(':id', id);
  }
  if (recordType === TYPE_STAFF) {
    return ROUTE_STAFFMEMBER_UPDATE.replace(':id', id);
  }
  return ROUTE_ADULT_UPDATE.replace(':id', id);
};

const deleteMember = (recordType, id, dispatch) => {
  if (recordType === TYPE_STUDENT) {
    dispatch(deleteStudent(id));
  } else {
    dispatch(deleteAdult(id));
  }
};

const getRelatedRow = (id, rel, type, adults, students) => {
  const rec = type === TYPE_STUDENT ? students[id] : adults[id];
  if (rec) {
    return (
      <ul className="table-row" key={id}>
        <li>{rec.fullName}</li>
        <li>{rel}</li>
      </ul>
    );
  }
  return <div key={id} />;
};

const getDeleteContent = (record, adults, students) => {
  const { relationships } = record;
  const hasRelationships = !isEmpty(relationships);
  return (
    <>
      <p>Are you sure you want to delete {record.fullName}?</p>
      {!!hasRelationships && (
        <>
          <p>The following parties will have their relationship with {record.fullName} removed.</p>
          <div className="table table--assigned-parties">
            <ul className="table-header" key="header">
              <li>Name</li>
              <li>Type</li>
            </ul>
            {Object.keys(relationships).map((it) =>
              getRelatedRow(
                it,
                relationships[it].rel,
                relationships[it].relType || relationships[it].details,
                adults,
                students,
              ),
            )}
          </div>
        </>
      )}
    </>
  );
};

const PersonalInformation = (props) => {
  const { record, recordType } = props;
  const { isShowing, toggle } = useModal();
  const { isShowing: isDeleteShowing, toggle: toggleDelete } = useModal();
  const location = useLocation();
  const dispatch = useDispatch();

  const { items: adults } = useSubscription({
    subscribeFn: subscribeToAdults,
    unsubscribeFn: unsubscribeFromAdults,
    selectorKey: 'adults',
  });

  const { items: students } = useSubscription({
    subscribeFn: subscribeToStudents,
    unsubscribeFn: unsubscribeFromStudents,
    selectorKey: 'students',
  });

  const { id, isActive } = record;

  const actions = [];
  if (isActive) {
    actions.push({
      key: 'edit',
      classNames: 'button',
      text: 'Edit',
      isVisible: true,
      isLink: true,
      url: getUpdateUrl(recordType, id),
      referrer: location.pathname,
    });
    actions.push({
      key: 'deactivate',
      classNames: 'button--action--neg',
      text: 'Make Inactive',
      isVisible: true,
      isLink: false,
      onClick: toggleDelete,
    });
  }
  const listData = getListData(recordType, record, dispatch, toggle);
  return (
    <>
      <SubPageHeader title="Personal Information" actions={<PageActions actions={actions} />} />
      <InformationList listData={listData} />
      <ReasonForChangeModal
        doAction={(lockReason) => {
          toggle();
          dispatch(updateAdult(id, { isLocked: true, lockReason }));
        }}
        isShowing={isShowing}
        hide={() => toggle()}
        isRequesting={false}
        title="Reason for locking"
        text="Please specify why you are locking this account"
      />
      {isActive && (
        <ConfirmModal
          isShowing={isDeleteShowing}
          title={`Delete ${record.fullName}?`}
          content={getDeleteContent(record, adults, students)}
          isRequesting={false}
          doAction={() => {
            toggleDelete();
            deleteMember(recordType, id, dispatch);
          }}
          hide={toggleDelete}
          disableConfirmation={false}
          confirmLabel="Delete"
          cancelLabel="Cancel"
        />
      )}
    </>
  );
};

PersonalInformation.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    dateOfBirth: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    classRoom: PropTypes.string,
    fullName: PropTypes.string,
    isActive: PropTypes.bool,
  }).isRequired,
  recordType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
};

export default PersonalInformation;
