import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import config from 'conf';
import { Link } from 'react-router-dom';
import { onFieldChange, onDateChange, insertIf, validationChangeForm } from 'utils/index';
import { Form } from 'components/layout';
import { SingleDatePickerWrapper } from 'components/dates';
import { InputTime, InputCheckbox, ClassroomSelect } from 'components/formElements';
import { ActingSubmitButton } from 'components/buttons';
import { SCHEDULE_TYPE_CENTER, SCHEDULE_TYPE_PERSON, TYPE_STAFF, TYPE_STUDENT } from 'constants/index';

const getState = (schedule) => {
  const { EffectiveDate, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday } = schedule;
  // effective date is required for a "person" schedule
  return {
    ...insertIf(!!EffectiveDate, 'EffectiveDate', EffectiveDate),
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday,
  };
};

const getFormClassName = (originType) => {
  if (originType === TYPE_STAFF || originType === TYPE_STUDENT) {
    return 'form--schedule--staff';
  }
  return 'form--schedule';
};

// @TODO: find out if we need to prevent schedule overlap
const ScheduleForm = (props) => {
  const { schedule, isSubmitting, cancelUrl, submitFn, scheduleType, originType } = props;
  const { EffectiveDate } = schedule;
  const [values, setValues] = useState(getState(schedule));
  const [copyValues] = useState({ ...values });
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  useEffect(() => {
    validationChangeForm(values, copyValues, setDisabledSubmit);
  }, [values]);

  const submitProps = {
    btnClassName: 'button',
    actionBtnClassName: 'button button--icon--loading',
    btnText: 'Save Schedule',
    actionText: 'Saving...',
    isActing: isSubmitting,
    isDisabled: disabledSubmit,
  };
  const fields = Object.keys(values)
    .filter((it) => it !== 'EffectiveDate')
    .map((it) => {
      const day = values[it];
      return (
        <div className="wrapper--flex" key={it}>
          <InputCheckbox
            id={`${it}.IsSelected`}
            labelText={it}
            isChecked={day.IsSelected}
            onChange={(e) => onFieldChange(values, setValues, e)}
          />
          <InputTime
            id={`${it}.TimeIn`}
            labelText="Time In"
            onChange={(e) => onFieldChange(values, setValues, e)}
            value={day.TimeIn}
            isRequired={day.IsSelected}
            isDisabled={!day.IsSelected}
          />
          <InputTime
            id={`${it}.TimeOut`}
            labelText="Time Out"
            onChange={(e) => onFieldChange(values, setValues, e)}
            value={day.TimeOut}
            isRequired={day.IsSelected}
            isDisabled={!day.IsSelected}
          />
          {(originType === TYPE_STAFF || originType === TYPE_STUDENT) && (
            <ClassroomSelect
              id={`${it}.Classroom`}
              labelText="Classroom"
              value={day.Classroom}
              onChange={(e) => onFieldChange(values, setValues, e)}
              isRequired={day.IsSelected}
              isDisabled={!day.IsSelected}
            />
          )}
        </div>
      );
    });
  const effectiveD =
    scheduleType === SCHEDULE_TYPE_PERSON && EffectiveDate
      ? moment(EffectiveDate, config.formats.momentDateSystem)
      : null;
  return (
    <Form
      onSubmit={() => {
        submitFn(values);
      }}
      className={getFormClassName(originType)}
    >
      {scheduleType === SCHEDULE_TYPE_PERSON && (
        <SingleDatePickerWrapper
          id="EffectiveDate"
          labelText="Effective Date"
          placeholder="mm/dd/yyyy"
          hintText="Please use this format: MM/DD/YYYY"
          onClose={(date) => onDateChange(values, setValues, 'EffectiveDate', date)}
          onChange={(date) => onDateChange(values, setValues, 'EffectiveDate', date)}
          initialDate={effectiveD}
          displayFormat={config.formats.momentDate}
          numberOfMonths={1}
          isOutsideRange={() => false}
          appendToBody={false}
          isRequired
          block
        />
      )}
      {fields}
      <div className="form-actions">
        <Link className="button--text--cancel" to={cancelUrl}>
          Cancel
        </Link>
        <ActingSubmitButton {...submitProps} />
      </div>
    </Form>
  );
};

ScheduleForm.propTypes = {
  submitFn: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  cancelUrl: PropTypes.string.isRequired,
  scheduleType: PropTypes.oneOf([SCHEDULE_TYPE_CENTER, SCHEDULE_TYPE_PERSON]).isRequired,
  schedule: PropTypes.shape(),
  originType: PropTypes.string,
};

ScheduleForm.defaultProps = {
  schedule: {},
  originType: '',
};

export default ScheduleForm;
