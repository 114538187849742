import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const API_ID = 'STUDENT_UPDATE_';

const studentUpdateRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const studentUpdateSuccess = (id) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id,
  },
});

const studentUpdateReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const studentUpdateFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const updateStudent = (id, data) => (dispatch) => {
  dispatch(studentUpdateRequest());
  api
    .updateMember(id, data, true)
    .then(() => {
      dispatch(studentUpdateSuccess(id));
    })
    .then(() => dispatch(studentUpdateReset()))
    .catch((err) => {
      dispatch(studentUpdateFailure(err));
    });
};
