import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const API_ID = 'MEMBER_UNLINK_';

const memberUnLinkRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const memberUnLinkSuccess = (id) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id,
  },
});

const memberUnLinkReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const memberUnLinkFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

const removeRelationship = (idA, idB, dispatch) => {
  api
    .updateMember(
      idA,
      {
        relationships: {
          [idB]: api.fv.delete(),
        },
      },
      true,
    )
    .then(() => {
      dispatch(memberUnLinkSuccess(idA));
    })
    .then(() => {
      dispatch(memberUnLinkReset());
    })
    .catch((err) => {
      dispatch(memberUnLinkFailure(err));
    });
};

export const unlinkMember = (id, targetId) => (dispatch) => {
  dispatch(memberUnLinkRequest());
  removeRelationship(id, targetId, dispatch);
  removeRelationship(targetId, id, dispatch);
};
