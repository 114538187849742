import { _MODAL_OPEN, _MODAL_CLOSE } from 'constants/index';

const openModal = (menuId) => ({
  type: `${menuId.toUpperCase()}${_MODAL_OPEN}`,
});

const closeModal = (menuId) => ({
  type: `${menuId.toUpperCase()}${_MODAL_CLOSE}`,
});

export const toggleModal = (modalId, shouldOpen = false) => (dispatch, getState) => {
  const {
    ui: { modals },
  } = getState();
  const { isOpen } = modals[modalId];
  if (isOpen && !shouldOpen) {
    dispatch(closeModal(modalId));
  } else if (!isOpen && shouldOpen) {
    dispatch(openModal(modalId));
  }
};
