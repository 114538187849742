import {
  _MENU_OPEN,
  _MENU_CLOSE,
  _MODAL_OPEN,
  _MODAL_CLOSE,
  API_REQUEST,
  API_SUCCESS,
  API_FAILURE,
  API_RESET,
  LIST_SUBSCRIBE,
  LIST_UNSUBSCRIBE,
  LIST_SUCCESS,
  LIST_FAILURE,
  CHANGE_TENANT,
} from 'constants/index';

/**
 * Creates redux reducers from an initial state and map of action types -> reducer fns
 * @param initialState The initial state of the reducer
 * @param reducerMap A map of action types to reducer functions
 * @returns {function(*=, *): *}
 */
export const createReducer = (initialState, reducerMap) => (state = initialState, action) => {
  const reducer = reducerMap[action.type];

  return reducer ? reducer(state, action.payload) : state;
};

export const createAPIReducer = (apiID, initialState) =>
  createReducer(initialState, {
    [`${apiID}${API_REQUEST}`]: () => ({
      isRequesting: true,
      didRequestWithSuccess: false,
      requestError: null,
      id: null,
    }),
    [`${apiID}${API_SUCCESS}`]: (_state, { id }) => ({
      isRequesting: false,
      didRequestWithSuccess: true,
      requestError: null,
      id,
    }),
    [`${apiID}${API_FAILURE}`]: (_state, { message }) => ({
      isRequesting: false,
      didRequestWithSuccess: false,
      requestError: message,
      id: null,
    }),
    [`${apiID}${API_RESET}`]: () => ({
      isRequesting: false,
      didRequestWithSuccess: false,
      requestError: null,
      id: null,
    }),
  });

export const createListReducer = (apiID, initialState) =>
  createReducer(initialState, {
    [`${apiID}${LIST_SUBSCRIBE}`]: () => ({
      ...initialState,
    }),
    [`${apiID}${LIST_UNSUBSCRIBE}`]: () => ({
      ...initialState,
    }),
    [`${apiID}${LIST_SUCCESS}`]: (state, { items }) => ({
      ...state,
      items,
      fetchError: null,
    }),
    [`${apiID}${LIST_FAILURE}`]: (state, { message }) => ({
      ...state,
      items: {},
      fetchError: message,
    }),
    [CHANGE_TENANT]: () => ({
      ...initialState,
      shouldResubscribe: true,
    }),
  });

export const createRecordReducer = (apiID, initialState) =>
  createReducer(initialState, {
    [`${apiID}${API_REQUEST}`]: () => ({
      isRequesting: true,
      didRequestWithSuccess: false,
      requestError: null,
      data: {},
    }),
    [`${apiID}${API_SUCCESS}`]: (_state, { data }) => ({
      isRequesting: false,
      didRequestWithSuccess: true,
      requestError: null,
      data,
    }),
    [`${apiID}${API_FAILURE}`]: (state, { message }) => ({
      isRequesting: false,
      didRequestWithSuccess: false,
      requestError: message,
      data: {},
    }),
  });

export const createMenuReducer = (menuId, initialState) =>
  createReducer(initialState, {
    [`${menuId.toUpperCase()}${_MENU_OPEN}`]: () => ({
      isOpen: true,
    }),
    [`${menuId.toUpperCase()}${_MENU_CLOSE}`]: () => ({
      isOpen: false,
    }),
  });

export const createModalReducer = (modalId, initialState) =>
  createReducer(initialState, {
    [`${modalId.toUpperCase()}${_MODAL_OPEN}`]: () => ({
      isOpen: true,
    }),
    [`${modalId.toUpperCase()}${_MODAL_CLOSE}`]: () => ({
      isOpen: false,
    }),
  });
