import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getContacts, updateContact } from 'actions';
import { EmergencyContactForm } from 'components/form';
import { PageFocusview, Error } from 'components/layout';
import {
  ROUTE_STUDENT_VIEW,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_ADULT_VIEW,
  TYPE_STUDENT,
  TYPE_ADULT,
  TYPE_STAFF,
} from 'constants/index';

const closeUrl = (recordType, id) => {
  if (recordType === TYPE_STUDENT) {
    return `${ROUTE_STUDENT_VIEW}${id}/emergency-contacts/`;
  }
  if (recordType === TYPE_STAFF) {
    return `${ROUTE_STAFFMEMBER_VIEW}${id}/emergency-contacts/`;
  }
  return `${ROUTE_ADULT_VIEW}${id}/emergency-contacts/`;
};

const getValueNameObj = (contact) => {
  if (contact) {
    const { firstName, lastName } = contact;
    return { firstName, lastName };
  }
  return { firstName: '', lastName: '' };
};

const EmergencyContactUpdate = (props) => {
  const { id, entryId } = useParams();
  const dispatch = useDispatch();
  const { originType } = props;
  const { items, isFetching } = useSelector((state) => state.contact.list);
  const size = Object.keys(items).length;
  useEffect(() => {
    if (!size && !isFetching) {
      dispatch(getContacts(id));
    }
  }, [size, isFetching]);
  const { firstName, lastName } = getValueNameObj(items[entryId]);
  const { isRequesting, didRequestWithSuccess, requestError } = useSelector((state) => state.contact.update);

  if (!requestError && didRequestWithSuccess) {
    return <Redirect to={closeUrl(originType, id)} />;
  }
  // show the error if there is one
  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle={`Edit an ${firstName} ${lastName} emergency contact`}
      closeButtonUrl={closeUrl(originType, id)}
      showCloseButton
    >
      {!size && <div className="loading" />}
      {!!requestError && <Error error={requestError} />}
      {!!size && (
        <EmergencyContactForm
          emergencyContact={items[entryId]}
          submitFn={(values) => {
            const data = { ...items[entryId], ...values };
            dispatch(updateContact(entryId, data));
          }}
          isSubmitting={isRequesting}
          cancelUrl={closeUrl(originType, id)}
        />
      )}
    </PageFocusview>
  );
};

EmergencyContactUpdate.propTypes = {
  originType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
};

export default EmergencyContactUpdate;
