import { api } from 'index';
import { LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE } from 'constants/index';

const API_ID = 'TENANT_';

const tenantListRequest = () => ({
  type: `${API_ID}${LIST_REQUEST}`,
});

const tenantListSuccess = ({ items }) => ({
  type: `${API_ID}${LIST_SUCCESS}`,
  payload: {
    items,
  },
});

const tenantListFailure = (message) => ({
  type: `${API_ID}${LIST_FAILURE}`,
  payload: {
    message,
  },
});

export const getTenants = (userTenants, isAdmin) => (dispatch) => {
  dispatch(tenantListRequest);
  api
    .listTenants(userTenants, isAdmin)
    .then(({ docs }) => {
      const items = docs.map((it) => ({ refId: it.id, ...it.data() }));
      dispatch(
        tenantListSuccess({
          items,
        }),
      );
    })
    .catch((err) => {
      dispatch(tenantListFailure(err));
    });
};
