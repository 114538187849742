import { TIMESHEET_ALL_EXPAND_TOGGLE, TIMESHEET_ROW_EXPAND_TOGGLE } from 'constants/index';

const timesheetAllToggle = (tableId, toggleValue) => ({
  type: TIMESHEET_ALL_EXPAND_TOGGLE,
  payload: {
    tableId,
    toggleValue,
  },
});

export const toggleAll = (tableId, toggleValue) => (dispatch) => {
  dispatch(timesheetAllToggle(tableId, toggleValue));
};

const timesheetRowToggle = (rowId, toggleValue) => ({
  type: TIMESHEET_ROW_EXPAND_TOGGLE,
  payload: {
    rowId,
    toggleValue,
  },
});

export const toggleRow = (rowId, toggleValue) => (dispatch) => {
  dispatch(timesheetRowToggle(rowId, toggleValue));
};
