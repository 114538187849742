import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const API_ID = 'MEMBER_LINK_';

const memberLinkRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const memberLinkSuccess = (id) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id,
  },
});

const memberLinkReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const memberLinkFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const linkMembers = (idA, relA, idB, relB) => (dispatch) => {
  dispatch(memberLinkRequest());
  api
    .batchSetRelationships([
      {
        id: idA,
        rel: relA,
      },
      {
        id: idB,
        rel: relB,
      },
    ])
    .then(() => {
      dispatch(memberLinkSuccess(idA));
    })
    .then(() => {
      dispatch(memberLinkReset());
    })
    .catch((err) => {
      dispatch(memberLinkFailure(err));
    });
};
