import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { subscribeToAdults, unsubscribeFromAdults } from 'actions';
import { Header, PageActions, PageHeader, PageTitle, Wrapper, Error } from 'components/layout';
import { Table, TableRowAdult } from 'components/table';
import { NavUser, GlobalNav } from 'components/nav';
import { useSubscription } from 'utils/useSubscription';
import { ROUTE_ADULT_CREATE } from 'constants/index';

const headerLabels = [
  {
    label: 'Name',
    className: 'name',
  },
  {
    label: 'PIN',
    className: 'pin',
  },
  {
    label: 'Locked',
    className: 'locked',
  },
  {
    label: ' ',
    className: 'view',
  },
];

const getPageActions = (referrer, toggleValue, toggleAction) => [
  {
    key: 'toggle-inactive',
    isVisible: true,
    type: 'toggle',
    toggleProps: {
      id: 'toggleInactive',
      isHorizontal: true,
      toggleValue,
      toggleAction,
      label: 'Show Inactive',
    },
  },
  {
    key: 'add-party-student',
    classNames: 'button--action button--icon--add',
    text: 'Add Parent/Guardian',
    isVisible: true,
    isLink: true,
    url: ROUTE_ADULT_CREATE,
    referrer,
  },
];

const Adults = () => {
  const { pathname } = useLocation();
  const [toggleValue, setToggleValue] = useState(false);

  const toggleAction = () => {
    if (toggleValue) setToggleValue(false);
    else setToggleValue(true);
  };

  const { items, keys, size, fetchError } = useSubscription({
    subscribeFn: subscribeToAdults,
    unsubscribeFn: unsubscribeFromAdults,
    selectorKey: 'adults',
  });

  const itemRows = keys
    .filter((it) => items[it].isActive === !toggleValue)
    .map((it) => {
      const data = items[it];
      return <TableRowAdult data={data} key={data.id} />;
    });
  const headerChildren = (
    <>
      <NavUser />
    </>
  );
  return (
    <>
      <Header>{headerChildren}</Header>
      <GlobalNav />
      <Wrapper>
        <PageHeader>
          <PageTitle title="Parents, guardians, and other relatives" />
          <PageActions actions={getPageActions(pathname, toggleValue, toggleAction)} />
        </PageHeader>
        {!!fetchError && <Error error={fetchError} />}
        {!!size && <Table id="parents-guardians" headerLabels={headerLabels} rows={itemRows} />}
      </Wrapper>
    </>
  );
};

export default Adults;
