import { createReducer } from 'utils/index';
import { NOTIFY_SMS_REQUEST, NOTIFY_SMS_SUCCESS, NOTIFY_SMS_FAILURE, NOTIFY_SMS_RESET } from 'constants/index';

const initialState = {
  sendResult: null,
  isSending: false,
  didSend: false,
  error: null,
  shouldReset: false,
};

export default createReducer(initialState, {
  [NOTIFY_SMS_REQUEST]: () => ({
    ...initialState,
    isSending: true,
    shouldReset: false,
  }),
  [NOTIFY_SMS_SUCCESS]: (state, { result }) => ({
    ...state,
    sendResult: result,
    isSending: false,
    didSend: true,
  }),
  [NOTIFY_SMS_FAILURE]: (state, { message }) => ({
    ...state,
    isSending: false,
    didSend: false,
    error: message,
  }),
  [NOTIFY_SMS_RESET]: () => ({
    ...initialState,
    shouldReset: true,
  }),
});
