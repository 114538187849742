import React from 'react';
import { Route, Switch, Redirect, useParams, useLocation } from 'react-router-dom';
import { subscribeToStudents, unsubscribeFromStudents } from 'actions';
import { PageFocusview, ProfileInfo } from 'components/layout';
import { Tabs, TabContent, TabsNav } from 'components/tabs';
import {
  PersonalInformationTab,
  RelatedPartiesTab,
  SchedulesTab,
  EmergencyContactsTab,
} from 'components/memberProfiles';
import {
  ROUTE_STUDENT_LIST,
  ROUTE_STUDENT_PROFILE,
  ROUTE_STUDENT_VIEW,
  PROFILE_TYPE_STUDENT,
  TYPE_STUDENT,
  ROUTE_STUDENT_EMERGENCY_CONTACTS,
} from 'constants/index';
import { getProfileStatus, isEmpty } from 'utils';
import { useSubscription } from 'utils/useSubscription';

export const StudentView = () => {
  const { id } = useParams();

  if (!id) return <Redirect to={ROUTE_STUDENT_LIST} />;

  const location = useLocation();

  const { items, size } = useSubscription({
    subscribeFn: subscribeToStudents,
    unsubscribeFn: unsubscribeFromStudents,
    selectorKey: 'students',
  });

  const record = size ? items[id] : {};

  const pageTitle = !isEmpty(record) ? `${record.lastName}, ${record.firstName}` : '';
  const profileInfo = <ProfileInfo profileType={PROFILE_TYPE_STUDENT} profileStatus={getProfileStatus(record)} />;

  const tabsNavItems = [
    {
      key: 'personalInfo',
      tabUrl: `${ROUTE_STUDENT_VIEW}${id}/`,
      tabLabel: 'Personal Information',
      isActive: location.pathname === `${ROUTE_STUDENT_VIEW}${id}/`,
    },
    {
      key: 'relatedParties',
      tabUrl: `${ROUTE_STUDENT_VIEW}${id}/related-parties/`,
      tabLabel: 'Related Parties',
      isActive: location.pathname.includes('related-parties'),
    },
    {
      key: 'EmergencyContacts',
      tabUrl: `${ROUTE_STUDENT_VIEW}${id}/emergency-contacts/`,
      tabLabel: 'Emergency Contacts',
      isActive: location.pathname.includes('emergency-contacts'),
    },
    {
      key: 'studentSchedules',
      tabUrl: `${ROUTE_STUDENT_VIEW}${id}/schedule/`,
      tabLabel: 'Schedules',
      isActive: location.pathname.includes('schedule'),
    },
  ];

  const tabsNav = <TabsNav navItems={tabsNavItems} />;

  return (
    <PageFocusview
      pageTitle={pageTitle}
      pageTitleChild={profileInfo}
      pageHeaderClassNames={['header--highlighted']}
      showCloseButton
      closeButtonUrl={ROUTE_STUDENT_LIST}
    >
      <Tabs tabsNav={tabsNav}>
        <Switch>
          <Route exact path={ROUTE_STUDENT_PROFILE}>
            <TabContent id="personalInfo" isShowing={location.pathname === `${ROUTE_STUDENT_VIEW}${id}/`}>
              <PersonalInformationTab record={record} recordType={TYPE_STUDENT} />
            </TabContent>
          </Route>
          <Route exact path={`${ROUTE_STUDENT_PROFILE}related-parties/`}>
            <TabContent id="relatedParties" isShowing={location.pathname.includes('related-parties')}>
              <RelatedPartiesTab record={record} recordType={TYPE_STUDENT} />
            </TabContent>
          </Route>
          <Route exact path={`${ROUTE_STUDENT_PROFILE}schedule/`}>
            <SchedulesTab recordType={TYPE_STUDENT} />
          </Route>
          <Route exact path={ROUTE_STUDENT_EMERGENCY_CONTACTS}>
            <EmergencyContactsTab record={record} recordType={TYPE_STUDENT} />
          </Route>
        </Switch>
      </Tabs>
    </PageFocusview>
  );
};
