import { combineReducers } from 'redux';
import { createAPIReducer } from 'utils/index';

const initialAPIState = {
  isRequesting: false,
  didRequestWithSuccess: false,
  requestError: null,
  id: null,
};

export default combineReducers({
  add: createAPIReducer('ADULT_ADD_', initialAPIState),
  update: createAPIReducer('ADULT_UPDATE_', initialAPIState),
  del: createAPIReducer('ADULT_DELETE_', initialAPIState),
});
