import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const API_ID = 'TIMESHEET_ADD_';

const timesheetAddRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const timesheetAddSuccess = (id) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id,
  },
});

const timesheetAddReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const timesheetAddFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const addTimesheet = (data) => (dispatch) => {
  dispatch(timesheetAddRequest());
  const docRef = api.createTimesheetDoc();
  api
    .addTimesheet(docRef, data)
    .then(() => {
      dispatch(timesheetAddSuccess(docRef.id));
    })
    .then(() => dispatch(timesheetAddReset()))
    .catch((err) => {
      dispatch(timesheetAddFailure(err));
    });
};

export const addTimesheets = (data) => (dispatch) => {
  dispatch(timesheetAddRequest());
  api
    .batchAddTimesheets(data)
    .then(() => {
      dispatch(timesheetAddSuccess());
      dispatch(timesheetAddReset());
    })
    .catch((err) => {
      dispatch(timesheetAddFailure(err));
    });
};
