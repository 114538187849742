import { api } from 'index';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  CHANGE_TENANT,
} from 'constants/index';
import config from 'conf';
import { getSelectedTenant } from 'utils';
import { unsubscribeFromStaff } from './staff/staffList';
import { unsubscribeFromStudents } from './student/studentList';
import { unsubscribeFromAdults } from './adult/adultList';
import { unsubscribeFromTimesheets } from './timesheet/timesheetList';

const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = ({ userId, email, selectedTenant, fullName, userTenants }) => ({
  type: LOGIN_SUCCESS,
  payload: {
    userId,
    fullName,
    email,
    selectedTenant,
    userTenants,
  },
});

const loginFailure = (message) => ({
  type: LOGIN_FAILURE,
  payload: {
    message,
  },
  error: true,
});

const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
});

const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

const tenantChange = (tenantId) => ({
  type: CHANGE_TENANT,
  payload: {
    selectedTenant: tenantId,
  },
});

export const changeTenant = (tenantId) => (dispatch) => {
  dispatch(tenantChange(tenantId));
  api.setTenantId(tenantId);
  if (tenantId) {
    localStorage.setItem(config.tenant.key, tenantId);
  } else {
    localStorage.removeItem(config.tenant.key);
  }
};

export const logout = () => (dispatch) => {
  dispatch(unsubscribeFromStaff());
  dispatch(unsubscribeFromStudents());
  dispatch(unsubscribeFromAdults());
  dispatch(unsubscribeFromTimesheets());
  dispatch(logoutRequest());
  api.doSignOut().then(() => {
    localStorage.removeItem(config.tenant.key);
    dispatch(logoutSuccess());
  });
};

export const login = (email, password) => (dispatch) => {
  dispatch(loginRequest());
  api
    .doSignInWithEmailAndPassword(email, password)
    .then(({ user }) => {
      const { uid } = user;
      api
        .getUser(uid)
        .then(({ docs }) => {
          const [first] = docs;
          const { name, tenantId: userTenants } = first.data();
          const selectedTenant = getSelectedTenant(userTenants, null);
          dispatch(changeTenant(selectedTenant));
          dispatch(
            loginSuccess({
              userId: user.uid,
              fullName: name,
              email: user.email,
              userTenants,
              selectedTenant,
            }),
          );
        })
        .catch((e) => {
          const { message } = e;
          dispatch(loginFailure(message));
        });
    })
    .catch((e) => {
      const { message } = e;
      dispatch(loginFailure(message));
    });
};
