import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/buttons';

class TableActions extends PureComponent {
  static propTypes = {
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        classNames: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
      }),
    ),
  };

  static defaultProps = {
    actions: [],
  };

  render() {
    const { actions } = this.props;
    const children = actions.map((it) => {
      return (
        <li key={it.key}>
          <Button className={it.classNames} onClick={it.onClick}>
            {it.text}
          </Button>
        </li>
      );
    });
    return (
      <div className="table-actions">
        <ul>{children}</ul>
      </div>
    );
  }
}

export default TableActions;
