import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Form } from 'components/layout';
import { ActingSubmitButton } from 'components/buttons';
import { InputText, InputSelect } from 'components/formElements';
import { onFieldChange, isEmpty, validationChangeForm } from 'utils/index';
import { EMPTY_STRING } from 'constants/index';

const getState = (classroom) => {
  if (isEmpty(classroom)) {
    return {
      name: '',
      nickName: '',
      minMonths: 0,
      maxMonths: 0,
      ratio: 0,
      maxSize: 0,
    };
  }
  return {
    ...classroom,
  };
};
const getOptions = (stateClassrooms) => {
  return [
    {
      label: EMPTY_STRING,
      value: EMPTY_STRING,
    },
  ].concat(
    Object.keys(stateClassrooms)
      .sort((a, b) => stateClassrooms[a].maxMonths - stateClassrooms[b].maxMonths)
      .map((it) => {
        const { name, maxSize, ratio } = stateClassrooms[it];
        return {
          label: name === 'Admin/Office' ? name : `${name} (1:${ratio}, ${maxSize} max)`,
          value: it,
        };
      }),
  );
};
const getSelectValue = (name, stateClassrooms) => {
  return Object.keys(stateClassrooms).find((it) => stateClassrooms[it].name === name);
};

const ClassroomForm = (props) => {
  const { isSubmitting, submitFn, classroom, cancelUrl, stateClassrooms } = props;
  const [values, setValues] = useState(getState(classroom));
  const [copyValues] = useState({ ...values });
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  useEffect(() => {
    validationChangeForm(values, copyValues, setDisabledSubmit);
  }, [values]);

  const submitProps = {
    btnClassName: 'button',
    actionBtnClassName: 'button button--icon--loading',
    btnText: 'Save',
    actionText: 'Saving...',
    isActing: isSubmitting,
    isDisabled: disabledSubmit,
  };
  const { classroomId } = useParams();

  return (
    <Form
      onSubmit={() => {
        submitFn(values);
      }}
    >
      <InputText
        id="nickName"
        labelText="Classroom name"
        onChange={(e) => onFieldChange(values, setValues, e)}
        value={values.nickName}
        isRequired
      />
      {!classroomId && (
        <InputSelect
          id="name"
          labelText="Age Range"
          options={getOptions(stateClassrooms)}
          value={getSelectValue(values.name, stateClassrooms)}
          onChange={(e) => {
            const data = { ...stateClassrooms[e.target.value], nickName: values.nickName };
            const { refId, ...rest } = data;
            setValues({ ...rest });
          }}
          isRequired
        />
      )}
      <div className="form-actions">
        <Link className="button--text--cancel" to={cancelUrl}>
          Cancel
        </Link>
        <ActingSubmitButton {...submitProps} />
      </div>
    </Form>
  );
};

ClassroomForm.propTypes = {
  submitFn: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  cancelUrl: PropTypes.string.isRequired,
  classroom: PropTypes.shape(),
  stateClassrooms: PropTypes.shape(),
};

ClassroomForm.defaultProps = {
  classroom: {
    name: '',
    nickName: '',
    minMonths: 0,
    maxMonths: 0,
    ratio: 0,
    maxSize: 0,
  },
  isSubmitting: false,
  stateClassrooms: {},
};

export default ClassroomForm;
