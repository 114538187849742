export * from './routes';
export * from './api';

export const APP_LOAD_START = 'APP_LOAD_START';
export const APP_LOAD_END = 'APP_LOAD_END';

export const TIMESHEET_ALL_EXPAND_TOGGLE = 'TIMESHEET_ALL_EXPAND_TOGGLE';
export const TIMESHEET_ROW_EXPAND_TOGGLE = 'TIMESHEET_ROW_EXPAND_TOGGLE';

export const CHANGE_TENANT = 'CHANGE_TENANT';

/* eslint-disable no-underscore-dangle */
export const _MENU_OPEN = '_MENU_OPEN';
export const _MENU_CLOSE = '_MENU_CLOSE';
export const _MODAL_OPEN = '_MODAL_OPEN';
export const _MODAL_CLOSE = '_MODAL_CLOSE';
/* eslint-enable */

export const REPORT_TYPE_ATTENDANCE = 'Student';
export const REPORT_TYPE_PAYROLL = 'StaffMember';
export const REPORT_TYPE_ALL = 'REPORT_ALL';

export const TYPE_STUDENT = 'Student';
export const TYPE_STAFF = 'StaffMember';
export const TYPE_ADULT = 'Adult';
export const TYPE_ALL = 'All';

export const TYPE_STUDENT_PATH = 'student';
export const TYPE_STAFF_PATH = 'staff-member';
export const TYPE_ADULT_PATH = 'adult';

export const REL_TYPE_OTHER = 'Other';
export const REL_TYPE_PARENT_GUARDIAN = 'Parent/Guardian';
export const REL_TYPE_SIBLING = 'Sibling';

export const LIST_TYPE_RELATED = 'related-parties';
export const LIST_TYPE_EMERGENCY = 'emergency-contacts';

export const TYPE_SCHEDULE = 'Schedule';
export const TYPE_PLANNED_ABSENCE = 'PlannedAbsence';

export const EMPTY_STRING = '';

export const BILLING_TYPE_PERC = 'PERC';
export const BILLING_TYPE_FIXED = 'FIXED';

export const CHECKED_OUT = 'Checked out';
export const CHECKED_IN = 'Checked in';
export const OTHER_START = 'Other: Start';
export const OTHER_END = 'Other: End';
export const ACTION_OTHER = 'Other';
export const NO_ACTION_SELECTED = 'No action selected';

export const PROFILE_TYPE_ADULT = 'adult';
export const PROFILE_TYPE_STUDENT = 'child';
export const PROFILE_TYPE_STAFF = 'staff member';

export const PROFILE_ACTIVE = 'active';
export const PROFILE_INACTIVE = 'inactive';
export const PROFILE_ARCHIVED = 'archived';

export const SCHEDULE_TYPE_CENTER = 'CenterSchedule';
export const SCHEDULE_TYPE_PERSON = 'PersonSchedule';

export const PERMISSION_TYPE_ATTENDANCE = 'attendance';
export const PERMISSION_TYPE_BILLING = 'billing';

export const MODE_UPDATE = 'MODE_UPDATE';
export const MODE_CREATE = 'MODE_CREATE';

export const WORKFLOW_CREATE = 'WORKFLOW_CREATE';

export const PERMISSION_LABELS = {
  [PERMISSION_TYPE_ATTENDANCE]: 'Drop Off/Pick Up',
  [PERMISSION_TYPE_BILLING]: 'Payment',
};

export const TIMESHEET_LABEL_ATTENDANCE = 'Attendance';
export const TIMESHEET_LABEL_PAYROLL = 'Payroll';

export const OPTIONS_RELATIONSHIPS = [
  { label: EMPTY_STRING, value: EMPTY_STRING },
  { label: REL_TYPE_PARENT_GUARDIAN, value: REL_TYPE_PARENT_GUARDIAN },
  { label: REL_TYPE_OTHER, value: REL_TYPE_OTHER },
];

export const OPTIONS_BILLING_AMOUNTS = [
  { label: EMPTY_STRING, value: EMPTY_STRING },
  { label: 'Percentage', value: BILLING_TYPE_PERC },
  { label: 'Fixed Amount', value: BILLING_TYPE_FIXED },
];

export const DATE_PICKER_OPEN_DIRECTION = {
  Down: 'down',
  Up: 'up',
};

export const DAYS_OF_WEEK = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
};
