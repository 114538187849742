import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from 'components/table';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'components/buttons';
import { useModal } from 'utils/useModal';
import { ConfirmModal } from 'components/modal';
import { Error } from 'components/layout';
import { deleteContact } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  ROUTE_STUDENT_VIEW,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_ADULT_VIEW,
  TYPE_STUDENT,
  TYPE_STAFF,
} from 'constants/index';
import { isEmpty } from 'utils';

const getLinkUrl = (personType, id) => {
  if (personType === TYPE_STUDENT) {
    return `${ROUTE_STUDENT_VIEW}${id}/`;
  }
  if (personType === TYPE_STAFF) {
    return `${ROUTE_STAFFMEMBER_VIEW}${id}/`;
  }
  return `${ROUTE_ADULT_VIEW}${id}/`;
};

const TableRowEmergencyContact = (props) => {
  const { name, rel, type, mobilePhone, homePhone, workPhone, personType, entryId, linkId } = props;
  const { isShowing, toggle } = useModal();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isRequesting, didRequestWithSuccess, requestError } = useSelector((state) => state.contact.del);

  return (
    <>
      {!isRequesting && !!requestError && <Error error={requestError} />}
      <TableRow>
        <li className="tc--name">{type === 'link' ? <Link to={getLinkUrl(personType, linkId)}>{name}</Link> : name}</li>
        <li className="tc--relationship">{rel}</li>
        <li className="tc--contacts">
          <span className="contact">
            <span className="label">Mobile:</span>&nbsp;
            {isEmpty(mobilePhone) && 'N/A'}
            {!isEmpty(mobilePhone) && (
              <a className="link" href={`tel:+1${mobilePhone}`}>
                {mobilePhone}
              </a>
            )}
          </span>
          <span className="contact">
            <span className="label">Home:</span>&nbsp;
            {isEmpty(homePhone) && 'N/A'}
            {!isEmpty(homePhone) && (
              <a className="link" href={`tel:+1${homePhone}`}>
                {homePhone}
              </a>
            )}
          </span>
          <span className="contact">
            <span className="label">Work:</span>&nbsp;
            {isEmpty(workPhone) && 'N/A'}
            {!isEmpty(workPhone) && (
              <a className="link" href={`tel:+1${workPhone}`}>
                {workPhone}
              </a>
            )}
          </span>
        </li>
        <li className="tc--edit">
          {type === 'entry' && (
            <Link className="button--text" to={`/student/${id}/emergency-contact/${entryId}/update`}>
              Edit
            </Link>
          )}
        </li>
        <li className="tc--remove">
          <Button className="button--text--neg" onClick={toggle}>
            Remove
          </Button>
        </li>
      </TableRow>
      <ConfirmModal
        isShowing={isShowing}
        title="Remove Emergency Contact"
        content={`Are you sure you want to remove ${name} from this child's record?`}
        isRequesting={isRequesting}
        doAction={() => {
          dispatch(deleteContact(entryId, id));
          if (didRequestWithSuccess) {
            toggle();
          }
        }}
        hide={toggle}
        confirmLabel="Remove Emergency Contact"
        cancelLabel="Cancel"
      />
    </>
  );
};
TableRowEmergencyContact.propTypes = {
  name: PropTypes.string,
  rel: PropTypes.string,
  type: PropTypes.string,
  // memberId: PropTypes.string,
  mobilePhone: PropTypes.string,
  homePhone: PropTypes.string,
  workPhone: PropTypes.string,
  personType: PropTypes.string,
  entryId: PropTypes.string.isRequired,
  linkId: PropTypes.string,
};
TableRowEmergencyContact.defaultProps = {
  // memberId: '',
  mobilePhone: 'N/A',
  homePhone: 'N/A',
  workPhone: 'N/A',
  name: 'N/A',
  rel: 'N/A',
  type: 'N/A',
  personType: 'Adult',
  linkId: '',
};

export default TableRowEmergencyContact;
