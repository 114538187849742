import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET, TYPE_ADULT } from 'constants/index';

const API_ID = 'ADULT_ADD_';

const adultAddRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const adultAddSuccess = (id) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id,
  },
});

const adultAddReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const adultAddFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const addAdult = (data) => (dispatch) => {
  dispatch(adultAddRequest());
  const docRef = api.createMemberDoc();
  api
    .addMember(docRef, TYPE_ADULT, data)
    .then(() => {
      dispatch(adultAddSuccess(docRef.id));
    })
    .then(() => dispatch(adultAddReset()))
    .catch((err) => {
      dispatch(adultAddFailure(err));
    });
};
