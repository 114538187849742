import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/buttons';

class DropMenu extends Component {
  static propTypes = {
    menuChildren: PropTypes.node.isRequired,
    btnContent: PropTypes.node.isRequired,
    btnClassName: PropTypes.string,
    menuClassName: PropTypes.string,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
  };

  static defaultProps = {
    isOpen: false,
    onOpen: null,
    onClose: null,
    btnClassName: '',
    menuClassName: '',
  };

  constructor(props) {
    super(props);
    const { isOpen } = props;
    this.state = {
      isOpen,
    };
  }

  open = () => {
    this.setState(
      () => ({
        isOpen: true,
      }),
      () => {
        const { onOpen } = this.props;
        if (onOpen) onOpen();
      },
    );
  };

  close = () => {
    this.setState(
      () => ({
        isOpen: false,
      }),
      () => {
        const { onClose } = this.props;
        if (onClose) onClose();
      },
    );
  };

  toggle = () => {
    const { isOpen } = this.state;
    // eslint-disable-next-line no-unused-expressions
    isOpen ? this.close() : this.open();
  };

  render() {
    const { btnClassName, btnContent, menuClassName, menuChildren } = this.props;
    const { isOpen } = this.state;
    const menuClassNames = [];
    const btnClassNames = ['nav-trigger'];
    if (btnClassName) btnClassNames.push(btnClassName);
    if (menuClassName) menuClassNames.push(menuClassName);
    if (isOpen) {
      btnClassNames.push('expanded');
      menuClassNames.push('visible');
    }
    return (
      <>
        <Button className={`${btnClassNames.join(' ')}`} onClick={this.toggle}>
          {btnContent}
        </Button>
        <nav className={`${menuClassNames.join(' ')}`}>{menuChildren}</nav>
      </>
    );
  }
}

export default DropMenu;
