import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import 'react-dates/initialize';
import API from './API';
import configureStore from './store/configureStore';
import {
  loginSuccess,
  startAppLoad,
  endAppLoad,
  subscribeToAdults,
  subscribeToStudents,
  getTenants,
  getClassrooms,
} from './actions';
import { getSelectedTenant, getSavedTenant } from './utils';
import config from './conf';
import Root from './components/containers/Root';

/* eslint-disable no-underscore-dangle */
export const store = configureStore(window.__INITIAL_STATE__);
/* eslint-enable */

store.dispatch(startAppLoad());

export const api = new API();

const target = document.getElementById('root');

if (config.isProd) {
  console.log('Initializing Sentry');
  Sentry.init({
    dsn: config.sentryDsn,
    release: `admin-webapp@${config.version}`,
  });
}

// wait for auth to initialize
// so we know if we have an authenticated user or not
api.auth.onAuthStateChanged(async (user) => {
  let tenant = null;
  if (user) {
    // get user metadata and add to store when/if available
    api
      .getUser(user.uid)
      .then(async ({ docs }) => {
        const [first] = docs;
        const { name, tenantId: userTenants } = first.data();
        const selectedTenant = getSavedTenant(config);
        tenant = getSelectedTenant(userTenants, selectedTenant);
        store.dispatch(
          loginSuccess({
            userId: user.uid,
            fullName: name,
            email: user.email,
            userTenants,
            selectedTenant: tenant,
          }),
        );
      })
      .catch((e) => console.error(e))
      .finally(async () => {
        if (tenant) {
          const {
            auth: { userTenants },
          } = store.getState();
          api.setTenantId(tenant);
          localStorage.setItem(config.tenant.key, tenant);

          // fetch data to the store that we use everywhere
          store.dispatch(getTenants(userTenants, userTenants.includes('*')));
          store.dispatch(getClassrooms());
          store.dispatch(subscribeToAdults());
          store.dispatch(subscribeToStudents());
        }
        store.dispatch(endAppLoad());
      });
  } else {
    store.dispatch(endAppLoad());
  }
  ReactDOM.render(<Root store={store} />, target);
});
