import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { changeTenant, getTenants } from 'actions';
import { PageFocusview } from 'components/layout';
import { ActionLink } from 'components/links';
import { ROUTE_HOME } from 'constants/index';

const SelectTenant = () => {
  const dispatch = useDispatch();

  const selectedTenant = useSelector((state) => state.auth.selectedTenant);
  const userTenants = useSelector((state) => state.auth.userTenants);
  const tenantItems = useSelector((state) => state.tenants.items);
  const isFetching = useSelector((state) => state.tenants.isFetching);
  const isAdmin = userTenants.includes('*');

  useEffect(() => {
    if (!tenantItems.length) dispatch(getTenants(userTenants, userTenants.includes('*')));
  });

  const tenantList = tenantItems
    .filter((it) => isAdmin || userTenants.includes(it.id))
    .map((it) => {
      return (
        <ActionLink
          key={it.refId}
          linkText={it.name}
          onClick={() => {
            dispatch(changeTenant(it.id));
          }}
        />
      );
    });

  return selectedTenant ? (
    <Redirect to={ROUTE_HOME} />
  ) : (
    <>
      <PageFocusview
        pageTitle="Select Center"
        pageClassNames={['page--form']}
        wrapperClassNames={['width--s']}
        showNav={false}
      >
        <div className={isFetching ? 'list--actions loading' : 'list--actions'}>{tenantList}</div>
      </PageFocusview>
    </>
  );
};

export default SelectTenant;
