import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { useModal } from 'utils/useModal';
import { stringToMoment, getFormattedDate } from 'utils/index';
import { Error } from 'components/layout';
import {
  ROUTE_STUDENT_VIEW,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_ADULT_VIEW,
  TYPE_STUDENT,
  TYPE_ADULT,
  TYPE_STAFF,
} from 'constants/index';
import { Button } from 'components/buttons';
import { ConfirmModal } from 'components/modal';
import { deleteAbsence } from 'actions';
import { useDispatch, useSelector } from 'react-redux';

const getUrl = (id, absenceId, recordType) => {
  if (recordType === TYPE_STUDENT) {
    return `${ROUTE_STUDENT_VIEW}${id}/planned-absence/${absenceId}/update/`;
  }
  if (recordType === TYPE_STAFF) {
    return `${ROUTE_STAFFMEMBER_VIEW}${id}/planned-absence/${absenceId}/update/`;
  }
  return `${ROUTE_ADULT_VIEW}${id}/planned-absence/${absenceId}/update/`;
};

const TableRowPlannedAbsence = (props) => {
  const { absenceId, startDate, endDate, reason, originType } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { didRequestWithSuccess, isRequesting, requestError } = useSelector((state) => state.contact.del);
  const { isShowing, toggle } = useModal();
  return (
    <>
      {!!requestError && <Error error={requestError} />}
      <ul className={`table-row ${absenceId}`} key={absenceId}>
        <li className="tc--start-date">
          <span>{getFormattedDate(stringToMoment(startDate))}</span>
        </li>
        <li className="tc--end-date">
          <span>{getFormattedDate(stringToMoment(endDate))}</span>
        </li>
        <li className="tc--reason">
          <span>{reason}</span>
        </li>
        <li className="tc--edit">
          <Link className="button--text" to={getUrl(id, absenceId, originType)}>
            Edit
          </Link>
        </li>
        <li className="tc--delete">
          <Button className="button--text--neg" onClick={toggle}>
            Delete
          </Button>
        </li>
      </ul>
      <ConfirmModal
        isShowing={isShowing}
        title="Delete Planned Absence"
        content="Are you sure you want to delete this planned absence?"
        isRequesting={isRequesting}
        doAction={() => {
          dispatch(deleteAbsence(absenceId, id));
          if (didRequestWithSuccess) {
            toggle();
          }
        }}
        hide={toggle}
        cancelLabel="Cancel"
        confirmLabel="Delete Planned Absence"
      />
    </>
  );
};
TableRowPlannedAbsence.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  absenceId: PropTypes.any.isRequired,
  originType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
};

export default TableRowPlannedAbsence;
