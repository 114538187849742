import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TableRow } from 'components/table';
import { CHECKED_IN, CHECKED_OUT, NO_ACTION_SELECTED } from 'constants/index';
import { getInterimId, isEmpty } from 'utils/index';

const getActionClassName = (action) => {
  switch (action) {
    case CHECKED_IN:
      return ' action--1';
    case CHECKED_OUT:
      return ' action--2';
    case NO_ACTION_SELECTED:
      return ' action--3';
    default:
      return '';
  }
};

class TableRowExceptionEntry extends PureComponent {
  static propTypes = {
    data: PropTypes.shape(),
  };

  static defaultProps = {
    data: {},
  };

  render() {
    const { data } = this.props;
    const { id, dateFmt, time, action, actionDetails, isIgnoredReason } = data;
    const hasData = !isEmpty(data);

    return hasData ? (
      <TableRow key={id || getInterimId()}>
        <li className="tc--date">{dateFmt}</li>
        <li className="tc--time">{time}</li>
        <li className={`tc--action${getActionClassName(action)}`}>{action}</li>
        <li className="tc--details">{actionDetails}</li>
        <li className="tc--reason">{isIgnoredReason}</li>
      </TableRow>
    ) : null;
  }
}

export default TableRowExceptionEntry;
