import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { insertIf } from 'utils/index';

class NavFocusview extends PureComponent {
  static propTypes = {
    closeButtonUrl: PropTypes.string,
    showBackButton: PropTypes.bool,
    showCloseButton: PropTypes.bool,
    backButtonUrl: PropTypes.string,
    backButtonState: PropTypes.shape(),
    closeButtonState: PropTypes.shape(),
  };

  static defaultProps = {
    showCloseButton: false,
    showBackButton: false,
    backButtonUrl: null,
    closeButtonUrl: null,
    backButtonState: null,
    closeButtonState: null,
  };

  render() {
    const {
      showCloseButton,
      showBackButton,
      closeButtonUrl,
      backButtonUrl,
      backButtonState,
      closeButtonState,
    } = this.props;
    const backButtonProps = {
      pathname: backButtonUrl,
      ...insertIf(backButtonState, 'state', backButtonState),
    };
    const closeButtonProps = {
      pathname: closeButtonUrl,
      ...insertIf(closeButtonState, 'state', closeButtonState),
    };
    return (
      <nav className="nav--focusview">
        {showBackButton && <Link to={backButtonProps} className="button--back" />}
        {showCloseButton && <Link to={closeButtonProps} className="button--close" />}
      </nav>
    );
  }
}

export default NavFocusview;
