import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const API_ID = 'SCHEDULE_DELETE_';

const scheduleDeleteRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const scheduleDeleteSuccess = (memberId) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id: memberId,
  },
});

const scheduleDeleteReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const scheduleDeleteFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const deleteSchedule = (id, memberId) => (dispatch) => {
  dispatch(scheduleDeleteRequest());
  api
    .updateSchedule(id, {
      isArchived: true,
    })
    .then(() => {
      dispatch(scheduleDeleteSuccess(memberId));
    })
    .then(() => dispatch(scheduleDeleteReset()))
    .catch((err) => {
      dispatch(scheduleDeleteFailure(err));
    });
};
