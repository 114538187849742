import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TableOptions } from 'components/table';

const getPartyCount = (type, itemCount, ignoredItemCount) => {
  switch (type) {
    case 'Ignored attendance': {
      return <span>({ignoredItemCount} Students)</span>;
    }
    case 'Ignored payroll': {
      return <span>({ignoredItemCount} Staff Members)</span>;
    }
    case 'Attendance': {
      return <span>({itemCount} Students)</span>;
    }
    case 'Payroll':
    default: {
      return <span>({itemCount} Staff Members)</span>;
    }
  }
};

class TimesheetResults extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    start: PropTypes.string,
    end: PropTypes.string,
    tableOptions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        classNames: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
      }),
    ),
    itemCount: PropTypes.number,
    ignoredItemCount: PropTypes.number,
  };

  static defaultProps = {
    start: null,
    end: null,
    itemCount: 0,
    ignoredItemCount: 0,
    tableOptions: [],
  };

  render() {
    const { type, start, end, tableOptions, itemCount, ignoredItemCount } = this.props;
    return (
      <div className="table-head">
        <p className="table-title">
          <span className="type">{type}</span>
          records from
          <span className="date">{start}</span>
          to
          <span className="date">{end}</span>
          {getPartyCount(type, itemCount, ignoredItemCount)}
        </p>
        {!!tableOptions && <TableOptions options={tableOptions} />}
      </div>
    );
  }
}

export default TimesheetResults;
