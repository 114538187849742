import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/buttons';

class Wrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: 'wrapper--center',
  };

  constructor(props) {
    super(props);
    this.state = {
      isScrollButtonVisible: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollListener);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener);
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  scrollListener = () => {
    window.requestAnimationFrame(this.handleScroll);
  };

  handleScroll = () => {
    const { isScrollButtonVisible } = this.state;
    const showButton = window.scrollY >= 400;
    if (!isScrollButtonVisible && showButton) {
      this.setState(() => ({
        isScrollButtonVisible: true,
      }));
    } else if (isScrollButtonVisible && !showButton) {
      this.setState(() => ({
        isScrollButtonVisible: false,
      }));
    }
  };

  render() {
    const { children, className } = this.props;
    const { isScrollButtonVisible } = this.state;
    const classNames = ['scroll-top'];
    if (isScrollButtonVisible) classNames.push('visible');
    return (
      <main className={className}>
        {children}
        <Button className={classNames.join(' ')} onClick={this.scrollToTop} />
      </main>
    );
  }
}

export default Wrapper;
