import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import config from 'conf';
import { Link } from 'react-router-dom';
import isInclusivelyAfterDay from 'react-dates/src/utils/isInclusivelyAfterDay';
import isInclusivelyBeforeDay from 'react-dates/src/utils/isInclusivelyBeforeDay';
import { onDateChange, onFieldChange, validationChangeForm } from 'utils/index';
import { Form } from 'components/layout';
import { SingleDatePickerWrapper } from 'components/dates';
import { ActingSubmitButton } from 'components/buttons';
import { InputText } from 'components/formElements';

const labelText = (text) => {
  if (text === 'startDate') return 'Start Date';
  if (text === 'endDate') return 'End Date';
  return text;
};

// @TODO: find out if we need to prevent overlap
const isOutsideRange = (fieldId, sD, eD, day) => {
  if (fieldId === 'startDate') {
    return eD ? isInclusivelyBeforeDay(moment(eD, config.formats.momentDateSystem), day) : false;
  }
  return sD ? isInclusivelyAfterDay(moment(sD, config.formats.momentDateSystem), day) : false;
};

const AbsenceForm = (props) => {
  const { absence, isSubmitting, cancelUrl, submitFn } = props;
  const [values, setValues] = useState(absence);
  const [copyValues] = useState({ ...values });
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  useEffect(() => {
    validationChangeForm(values, copyValues, setDisabledSubmit);
  }, [values]);

  const submitProps = {
    btnClassName: 'button',
    actionBtnClassName: 'button button--icon--loading',
    btnText: 'Save',
    actionText: 'Saving...',
    isActing: isSubmitting,
    isDisabled: disabledSubmit,
  };
  const absenceForm = Object.keys(values).map((it) => {
    if (it === 'startDate' || it === 'endDate') {
      const absenceDate = values[it] ? moment(values[it], config.formats.momentDateSystem) : null;
      return (
        <SingleDatePickerWrapper
          id={it}
          labelText={labelText(it)}
          hintText="Please use this format: MM/DD/YYYY"
          placeholder="mm/dd/yyyy"
          onClose={(date) => onDateChange(values, setValues, it, date)}
          onChange={(date) => onDateChange(values, setValues, it, date)}
          initialDate={absenceDate}
          displayFormat={config.formats.momentDate}
          numberOfMonths={1}
          isOutsideRange={(day) => isOutsideRange(it, values.startDate, values.endDate, day)}
          appendToBody={false}
          block
          key={it}
        />
      );
    }
    return (
      <InputText
        labelText={`${it[0].toUpperCase()}${it.slice(1)}`}
        id={it}
        value={values[it]}
        onChange={(reason) => onFieldChange(values, setValues, reason)}
        key={it}
      />
    );
  });
  return (
    <Form
      onSubmit={() => {
        submitFn(values);
      }}
    >
      {absenceForm}
      <div className="form-actions">
        <Link className="button--text" to={cancelUrl}>
          Cancel
        </Link>
        <ActingSubmitButton {...submitProps} />
      </div>
    </Form>
  );
};

AbsenceForm.propTypes = {
  submitFn: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  cancelUrl: PropTypes.string.isRequired,
  absence: PropTypes.object.isRequired,
};
AbsenceForm.defaultProps = {
  isSubmitting: false,
};
export default AbsenceForm;
