import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';
import { getCenterSchedule } from './scheduleGet';

const API_ID = 'CENTER_SCHEDULE_UPDATE_';

const scheduleUpdateRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const scheduleUpdateSuccess = (id) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id,
  },
});

const scheduleUpdateFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

const scheduleUpdateReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

export const updateCenterSchedule = (data) => (dispatch) => {
  dispatch(scheduleUpdateRequest());
  api
    .updateCenterSchedule(data)
    .then(() => {
      dispatch(scheduleUpdateSuccess('schedule'));
      dispatch(getCenterSchedule());
    })
    .then(() => dispatch(scheduleUpdateReset()))
    .catch((err) => {
      dispatch(scheduleUpdateFailure(err));
    });
};
