import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { DateTime } from 'luxon';
import { TYPE_PLANNED_ABSENCE, TYPE_SCHEDULE } from 'constants/index';
import config from './conf';
import { getSavedTenant } from './utils/index';

const { apiConf } = config;

export default class API {
  constructor() {
    app.initializeApp(apiConf);
    this.auth = app.auth();
    this.db = app.firestore();
    this.fv = app.firestore.FieldValue; // for deleting fields
    this.tenantId = getSavedTenant(config);
  }

  setTenantId = (tenantId) => {
    this.tenantId = tenantId;
  };

  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    this.tenantId = null;
    return this.auth.signOut();
  };

  getUser = (uid) => {
    const { systemPath, settingsPath } = config.store;
    return this.db.collection(systemPath).doc(settingsPath).collection('users').where('id', '==', uid).get();
  };

  unsubscribeListener = (unsubListener) => unsubListener && unsubListener();

  subscribeToMemberList = (memberType, updateHandler) => {
    if (!this.tenantId) return null;
    const { dataPath, memberPath } = config.store;
    return (
      this.db
        .collection(this.tenantId)
        .doc(dataPath)
        .collection(memberPath)
        .orderBy('firstName', 'asc')
        .where('type', '==', memberType)
        // .where('isArchived', '==', false)
        .onSnapshot(updateHandler)
    );
  };

  subscribeToStaffMemberList = (updateHandler) => {
    if (!this.tenantId) return null;
    const { dataPath, memberPath } = config.store;
    return (
      this.db
        .collection(this.tenantId)
        .doc(dataPath)
        .collection(memberPath)
        .orderBy('firstName', 'asc')
        .where('isStaffMember', '==', true)
        // .where('isArchived', '==', false)
        .onSnapshot(updateHandler)
    );
  };

  getMember = (id) => {
    const { dataPath, memberPath } = config.store;
    return this.db.collection(this.tenantId).doc(dataPath).collection(memberPath).doc(id).get();
  };

  createMemberDoc = () => {
    const { dataPath, memberPath } = config.store;
    return this.db.collection(this.tenantId).doc(dataPath).collection(memberPath).doc();
  };

  getMemberDocRef = (docId) => {
    const { dataPath, memberPath } = config.store;
    return this.db.collection(this.tenantId).doc(dataPath).collection(memberPath).doc(docId);
  };

  addMember = (docRef, memberType, data) =>
    docRef.set({
      ...data,
      id: docRef.id,
      type: memberType,
      isActive: true,
      isArchived: false,
    });

  updateMember = (id, data, shouldMerge = false) => {
    const { dataPath, memberPath } = config.store;
    return this.db
      .collection(this.tenantId)
      .doc(dataPath)
      .collection(memberPath)
      .doc(id)
      .set(
        {
          ...data,
        },
        { merge: shouldMerge },
      );
  };

  deleteMember = (id, record) => {
    const { dataPath, memberPath } = config.store;
    const { relationships } = record;
    const relKeys = Object.keys(relationships);
    const deleteRels = relKeys.reduce((acc, next) => {
      return {
        ...acc,
        [next]: this.fv.delete(),
      };
    }, {});
    const params = {
      relationships: {
        ...deleteRels,
      },
      isArchived: true,
      isActive: false,
    };
    console.log(params);
    const batch = this.db.batch();
    const collectionRef = this.db.collection(this.tenantId).doc(dataPath).collection(memberPath);
    // delete this party
    batch.set(collectionRef.doc(id), params, { merge: true });
    // remove this party from any other party's relationship list
    relKeys.forEach((it) => {
      batch.set(
        collectionRef.doc(it),
        {
          relationships: {
            [id]: this.fv.delete(),
          },
        },
        { merge: true },
      );
    });
    return batch.commit();
  };

  subscribeToTimesheetQuery = (queryParams, updateHandler) => {
    if (!this.tenantId) return null;
    const { dataPath, timesheetPath } = config.store;
    const { startDate, endDate, type, memberId, hasSubsidy } = queryParams;
    const [sY, sM, sD] = startDate.split('-');
    const [eY, eM, eD] = endDate.split('-');
    const s = DateTime.fromObject({
      year: sY,
      month: sM,
      day: sD,
      hour: 0,
      minute: 0,
      second: 0,
      zone: config.timeZone,
    });
    const e = DateTime.fromObject({
      year: eY,
      month: eM,
      day: eD,
      hour: 23,
      minute: 59,
      second: 59,
      zone: config.timeZone,
    });
    // const s = moment(`${startDate} 00:00 -04:00`, 'Y-MM-DD HH:mm Z');
    // const e = moment(`${endDate} 23:59 -04:00`, 'Y-MM-DD HH:mm Z');
    let query = this.db
      .collection(this.tenantId)
      .doc(dataPath)
      .collection(timesheetPath)
      .where('timeStamp', '>=', s.valueOf())
      .where('timeStamp', '<=', e.valueOf())
      .where('type', '==', type);

    if (memberId) {
      query = query.where('memberId', '==', memberId);
    }

    if (hasSubsidy) {
      query = query.where('hasSubsidy', '==', hasSubsidy);
    }

    return query.onSnapshot(updateHandler);
  };

  getTsDocRef = (docId) => {
    const { dataPath, timesheetPath } = config.store;
    return this.db.collection(this.tenantId).doc(dataPath).collection(timesheetPath).doc(docId);
  };

  createTimesheetDoc = () => {
    const { dataPath, timesheetPath } = config.store;
    return this.db.collection(this.tenantId).doc(dataPath).collection(timesheetPath).doc();
  };

  getTimesheet = (id) => {
    const { dataPath, timesheetPath } = config.store;
    return this.db.collection(this.tenantId).doc(dataPath).collection(timesheetPath).doc(id).get();
  };

  addTimesheet = (docRef, data) => docRef.set(data);

  batchAddTimesheets = (data) => {
    const { dataPath, timesheetPath } = config.store;
    const batch = this.db.batch();
    const ref = this.db.collection(this.tenantId).doc(dataPath).collection(timesheetPath);
    data.forEach((it) => {
      batch.set(ref.doc(), it);
    });
    return batch.commit();
  };

  updateTimesheet = (id, data, shouldMerge = false) => {
    const { dataPath, timesheetPath } = config.store;
    return this.updateDoc(this.tenantId, dataPath, timesheetPath, id, data, shouldMerge);
  };

  batchSetRelationships = (data) => {
    const { dataPath, memberPath } = config.store;
    const batch = this.db.batch();
    const ref = this.db.collection(this.tenantId).doc(dataPath).collection(memberPath);
    data.forEach((it) => {
      batch.set(ref.doc(it.id), it.rel, { merge: true });
    });
    return batch.commit();
  };

  subscribeToSchedules = (memberId, updateHandler) => {
    if (!this.tenantId) return null;
    const { dataPath, schedulePath } = config.store;
    return this.db
      .collection(this.tenantId)
      .doc(dataPath)
      .collection(schedulePath)
      .where('memberId', '==', memberId)
      .where('isArchived', '==', false)
      .where('type', '==', TYPE_SCHEDULE)
      .orderBy('EffectiveDate', 'asc')
      .onSnapshot(updateHandler);
  };

  listSchedules = () => {
    if (!this.tenantId) return null;
    const { dataPath, schedulePath } = config.store;
    return this.listCollectionWithQuery(this.tenantId, dataPath, schedulePath, [
      ['isArchived', '==', false],
      ['type', '==', TYPE_SCHEDULE],
    ]);
  };

  subscribeToAbsences = (memberId, updateHandler) => {
    if (!this.tenantId) return null;
    // const today = DateTime.fromJSDate(new Date()).toISODate();
    const { dataPath, schedulePath } = config.store;
    return this.db
      .collection(this.tenantId)
      .doc(dataPath)
      .collection(schedulePath)
      .where('memberId', '==', memberId)
      .where('isArchived', '==', false)
      .where('type', '==', TYPE_PLANNED_ABSENCE)
      .orderBy('startDate', 'asc')
      .onSnapshot(updateHandler);
  };

  getSchedule = (id) => {
    const { dataPath, schedulePath } = config.store;
    return this.db.collection(this.tenantId).doc(dataPath).collection(schedulePath).doc(id).get();
  };

  addSchedule = (data) => {
    const { dataPath, schedulePath } = config.store;
    return this.db
      .collection(this.tenantId)
      .doc(dataPath)
      .collection(schedulePath)
      .doc()
      .set({
        ...data,
        isArchived: false,
      });
  };

  updateSchedule = (id, data) => {
    const { dataPath, schedulePath } = config.store;
    return this.updateDoc(this.tenantId, dataPath, schedulePath, id, data, true);
  };

  listContacts = (memberId) => {
    const { dataPath, contactsPath } = config.store;
    return this.listCollectionWithQuery(this.tenantId, dataPath, contactsPath, [
      ['memberId', '==', memberId],
      ['isArchived', '==', false],
    ]);
  };

  addContact = (data) => {
    const { dataPath, contactsPath } = config.store;
    return this.db
      .collection(this.tenantId)
      .doc(dataPath)
      .collection(contactsPath)
      .doc()
      .set({
        ...data,
        isArchived: false,
      });
  };

  updateContact = (id, data) => {
    const { dataPath, contactsPath } = config.store;
    return this.updateDoc(this.tenantId, dataPath, contactsPath, id, data, true);
  };

  listClassrooms = () => {
    const { dataPath, classroomPath } = config.store;
    return this.listCollectionWithQuery(this.tenantId, dataPath, classroomPath, [['isArchived', '==', false]]);
  };

  addClassroom = (data) => {
    const { dataPath, classroomPath } = config.store;
    return this.db
      .collection(this.tenantId)
      .doc(dataPath)
      .collection(classroomPath)
      .doc()
      .set({
        ...data,
        isArchived: false,
      });
  };

  updateClassroom = (id, data) => {
    const { dataPath, classroomPath } = config.store;
    return this.updateDoc(this.tenantId, dataPath, classroomPath, id, data, true);
  };

  getTenant = (id) => {
    const { systemPath, settingsPath, tenantsPath } = config.store;
    const { docs } = this.db
      .collection(systemPath)
      .doc(settingsPath)
      .collection(tenantsPath)
      .where('id', '==', id)
      .get();
    return docs[0];
  };

  updateTenantInfo = (id, data, shouldMerge = true) => {
    const { systemPath, settingsPath, tenantsPath } = config.store;
    return this.updateDoc(systemPath, settingsPath, tenantsPath, id, data, shouldMerge);
  };

  listTenants = (userTenants, isAdmin = false) => {
    const { systemPath, settingsPath, tenantsPath } = config.store;
    return this.listCollectionWithQuery(
      systemPath,
      settingsPath,
      tenantsPath,
      !isAdmin ? [['id', 'in', userTenants]] : [],
    );
  };

  listRatios = (state) => {
    const { systemPath, settingsPath, ratiosPath } = config.store;
    return this.listCollectionWithQuery(systemPath, settingsPath, ratiosPath, [['state', '==', state]]);
  };

  getCenterSchedule = () => {
    const { dataPath, settingsPath } = config.store;
    return this.db.collection(this.tenantId).doc(dataPath).collection(settingsPath).doc('schedule').get();
  };

  updateCenterSchedule = (data) => {
    const { dataPath, settingsPath } = config.store;
    return this.updateDoc(this.tenantId, dataPath, settingsPath, 'schedule', data, false);
  };

  updateDoc = (rootPath, dataPath, collectionPath, docId, data, shouldMerge = true) => {
    return this.db
      .collection(rootPath)
      .doc(dataPath)
      .collection(collectionPath)
      .doc(docId)
      .set(
        {
          ...data,
        },
        {
          merge: shouldMerge,
        },
      );
  };

  listCollection = (rootPath, docPath, collectionPath) =>
    this.db.collection(rootPath).doc(docPath).collection(collectionPath).get();

  listCollectionWithQuery = (rootPath, docPath, collectionPath, queryArgs = []) => {
    const query = this.db.collection(rootPath).doc(docPath).collection(collectionPath);
    return queryArgs
      .reduce((acc, nx) => {
        return nx.length ? acc.where(...nx) : acc;
      }, query)
      .get();
  };
}
