import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { deleteSchedule } from 'actions';
import { useModal } from 'utils/useModal';
import { stringToMoment, getFormattedDate } from 'utils/index';
import {
  ROUTE_STUDENT_VIEW,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_ADULT_VIEW,
  TYPE_STUDENT,
  TYPE_ADULT,
  TYPE_STAFF,
} from 'constants/index';
import { Button } from 'components/buttons';
import { ConfirmModal } from 'components/modal';

const getUrl = (id, scheduleId, recordType) => {
  if (recordType === TYPE_STUDENT) {
    return `${ROUTE_STUDENT_VIEW}${id}/schedule/update/${scheduleId}/`;
  }
  if (recordType === TYPE_STAFF) {
    return `${ROUTE_STAFFMEMBER_VIEW}${id}/schedule/update/${scheduleId}/`;
  }
  return `${ROUTE_ADULT_VIEW}${id}/schedule/update/${scheduleId}/`;
};

const clickExpandToggle = (currentValue, setClass) => setClass(currentValue === '' ? 'expanded' : '');

const TableRowSchedule = (props) => {
  const { scheduleId, EffectiveDate, children, originType, IsInEffect, IsLocked } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [classExpand, setClassExpand] = useState(IsInEffect ? 'expanded' : '');
  const { isShowing, toggle } = useModal();

  return (
    <>
      <ul className={classExpand ? `table-row ${classExpand} ${scheduleId}` : `table-row ${scheduleId}`}>
        <li className="tc--effective-date">
          <Button
            className="button--min"
            btnType="button"
            onClick={() => clickExpandToggle(classExpand, setClassExpand)}
          />
          <span>{getFormattedDate(stringToMoment(EffectiveDate))}</span>
        </li>
        <li className="tc--data">{!!children && children}</li>
        <li className="tc--edit">
          {!IsLocked && (
            <Link className="button--text" to={getUrl(id, scheduleId, originType)}>
              Edit
            </Link>
          )}
        </li>
        <li className="tc--delete">
          {!IsLocked && (
            <Button className="button--text--neg" onClick={toggle}>
              Delete
            </Button>
          )}
        </li>
      </ul>
      {!IsLocked && (
        <ConfirmModal
          isShowing={isShowing}
          title="Delete Schedule"
          content="This schedule will be deleted, are you sure?"
          isRequesting={false}
          doAction={() => {
            dispatch(deleteSchedule(scheduleId, id));
            toggle();
          }}
          hide={toggle}
          cancelLabel="Cancel"
          confirmLabel="Delete Schedule"
        />
      )}
    </>
  );
};
TableRowSchedule.propTypes = {
  EffectiveDate: PropTypes.string.isRequired,
  IsInEffect: PropTypes.bool.isRequired,
  IsLocked: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  scheduleId: PropTypes.any.isRequired,
  originType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
};

export default TableRowSchedule;
