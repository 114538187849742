import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { linkMembers } from 'actions';
import {
  TYPE_ADULT,
  TYPE_STAFF,
  TYPE_STUDENT,
  REL_TYPE_PARENT_GUARDIAN,
  REL_TYPE_SIBLING,
  OPTIONS_RELATIONSHIPS,
  OPTIONS_BILLING_AMOUNTS,
  REL_TYPE_OTHER,
  BILLING_TYPE_PERC,
} from 'constants/index';
import { Form, Error } from 'components/layout';
import {
  AdultSelect,
  StudentSelect,
  InputText,
  InputNumber,
  InputSelect,
  InputCheckbox,
} from 'components/formElements';
import { ActingSubmitButton } from 'components/buttons';
import { insertIf, onFieldChange, isEmpty, validationChangeForm } from 'utils/index';

const submitForm = (dispatch, data) => {
  console.log(data);
  const {
    referrerId,
    referrerType,
    linkType,
    linkTarget,
    rel,
    details,
    isPrimary,
    attendance,
    billing,
    billingAmount,
    billingAmountType,
  } = data;
  const relationship = referrerType === linkType && referrerType === TYPE_STUDENT ? REL_TYPE_SIBLING : rel;
  const refRel = {
    relationships: {
      [linkTarget]: {
        rel: relationship,
        relType: linkType,
        details,
        isPrimary,
        permissions: {
          billing,
          attendance,
        },
        billing: {
          type: billingAmountType,
          amount: parseFloat(billingAmount),
        },
      },
    },
  };
  const tgtRel = {
    relationships: {
      [referrerId]: {
        rel: relationship,
        relType: referrerType,
        details,
        isPrimary,
        permissions: {
          billing,
          attendance,
        },
        billing: {
          type: billingAmountType,
          amount: parseFloat(billingAmount),
        },
      },
    },
  };
  dispatch(linkMembers(referrerId, refRel, linkTarget, tgtRel));
};

const defaultState = {
  isFormDirty: false,
  linkTarget: '',
  rel: '',
  details: '',
  isPrimary: false,
  attendance: false,
  billing: false,
  billingAmount: '',
  billingAmountType: '',
};

const getState = (relationshipData) => {
  if (relationshipData) {
    const { details, rel, isPrimary, permissions, billing, linkTarget } = relationshipData;
    const { attendance, billing: billingPerm } = permissions;
    const { type, amount } = billing;
    return {
      isFormDirty: false,
      linkTarget,
      rel,
      details,
      isPrimary,
      attendance,
      billing: billingPerm,
      billingAmount: amount,
      billingAmountType: type,
    };
  }
  return defaultState;
};
const onChange = (values, setValues, e) => {
  onFieldChange(values, setValues, e);
};

const FormLinkParty = (props) => {
  const dispatch = useDispatch();
  const { completeUrl, referrerId, referrerType, linkType, relData, targetId, mode } = props;
  const hasData = !isEmpty(relData);
  const [values, setValues] = useState(getState(hasData ? { ...relData, linkTarget: targetId } : null));
  const [copyValues] = useState({ ...values });
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const { isRequesting, didRequestWithSuccess, requestError } = useSelector((state) => state.relationship.link);
  useEffect(() => {
    validationChangeForm(values, copyValues, setDisabledSubmit);
  }, [values]);

  const isStudentToAdult = referrerType === TYPE_STUDENT && (linkType === TYPE_ADULT || linkType === TYPE_STAFF);
  const isAdultToStudent = (referrerType === TYPE_ADULT || referrerType === TYPE_STAFF) && linkType === TYPE_STUDENT;
  const isBetweenAdultAndStudent = isAdultToStudent || isStudentToAdult;

  if (!isRequesting && didRequestWithSuccess) {
    return (
      <Redirect
        to={{
          pathname: completeUrl,
          state: { mode },
        }}
      />
    );
  }

  const submitProps = {
    btnClassName: 'button',
    actionBtnClassName: 'button button--icon--loading',
    btnText: 'Save',
    actionText: 'Saving...',
    isActing: isRequesting,
    isDisabled: disabledSubmit,
  };

  const { linkTarget, rel, details, isPrimary, attendance, billing, billingAmount, billingAmountType } = values;

  return (
    <Form
      onSubmit={() => {
        submitForm(dispatch, {
          referrerId,
          referrerType,
          linkType,
          ...values,
        });
      }}
    >
      {!!requestError && <Error error={requestError} className="form-error-msg" />}
      {/* TODO: filter out current adult if adult -> adult link */}
      {linkType === TYPE_ADULT ? (
        <AdultSelect
          id="linkTarget"
          labelText="Person"
          value={linkTarget}
          onChange={(e) => onChange(values, setValues, e)}
          isRequired
          isDisabled={hasData}
        />
      ) : (
        <StudentSelect
          id="linkTarget"
          labelText="Student"
          value={linkTarget}
          onChange={(e) => onChange(values, setValues, e)}
          isRequired
          isDisabled={hasData}
          filter={referrerType === TYPE_STUDENT}
          idsToFilter={[referrerId]}
        />
      )}
      {/* TODO: Add link when page is ready */}
      {/* <Link className="form-element-link" to="#">
        {`Not in the list? Add a ${linkType === TYPE_ADULT ? 'Person' : 'Child'}`}
      </Link> */}
      {isBetweenAdultAndStudent && (
        <>
          <InputSelect
            id="rel"
            labelText="Relationship"
            value={rel}
            onChange={(e) => onChange(values, setValues, e)}
            options={OPTIONS_RELATIONSHIPS}
            isRequired
          />
          {rel === REL_TYPE_OTHER && (
            <InputText
              id="details"
              labelText="Relationship Details"
              value={details}
              onChange={(e) => onChange(values, setValues, e)}
              isRequired
            />
          )}
          {rel === REL_TYPE_PARENT_GUARDIAN && (
            <InputCheckbox
              id="isPrimary"
              labelText="Is Primary Guardian"
              isChecked={isPrimary}
              onChange={(e) => onChange(values, setValues, e)}
            />
          )}
          <fieldset className="fieldset">
            <legend>Permissions</legend>
            <InputCheckbox
              id="attendance"
              labelText="Drop-off &amp; Pick-up"
              isChecked={attendance}
              onChange={(e) => onChange(values, setValues, e)}
            />
            <InputCheckbox
              id="billing"
              labelText="Billing"
              isChecked={billing}
              onChange={(e) => onChange(values, setValues, e)}
            />
            {billing && (
              <div className="wrapper--flex billing-amount">
                <InputSelect
                  id="billingAmountType"
                  labelText="Amount Type"
                  hintText="A specific dollar amount or a percentage"
                  value={billingAmountType}
                  onChange={(e) => onChange(values, setValues, e)}
                  options={OPTIONS_BILLING_AMOUNTS}
                  isRequired
                />
                <InputNumber
                  id="billingAmount"
                  labelText="Billing Amount"
                  hintText="The amount this person is responsible for"
                  value={billingAmount}
                  onChange={(e) => onChange(values, setValues, e)}
                  isRequired
                  {...{
                    ...insertIf(billingAmountType === BILLING_TYPE_PERC, 'hasMinMax', true),
                    ...insertIf(billingAmountType === BILLING_TYPE_PERC, 'min', 0),
                    ...insertIf(billingAmountType === BILLING_TYPE_PERC, 'max', 100),
                    ...insertIf(billingAmountType === BILLING_TYPE_PERC, 'maxLength', 3),
                  }}
                />
              </div>
            )}
          </fieldset>
        </>
      )}

      <div className="form-actions">
        <Link className="button--text--cancel" to={completeUrl}>
          Cancel
        </Link>
        <ActingSubmitButton {...submitProps} />
      </div>
    </Form>
  );
};

FormLinkParty.propTypes = {
  completeUrl: PropTypes.string.isRequired,
  referrerId: PropTypes.string.isRequired,
  referrerType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
  linkType: PropTypes.oneOf([TYPE_ADULT, TYPE_STUDENT]).isRequired,
  mode: PropTypes.string,
  targetId: PropTypes.string,
  relData: PropTypes.shape(),
  error: PropTypes.string,
};

FormLinkParty.defaultProps = {
  targetId: '',
  relData: {},
  error: null,
  mode: null,
};

export default FormLinkParty;
