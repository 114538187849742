import React from 'react';
import PropTypes from 'prop-types';
import { subscribeToStudents, unsubscribeFromStudents } from 'actions';
import { useSubscription } from 'utils/useSubscription';
import { EMPTY_STRING } from 'constants/index';
import InputSelect from './InputSelect';

const getFilteredRelated = (keys, idsToFilter) => keys.filter((it) => !idsToFilter.includes(it));

export const StudentSelect = (props) => {
  const { showInactiveParties, filter, idsToFilter } = props;
  const { items, keys } = useSubscription({
    subscribeFn: subscribeToStudents,
    unsubscribeFn: unsubscribeFromStudents,
    selectorKey: 'students',
  });
  const activeKeys = showInactiveParties ? keys : keys.filter((it) => items[it].isActive);
  const valuesToList = filter ? getFilteredRelated(activeKeys, idsToFilter) : activeKeys;
  const empty = [{ label: EMPTY_STRING, value: EMPTY_STRING }];
  const options = valuesToList.map((it) => {
    const r = items[it];
    return {
      label: r.fullName,
      value: r.id,
    };
  });
  return <InputSelect {...props} options={empty.concat(options)} />;
};

StudentSelect.propTypes = {
  filter: PropTypes.bool,
  showInactiveParties: PropTypes.bool,
  idsToFilter: PropTypes.arrayOf(PropTypes.string),
};

StudentSelect.defaultProps = {
  filter: false,
  showInactiveParties: false,
  idsToFilter: [],
};
