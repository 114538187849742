import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_STUDENT, TYPE_STAFF, TYPE_ADULT } from 'constants/index';
import { PlannedAbsences, Schedules } from 'components/memberProfiles';

const SchedulesTab = (props) => {
  const { recordType } = props;

  return (
    <>
      <Schedules recordType={recordType} />
      <PlannedAbsences recordType={recordType} />
    </>
  );
};

SchedulesTab.propTypes = {
  recordType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
};

export default SchedulesTab;
