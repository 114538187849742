import React from 'react';
import { Route } from 'react-router-dom';
import { AdultView } from 'components/memberProfiles';
import { ROUTE_ADULT_PROFILE } from 'constants/index';

export const Adult = () => {
  window.scrollTo(0, 0);
  return (
    <Route path={ROUTE_ADULT_PROFILE}>
      <AdultView />
    </Route>
  );
};
