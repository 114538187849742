import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Header, PageHeader, PageTitle, Wrapper } from 'components/layout';
import { NavUser, GlobalNav } from 'components/nav';
import { Tabs, TabsNav } from 'components/tabs';
import {
  AdminInfo,
  AdminNotify,
  AdminSchedule,
  AdminInfoUpdate,
  AdminScheduleUpdate,
  AdminClassrooms,
  ClassroomAdd,
  ClassroomUpdate,
} from 'components/containers/admin/index';
import {
  ROUTE_ADMIN,
  ROUTE_ADMIN_CLASSROOMS,
  ROUTE_ADMIN_SCHEDULE,
  ROUTE_ADMIN_NOTIFY,
  ROUTE_ADMIN_INFO_UPDATE,
  ROUTE_ADMIN_SCHEDULE_UPDATE,
  ROUTE_ADMIN_CLASSROOMS_ADD,
  ROUTE_ADMIN_CLASSROOMS_UPDATE,
} from 'constants/index';

const getNavItems = (pathname) => [
  {
    key: 'adminInfo',
    tabUrl: ROUTE_ADMIN,
    tabLabel: 'Center Information',
    isActive: pathname === ROUTE_ADMIN,
  },
  {
    key: 'adminSchedule',
    tabUrl: ROUTE_ADMIN_SCHEDULE,
    tabLabel: 'Operating Hours',
    isActive: pathname === ROUTE_ADMIN_SCHEDULE,
  },
  {
    key: 'adminClassrooms',
    tabUrl: ROUTE_ADMIN_CLASSROOMS,
    tabLabel: 'Classrooms',
    isActive: pathname === ROUTE_ADMIN_CLASSROOMS,
  },
  {
    key: 'adminNotify',
    tabUrl: ROUTE_ADMIN_NOTIFY,
    tabLabel: 'SMS Notifications',
    isActive: pathname === ROUTE_ADMIN_NOTIFY,
  },
];

const tabsNav = (pathname) => <TabsNav navItems={getNavItems(pathname)} />;

const Admin = () => {
  const location = useLocation();
  const headerChildren = (
    <>
      <NavUser />
    </>
  );
  return (
    <Switch>
      <Route exact path={ROUTE_ADMIN_CLASSROOMS_UPDATE}>
        <ClassroomUpdate />
      </Route>
      <Route exact path={ROUTE_ADMIN_CLASSROOMS_ADD}>
        <ClassroomAdd />
      </Route>
      <Route exact path={ROUTE_ADMIN_SCHEDULE_UPDATE}>
        <AdminScheduleUpdate />
      </Route>
      <Route exact path={ROUTE_ADMIN_INFO_UPDATE}>
        <AdminInfoUpdate />
      </Route>
      <Route path={ROUTE_ADMIN}>
        <>
          <Header>{headerChildren}</Header>
          <GlobalNav />
          <Wrapper>
            <PageHeader>
              <PageTitle title="Admin" />
            </PageHeader>
            <Tabs tabsNav={tabsNav(location.pathname)}>
              <Switch>
                <Route exact path={ROUTE_ADMIN}>
                  <AdminInfo />
                </Route>
                <Route exact path={ROUTE_ADMIN_SCHEDULE}>
                  <AdminSchedule />
                </Route>
                <Route exact path={ROUTE_ADMIN_CLASSROOMS}>
                  <AdminClassrooms />
                </Route>
                <Route exact path={ROUTE_ADMIN_NOTIFY}>
                  <AdminNotify />
                </Route>
              </Switch>
            </Tabs>
          </Wrapper>
        </>
      </Route>
    </Switch>
  );
};

export default Admin;
