import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getContacts } from 'actions';
import { useLocation, useParams } from 'react-router-dom';
import { Error, SubPageHeader, PageActions } from 'components/layout';
import {
  ROUTE_STUDENT_VIEW,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_ADULT_VIEW,
  TYPE_STUDENT,
  TYPE_STAFF,
  TYPE_ADULT,
} from 'constants/index';
import { Table, TableRowEmergencyContact } from 'components/table';
import { useSelector, useDispatch } from 'react-redux';

const getAddScheduleLinkUrl = (recordType, id) => {
  if (recordType === TYPE_STUDENT) {
    return `${ROUTE_STUDENT_VIEW}${id}/emergency-contact/select`;
  }
  if (recordType === TYPE_STAFF) {
    return `${ROUTE_STAFFMEMBER_VIEW}${id}/emergency-contact/select`;
  }
  return `${ROUTE_ADULT_VIEW}${id}/emergency-contact/select`;
};

const getEmergencyContactsRows = (emergencyContacts, adults) => {
  return Object.keys(emergencyContacts).map((it) => {
    if (emergencyContacts[it].type === 'link' && Object.keys(adults).length > 0) {
      const { firstName, lastName, mobile, workPhone, homePhone, type } = adults[emergencyContacts[it].linkId];
      const data = {
        memberId: emergencyContacts[it].memberId,
        type: emergencyContacts[it].type,
        name: `${firstName} ${lastName}`,
        rel: type,
        mobilePhone: mobile,
        homePhone,
        workPhone,
        personType: type,
        entryId: it,
        linkId: emergencyContacts[it].linkId,
      };
      return <TableRowEmergencyContact {...data} key={it} />;
    }
    const { type, firstName, lastName, rel, mobilePhone, homePhone, workPhone } = emergencyContacts[it];
    const data = { type, name: `${firstName} ${lastName}`, rel, mobilePhone, homePhone, workPhone, entryId: it };
    return <TableRowEmergencyContact {...data} key={it} />;
  });
};

const headerLabels = [
  { label: 'Name', className: 'name' },
  { label: 'Relationship', className: 'relationship' },
  { label: 'Contacts', className: 'contacts' },
  { label: '', className: 'edit' },
  { label: '', className: 'remove' },
];

const EmergencyContacts = (props) => {
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { items, fetchError, isFetching } = useSelector((state) => state.contact.list);
  const size = Object.keys(items).length;
  const { recordType } = props;
  const adults = useSelector((state) => state.adults.items);
  const actions = [
    {
      key: 'Add-Emergency-Contact',
      classNames: 'button--action button--icon--add',
      text: 'Add Emergency Contact',
      isVisible: true,
      isLink: true,
      url: getAddScheduleLinkUrl(recordType, id),
      referrer: location.pathname,
    },
  ];
  const tableProps = {
    id: 'emergency-contacts',
    rows: getEmergencyContactsRows(items, adults),
    resultsRow: null,
    tableOptions: [],
    tableActions: [],
    headerLabels,
  };
  useEffect(() => {
    if (!size && !isFetching) {
      dispatch(getContacts(id));
    }
  }, [size, isFetching]);
  return (
    <>
      <SubPageHeader title="Emergency Contacts" actions={<PageActions actions={actions} />} />
      {isFetching && <div className="loading" />}
      {!!fetchError && <Error error={fetchError} />}
      {!size && !isFetching && !fetchError && <Error error="No contacts available for this party" />}
      {!fetchError && !!size && <Table {...tableProps} />}
    </>
  );
};

EmergencyContacts.propTypes = {
  recordType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
    relationships: PropTypes.shape(),
    emergencyContacts: PropTypes.shape(),
  }).isRequired,
};

export default EmergencyContacts;
