import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const API_ID = 'STUDENT_DELETE_';

const studentDeleteRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const studentDeleteSuccess = (id) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id,
  },
});

const studentDeleteReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const studentDeleteFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const deleteStudent = (id) => (dispatch, getState) => {
  const {
    students: { items },
  } = getState();
  dispatch(studentDeleteRequest());
  api
    .deleteMember(id, items[id])
    .then(() => {
      dispatch(studentDeleteSuccess(id));
    })
    .then(() => dispatch(studentDeleteReset()))
    .catch((err) => {
      dispatch(studentDeleteFailure(err));
    });
};
