import { _MENU_OPEN, _MENU_CLOSE } from 'constants/index';

const openMenu = (menuId) => ({
  type: `${menuId.toUpperCase()}${_MENU_OPEN}`,
});

const closeMenu = (menuId) => ({
  type: `${menuId.toUpperCase()}${_MENU_CLOSE}`,
});

export const toggleMenu = (menuId, shouldOpen = false) => (dispatch, getState) => {
  const {
    ui: { menus },
  } = getState();
  const { isOpen } = menus[menuId];
  if (isOpen && !shouldOpen) {
    dispatch(closeMenu(menuId));
  } else if (!isOpen && shouldOpen) {
    dispatch(openMenu(menuId));
  }
};
