import { api } from 'index';
import { LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE } from 'constants/index';

const apiPrefix = 'CONTACT_';

export const contactListRequest = () => ({
  type: `${apiPrefix}${LIST_REQUEST}`,
});

const contactListSuccess = ({ items }) => ({
  type: `${apiPrefix}${LIST_SUCCESS}`,
  payload: {
    items,
  },
});

const contactListFailure = (message) => ({
  type: `${apiPrefix}${LIST_FAILURE}`,
  payload: {
    message,
  },
});

export const getContacts = (memberId) => (dispatch) => {
  dispatch(contactListRequest);
  api
    .listContacts(memberId)
    .then(({ docs }) => {
      const items = docs
        .map((it) => ({ refId: it.id, ...it.data() }))
        .reduce((acc, next) => {
          return {
            ...acc,
            [next.refId]: next,
          };
        }, {});
      dispatch(
        contactListSuccess({
          items,
        }),
      );
    })
    .catch((err) => {
      dispatch(contactListFailure(err));
    });
};
