import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PageFocusview, Form } from 'components/layout';
import { AdultSelect } from 'components/formElements';
import { ActingSubmitButton } from 'components/buttons';
import { onFieldChange } from 'utils/index';
import {
  addContact,
  subscribeToStudents,
  unsubscribeFromStudents,
  subscribeToAdults,
  unsubscribeFromAdults,
} from 'actions';
import { useSubscription } from 'utils/useSubscription';
import {
  ROUTE_STUDENT_VIEW,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_ADULT_VIEW,
  TYPE_STUDENT,
  TYPE_ADULT,
  TYPE_STAFF,
} from 'constants/index';

const closeUrl = (recordType, id) => {
  if (recordType === TYPE_STUDENT) {
    return `${ROUTE_STUDENT_VIEW}${id}/emergency-contacts/`;
  }
  if (recordType === TYPE_STAFF) {
    return `${ROUTE_STAFFMEMBER_VIEW}${id}/emergency-contacts/`;
  }
  return `${ROUTE_ADULT_VIEW}${id}/emergency-contacts/`;
};
const urlCreateEmergencyContact = (recordType, id) => {
  if (recordType === TYPE_STUDENT) {
    return `${ROUTE_STUDENT_VIEW}${id}/emergency-contact/add`;
  }
  if (recordType === TYPE_STAFF) {
    return `${ROUTE_STAFFMEMBER_VIEW}${id}/emergency-contact/add`;
  }
  return `${ROUTE_ADULT_VIEW}${id}/emergency-contact/add`;
};

const EmergencyContactSelect = (props) => {
  const { id } = useParams();
  const { originType } = props;
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    linkId: '',
  });
  const { didRequestWithSuccess, isRequesting, requestError } = useSelector((state) => state.contact.add);
  const { items, size } = useSubscription({
    subscribeFn: originType === TYPE_STUDENT ? subscribeToStudents : subscribeToAdults,
    unsubscribeFn: originType === TYPE_STUDENT ? unsubscribeFromStudents : unsubscribeFromAdults,
    selectorKey: originType === TYPE_STUDENT ? 'students' : 'adults',
  });

  if (!requestError && didRequestWithSuccess) {
    return <Redirect to={closeUrl(originType, id)} />;
  }

  const record = size ? items[id] : {};

  const { relationships } = record;

  const submitProps = {
    btnClassName: 'button',
    actionBtnClassName: 'button button--icon--loading',
    btnText: 'Save Contact',
    actionText: 'Saving...',
    isActing: isRequesting,
    isDisabled: !values.linkId || isRequesting,
  };

  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle="Add an emergency contact"
      closeButtonUrl={closeUrl(originType, id)}
      showCloseButton
    >
      <Form onSubmit={() => dispatch(addContact(id, { linkId: values.linkId }, 'link'))}>
        <AdultSelect
          id="linkId"
          labelText="Existing related party"
          hintText="Select an existing related party to automatically fill in their information"
          onChange={(e) => onFieldChange(values, setValues, e)}
          value={values.linkId}
          filterRelated
          relatedIds={Object.keys(relationships)}
        />
        <div className="form-actions">
          <ActingSubmitButton {...submitProps} />
        </div>
        <div className="list--actions">
          <div className="action-divider">
            <p>or</p>
          </div>
          <div className="action--min">
            <Link to={urlCreateEmergencyContact(originType, id)}>Create an emergency contact</Link>
            <p>Add an emergency contact that will not be a system user</p>
          </div>
        </div>
      </Form>
    </PageFocusview>
  );
};

EmergencyContactSelect.propTypes = {
  originType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
};

export default EmergencyContactSelect;
