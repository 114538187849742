import React from 'react';
import PropTypes from 'prop-types';
import { subscribeToAdults, unsubscribeFromAdults } from 'actions';
import { useSubscription } from 'utils/useSubscription';
import { EMPTY_STRING } from 'constants/index';
import InputSelect from './InputSelect';

const getFilteredStaffMembers = (keys, items) =>
  keys.filter((it) => {
    const r = items[it];
    return r.isStaffMember;
  });

const getFilteredRelated = (keys, relatedIds) => keys.filter((it) => relatedIds.includes(it));

export const AdultSelect = (props) => {
  const { showInactiveParties, filterStaffMembers, filterRelated, relatedIds, ...rest } = props;
  const { items, keys } = useSubscription({
    subscribeFn: subscribeToAdults,
    unsubscribeFn: unsubscribeFromAdults,
    selectorKey: 'adults',
  });
  const activeKeys = showInactiveParties ? keys : keys.filter((it) => items[it].isActive);
  const empty = [{ label: EMPTY_STRING, value: EMPTY_STRING }];
  // eslint-disable-next-line no-nested-ternary
  const valuesToList = filterStaffMembers
    ? getFilteredStaffMembers(activeKeys, items)
    : filterRelated
    ? getFilteredRelated(activeKeys, relatedIds)
    : activeKeys;
  const options = valuesToList.map((it) => {
    const r = items[it];
    return {
      label: r.fullName,
      value: r.id,
    };
  });
  return <InputSelect {...rest} options={empty.concat(options)} />;
};

AdultSelect.propTypes = {
  filterStaffMembers: PropTypes.bool,
  filterRelated: PropTypes.bool,
  showInactiveParties: PropTypes.bool,
  relatedIds: PropTypes.arrayOf(PropTypes.string),
};

AdultSelect.defaultProps = {
  filterStaffMembers: false,
  filterRelated: false,
  showInactiveParties: false,
  relatedIds: [],
};
