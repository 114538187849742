import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class Tabs extends PureComponent {
  static propTypes = {
    tabsNav: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { tabsNav, children } = this.props;

    return (
      <section className="tabs">
        {tabsNav}
        {children}
      </section>
    );
  }
}
