import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getClassrooms, listStaffMemberSchedules } from 'actions';
import { ROUTE_ADMIN_CLASSROOMS, ROUTE_ADMIN_CLASSROOMS_ADD } from 'constants/index';
import { TabContent } from 'components/tabs';
import { SubPageHeader, PageActions, Error } from 'components/layout';
import { Table, TableRowAdminClassrooms } from 'components/table';

const headerLabels = [
  { label: 'Name', className: 'name' },
  { label: 'Age Range', className: 'age-range' },
  { label: 'Ratio', className: 'ratio' },
  { label: 'Maximum Group Size', className: 'max-size' },
  { label: '', className: 'edit' },
  { label: '', className: 'delete' },
];
const getAdminClassroomsRows = (classrooms) => {
  return Object.keys(classrooms).map((it) => {
    const { name, nickName, minMonths, maxMonths, ratio, maxSize } = classrooms[it];
    const data = { name, nickName, minMonths, maxMonths, ratio, maxSize, classroomId: it };
    return <TableRowAdminClassrooms {...data} key={it} />;
  });
};

const tableProps = {
  id: 'classrooms',
  resultsRow: null,
  tableOptions: [],
  tableActions: [],
  headerLabels,
};

const AdminClassrooms = () => {
  const location = useLocation();
  const actions = [
    {
      key: 'Add-Classroom',
      classNames: 'button--action button--icon--add',
      text: 'Add Classroom',
      isVisible: true,
      isLink: true,
      url: ROUTE_ADMIN_CLASSROOMS_ADD,
      referrer: location.pathname,
    },
  ];

  const { items, fetchError, isFetching } = useSelector((state) => state.classroom.list);
  const dispatch = useDispatch();
  const adults = useSelector((state) => state.adults.items);
  const students = useSelector((state) => state.students.items);
  const studentKeys = Object.keys(students);
  const size = Object.keys(items).length;
  const staffMembers = Object.keys(adults).filter((el) => adults[el].isStaffMember);
  const sizeStaffMembers = staffMembers.length;
  const sizeStudents = studentKeys.length;
  const fetchingSchedules = useSelector((state) => state.staffSchedules.isFetching);

  useEffect(() => {
    if (!size && !isFetching) {
      dispatch(getClassrooms());
    }
    if (!!sizeStaffMembers && !!sizeStudents && !!size) {
      dispatch(listStaffMemberSchedules(staffMembers.concat(studentKeys)));
    }
  }, [size, isFetching, sizeStaffMembers]);

  return (
    <TabContent id="adminClassrooms" isShowing={location.pathname === ROUTE_ADMIN_CLASSROOMS}>
      <SubPageHeader title="Classrooms" actions={<PageActions actions={actions} />} />
      {!!fetchError && <Error error={fetchError} />}
      {!size && !isFetching && !fetchError && <Error error="No classrooms configured yet" />}
      {!isFetching && !!size && !fetchingSchedules && <Table {...tableProps} rows={getAdminClassroomsRows(items)} />}
      {(isFetching || !!fetchingSchedules) && <div className="loading" />}
    </TabContent>
  );
};

export default AdminClassrooms;
