import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'components/buttons';
import { toggleMenu } from 'actions/index';

class NavMobile extends PureComponent {
  static propTypes = {
    doToggleMenu: PropTypes.func.isRequired,
    globalMenuState: PropTypes.bool.isRequired,
    userMenuState: PropTypes.bool.isRequired,
    menuText: PropTypes.string,
    accountText: PropTypes.string,
  };

  static defaultProps = {
    menuText: 'Menu',
    accountText: 'Your Account',
  };

  toggleMenu = (id, shouldOpen) => {
    const { doToggleMenu } = this.props;
    doToggleMenu(id, shouldOpen);
  };

  render() {
    const { menuText, accountText, globalMenuState, userMenuState } = this.props;
    return (
      <nav className="nav--mobile">
        <ul>
          <li>
            <Button
              className="nav-trigger nav-trigger--global"
              onClick={() => this.toggleMenu('GLOBAL', !globalMenuState)}
            >
              {menuText}
            </Button>
          </li>
          <li>
            <Button className="nav-trigger nav-trigger--user" onClick={() => this.toggleMenu('USER', !userMenuState)}>
              {accountText}
            </Button>
          </li>
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = ({ ui }) => {
  const { menus } = ui;
  const { GLOBAL, USER } = menus;
  return {
    globalMenuState: GLOBAL.isOpen,
    userMenuState: USER.isOpen,
  };
};

const mapDispatchToProps = {
  doToggleMenu: toggleMenu,
};

export { NavMobile };
export default connect(mapStateToProps, mapDispatchToProps)(NavMobile);
