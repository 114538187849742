import moment from 'moment';
import { api } from 'index';
import config from 'conf/index';
import { LIST_SUBSCRIBE, LIST_SUCCESS, LIST_FAILURE, LIST_UNSUBSCRIBE, LIST_REQUEST } from 'constants/index';

let listUnsubscriber = null;

const apiPrefix = 'SCHEDULE_';
const apiPrefixStaffSchedules = 'SCHEDULE_STAFF_';

export const scheduleListSubscribe = () => ({
  type: `${apiPrefix}${LIST_SUBSCRIBE}`,
});

const staffScheduleListRequest = () => ({
  type: `${apiPrefixStaffSchedules}${LIST_REQUEST}`,
});

export const scheduleListUnsubscribe = () => ({
  type: `${apiPrefix}${LIST_UNSUBSCRIBE}`,
});

const scheduleListSuccess = ({ items }) => ({
  type: `${apiPrefix}${LIST_SUCCESS}`,
  payload: {
    items,
  },
});

const staffScheduleListSuccess = ({ items }) => ({
  type: `${apiPrefixStaffSchedules}${LIST_SUCCESS}`,
  payload: {
    items,
  },
});

const scheduleListFailure = (message) => ({
  type: `${apiPrefix}${LIST_FAILURE}`,
  payload: {
    message,
  },
});

const staffScheduleListFailure = (message) => ({
  type: `${apiPrefixStaffSchedules}${LIST_FAILURE}`,
  payload: {
    message,
  },
});

export const subscribeToSchedules = (memberId) => (dispatch) => {
  dispatch(scheduleListSubscribe());
  listUnsubscriber = api.subscribeToSchedules(memberId, ({ docs, size }) => {
    if (size) {
      const today = moment().format(config.formats.momentDateSystem);
      const allDates = docs.map((it) => {
        const { id, ...rest } = it.data();
        return {
          id: it.id,
          ...rest,
        };
      });
      const pastDateClosestToToday = allDates
        .filter((it) => {
          return it.EffectiveDate <= today;
        })
        .reduce((acc, next) => {
          if (!acc) return next;
          return acc.EffectiveDate > next.EffectiveDate ? acc : next;
        }, null);
      const futureAndClosestDate = [
        {
          ...pastDateClosestToToday,
          IsInEffect: true,
          IsLocked: true,
        },
      ].concat(
        allDates
          .filter((it) => {
            return it.EffectiveDate > today;
          })
          .map((it) => ({ ...it, IsInEffect: false, IsLocked: false })),
      );
      const items = futureAndClosestDate.reduce(
        (acc, next) => ({
          ...acc,
          [next.id]: next,
        }),
        {},
      );
      dispatch(scheduleListSuccess({ items }));
    } else {
      dispatch(scheduleListFailure('No entries available for this party'));
    }
  });
};

export const listStaffMemberSchedules = (memberIds = []) => (dispatch) => {
  dispatch(staffScheduleListRequest());
  api
    .listSchedules()
    .then(({ docs }) => {
      const items = docs
        .map((it) => ({ id: it.id, ...it.data() }))
        .filter((it) => memberIds.includes(it.memberId))
        .reduce((acc, next) => {
          return {
            ...acc,
            [next.id]: next,
          };
        }, {});
      dispatch(staffScheduleListSuccess({ items }));
    })
    .catch((err) => dispatch(staffScheduleListFailure(err)));
};

export const unsubscribeFromSchedules = () => (dispatch) => {
  dispatch(scheduleListUnsubscribe());
  api.unsubscribeListener(listUnsubscriber);
  listUnsubscriber = null;
};
