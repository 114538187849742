import { combineReducers } from 'redux';
import { createReducer } from 'utils/index';
import { APP_LOAD_START, APP_LOAD_END } from 'constants/index';
import menus from './menus';
import modals from './modals';

const initialState = {
  isAppLoading: false,
};

export default combineReducers({
  appLauncher: createReducer(initialState, {
    [APP_LOAD_START]: () => ({
      isAppLoading: true,
    }),
    [APP_LOAD_END]: () => ({
      isAppLoading: false,
    }),
  }),
  menus,
  modals,
});
