import { combineReducers } from 'redux';
import { createModalReducer } from 'utils/index';

const initialState = {
  isOpen: false,
};

export default combineReducers({
  STUDENT_ADD: createModalReducer('STUDENT_ADD', initialState),
  STUDENT_UPDATE: createModalReducer('STUDENT_UPDATE', initialState),
  STUDENT_DELETE: createModalReducer('STUDENT_DELETE', initialState),
  STUDENT_SCHEDULE_UPDATE: createModalReducer('STUDENT_SCHEDULE_UPDATE', initialState),
  STAFF_ADD: createModalReducer('STAFF_ADD', initialState),
  STAFF_UPDATE: createModalReducer('STAFF_UPDATE', initialState),
  STAFF_DELETE: createModalReducer('STAFF_DELETE', initialState),
  STAFF_SCHEDULE_UPDATE: createModalReducer('STAFF_SCHEDULE_UPDATE', initialState),
  TIMESHEET_ADD: createModalReducer('TIMESHEET_ADD', initialState),
  TIMESHEET_PRESET_ADD: createModalReducer('TIMESHEET_PRESET_ADD', initialState),
  TIMESHEET_UPDATE: createModalReducer('TIMESHEET_UPDATE', initialState),
  TIMESHEET_IGNORE: createModalReducer('TIMESHEET_IGNORE', initialState),
});
