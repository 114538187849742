import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addClassroom, getRatios } from 'actions';
import { ClassroomForm } from 'components/form';
import { PageFocusview } from 'components/layout';
import { ROUTE_ADMIN_CLASSROOMS } from 'constants/index';

const ClassroomAdd = () => {
  const dispatch = useDispatch();
  const { isRequesting, didRequestWithSuccess } = useSelector((state) => state.classroom.add);
  // "Ratios" are the state-approved list of classrooms which a user selects and we save
  // the data along with the nickname as a new classroom for the center
  const { items: ratios, fetchError, isFetching } = useSelector((state) => state.ratios);
  const { selectedTenant } = useSelector((state) => state.auth);
  const tenants = useSelector((state) => state.tenants.items);
  const currentTenant = tenants.find((it) => it.id === selectedTenant) || {};
  const { state } = currentTenant;
  const ratioSize = Object.keys(ratios).length;

  useEffect(() => {
    if (!!state && !ratioSize && !isFetching) {
      dispatch(getRatios(state));
    }
  }, [state, ratioSize, isFetching]);

  // check if we need to show a "loading" indicator or if we can show the form
  const showForm = !isFetching && !fetchError && !!ratioSize;

  if (didRequestWithSuccess) {
    return <Redirect to={ROUTE_ADMIN_CLASSROOMS} />;
  }

  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle="Add Classroom"
      closeButtonUrl={ROUTE_ADMIN_CLASSROOMS}
      showCloseButton
    >
      {isFetching && <div className="loading" />}
      {showForm && (
        <ClassroomForm
          stateClassrooms={ratios}
          submitFn={(values) => {
            dispatch(addClassroom(values));
          }}
          isSubmitting={isRequesting}
          cancelUrl={ROUTE_ADMIN_CLASSROOMS}
        />
      )}
    </PageFocusview>
  );
};

export default ClassroomAdd;
