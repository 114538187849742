import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Error extends PureComponent {
  static propTypes = {
    error: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: 'error',
  };

  render() {
    const { error, className } = this.props;
    return (
      <div className={className}>
        <p>{error}</p>
      </div>
    );
  }
}

export default Error;
