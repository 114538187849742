import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClassrooms } from 'actions';
import { EMPTY_STRING } from 'constants/index';
import InputSelect from './InputSelect';

export const ClassroomSelect = (props) => {
  const { items, isFetching } = useSelector((state) => state.classroom.list);
  const dispatch = useDispatch();
  const keys = Object.keys(items);
  const size = keys.length;

  useEffect(() => {
    if (!size && !isFetching) {
      dispatch(getClassrooms());
    }
  }, [size, isFetching]);

  const empty = [{ label: EMPTY_STRING, value: EMPTY_STRING }];

  const options = keys.map((it) => {
    const r = items[it];
    return {
      label: r.nickName,
      value: r.refId,
    };
  });
  return <InputSelect {...props} options={empty.concat(options)} />;
};
