import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { stringToMoment, getFormattedDate, boolToYesNo } from 'utils/index';
import { ROUTE_STUDENT_VIEW } from 'constants/index';
import { TableRow } from '.';

class TableRowStudent extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      dateOfBirth: PropTypes.string,
      pin: PropTypes.string,
      schedule: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      hasSubsidy: PropTypes.bool,
    }),
  };

  static defaultProps = {
    data: {},
  };

  render() {
    const { data } = this.props;
    const { id, fullName, dateOfBirth, hasSubsidy } = data;
    const hasData = Object.keys(data).length;
    return hasData ? (
      <TableRow key={id}>
        <li className="tc--name">{fullName}</li>
        <li className="tc--dob">{getFormattedDate(stringToMoment(dateOfBirth))}</li>
        <li className="tc--subsidized">{boolToYesNo(hasSubsidy)}</li>
        <li className="tc--view">
          <Link className="button--text" to={`${ROUTE_STUDENT_VIEW}${id}/`}>
            View
          </Link>
        </li>
      </TableRow>
    ) : null;
  }
}

export default TableRowStudent;
