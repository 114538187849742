import { api } from 'index';
import { sortByString } from 'utils/index';
import {
  ADULT_LIST_SUBSCRIBE,
  ADULT_LIST_SUCCESS,
  ADULT_LIST_FAILURE,
  ADULT_LIST_UNSUBSCRIBE,
  TYPE_ADULT,
} from 'constants/index';

let listUnsubscriber = null;

export const adultListSubscribe = () => ({
  type: ADULT_LIST_SUBSCRIBE,
});

export const adultListUnsubscribe = () => ({
  type: ADULT_LIST_UNSUBSCRIBE,
});

const adultListSuccess = ({ items }) => ({
  type: ADULT_LIST_SUCCESS,
  payload: {
    items,
  },
});

const adultListFailure = (message) => ({
  type: ADULT_LIST_FAILURE,
  payload: {
    message,
  },
});

export const subscribeToAdults = () => (dispatch) => {
  dispatch(adultListSubscribe());
  listUnsubscriber = api.subscribeToMemberList(TYPE_ADULT, ({ docs, size }) => {
    if (size) {
      const items = docs
        .map((it) => {
          const { id, ...rest } = it.data();
          return {
            id: it.id,
            fullName: `${rest.lastName}, ${rest.firstName}`,
            ...rest,
          };
        })
        .sort((a, b) => sortByString(a.fullName, b.fullName))
        .reduce(
          (acc, next) => ({
            ...acc,
            [next.id]: next,
          }),
          {},
        );
      dispatch(adultListSuccess({ items }));
    } else {
      dispatch(adultListFailure('No records available'));
    }
  });
};

export const unsubscribeFromAdults = () => (dispatch) => {
  dispatch(adultListUnsubscribe());
  api.unsubscribeListener(listUnsubscriber);
  listUnsubscriber = null;
};
