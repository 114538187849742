import React from 'react';
import { useParams } from 'react-router-dom';
import { subscribeToStudents, unsubscribeFromStudents, subscribeToAdults, unsubscribeFromAdults } from 'actions';
import { FormLinkParty } from 'components/form';
import { PageFocusview } from 'components/layout';
import { TYPE_STUDENT } from 'constants/index';
import { useSubscription } from 'utils/useSubscription';
import { isEmpty, getRecordTypeFromPath } from 'utils';

const getCloseUrl = (pathType, id) => `/${pathType}/${id}/related-parties/`;

const RelationshipUpdate = () => {
  const { idA, idB, typeA, typeB } = useParams();

  const recordTypeA = getRecordTypeFromPath(typeA);
  const recordTypeB = getRecordTypeFromPath(typeB);

  const { items, size } = useSubscription({
    subscribeFn: recordTypeA === TYPE_STUDENT ? subscribeToStudents : subscribeToAdults,
    unsubscribeFn: recordTypeA === TYPE_STUDENT ? unsubscribeFromStudents : unsubscribeFromAdults,
    selectorKey: recordTypeA === TYPE_STUDENT ? 'students' : 'adults',
  });

  const record = size ? items[idA] : {};
  const relationships = !isEmpty(record) ? record.relationships : {};
  const relationship = !isEmpty(relationships) ? relationships[idB] : {};

  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle="Update Relationship"
      closeButtonUrl={getCloseUrl(typeA, idA)}
      showCloseButton
    >
      <FormLinkParty
        referrerId={idA}
        targetId={idB}
        referrerType={recordTypeA}
        linkType={recordTypeB}
        completeUrl={getCloseUrl(recordTypeA, idA)}
        relData={relationship}
      />
    </PageFocusview>
  );
};

export default RelationshipUpdate;
