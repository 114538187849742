import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Button } from 'components/buttons';
import { Modal } from 'components/modal';
import { Error } from 'components/layout';

export const ConfirmModal = (props) => {
  const {
    doAction,
    onClose,
    isShowing,
    hide,
    isRequesting,
    error,
    title,
    content,
    disableConfirmation,
    confirmLabel,
    cancelLabel,
  } = props;

  if (!isShowing) {
    document.body.classList.remove('has-modal');
  }

  return isShowing
    ? ReactDOM.createPortal(
        <Modal isShowing={isShowing} modalTitle={title} onClose={onClose}>
          {!!error && <Error error={error} />}
          <div>{content}</div>
          <div className="modal-actions">
            <Button
              className="button--text--cancel"
              onClick={() => {
                hide();
              }}
              isDisabled={isRequesting}
            >
              {cancelLabel}
            </Button>

            <Button
              className="button--action--neg"
              onClick={() => doAction()}
              isDisabled={disableConfirmation || isRequesting}
            >
              {confirmLabel}
            </Button>
          </div>
        </Modal>,
        document.body,
      )
    : null;
};

ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  doAction: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  disableConfirmation: PropTypes.bool,
  error: PropTypes.string,
  onClose: PropTypes.func,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
};

ConfirmModal.defaultProps = {
  error: null,
  onClose: null,
  disableConfirmation: false,
  confirmLabel: 'Yes',
  cancelLabel: 'No',
};
