import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ROUTE_CHOOSE_TENANT } from 'constants/index';

export const withSelectTenant = (WrappedComponent) => (props) => {
  const shouldChooseTenant = useSelector((state) => {
    // console.log(state.auth);
    return !state.auth.selectedTenant;
  });
  return shouldChooseTenant ? <Redirect to={ROUTE_CHOOSE_TENANT} /> : <WrappedComponent {...props} />;
};
