import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageFocusview } from 'components/layout';
import { ROUTE_HOME } from 'constants/index';

const NoMatch = () => {
  const history = useHistory();

  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle="Not Found"
      closeButtonUrl={ROUTE_HOME}
      showCloseButton
    >
      <h4>We haven&apos;t found what you&apos;re looking for.</h4>
      <br />
      <p>
        Click the close button to return to the home page, or
        <button onClick={() => history.goBack()}>go back to the previous page</button>.
      </p>
    </PageFocusview>
  );
};

export default NoMatch;
