import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const API_ID = 'TIMESHEET_UPDATE_';

const timesheetUpdateRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const timesheetUpdateSuccess = (id) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id,
  },
});

const timesheetUpdateReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const timesheetUpdateFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const updateTimesheet = (id, data) => (dispatch) => {
  dispatch(timesheetUpdateRequest());
  api
    .updateTimesheet(id, data)
    .then(() => {
      dispatch(timesheetUpdateSuccess(id));
    })
    .then(() => dispatch(timesheetUpdateReset()))
    .catch((err) => {
      dispatch(timesheetUpdateFailure(err));
    });
};
