import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { subscribeToStudents, unsubscribeFromStudents, subscribeToAdults, unsubscribeFromAdults } from 'actions';
import { useSubscription } from 'utils/useSubscription';
import { SubPageHeader, PageActions } from 'components/layout';
import { Table, TableRowRelated } from 'components/table';
import { ROUTE_RELATIONSHIP_SELECT_TYPE, TYPE_STUDENT, TYPE_ADULT, TYPE_STAFF } from 'constants/index';
import { getRecordPathFromType } from 'utils';

const headerLabels = [
  {
    label: 'Name',
    className: 'name',
  },
  {
    label: 'Relationship',
    className: 'relationship',
  },
  {
    label: 'Primary Guardian',
    className: 'primary-guardian',
  },
  {
    label: 'Permissions',
    className: 'permissions',
  },
  {
    label: 'Billing',
    className: 'billing',
  },
  {
    label: 'Pin',
    className: 'pin',
  },
  {
    label: ' ',
    className: 'edit',
  },
  {
    label: ' ',
    className: 'remove',
  },
];

const getRelatedPartyRows = (adults, students, record) => {
  const { id: ownerId, relationships, type } = record;
  if (!relationships) return [];

  return Object.keys(relationships).map((id) => {
    const relData = relationships[id];
    const { relType, ...rest } = relData;
    const targetData = relType === TYPE_STUDENT ? students[id] : adults[id];
    if (!targetData) return null;
    const { fullName, pin } = targetData;
    const props = {
      ...rest,
      relId: id,
      relType,
      ownerId,
      pin,
      fullName,
      referrerType: type,
    };
    return <TableRowRelated key={id} data={props} />;
  });
};

const getAddPartyLinkUrl = (recordType, id) =>
  ROUTE_RELATIONSHIP_SELECT_TYPE.replace(':id', id).replace(':type', getRecordPathFromType(recordType));

const RelatedParties = (props) => {
  const { record, recordType } = props;
  const { id } = record;

  const location = useLocation();

  const { items: adults } = useSubscription({
    subscribeFn: subscribeToAdults,
    unsubscribeFn: unsubscribeFromAdults,
    selectorKey: 'adults',
  });

  const { items: students } = useSubscription({
    subscribeFn: subscribeToStudents,
    unsubscribeFn: unsubscribeFromStudents,
    selectorKey: 'students',
  });

  const actions = [
    {
      key: 'link-party',
      classNames: 'button--action button--icon--add',
      text: 'Link Party',
      isVisible: true,
      isLink: true,
      url: getAddPartyLinkUrl(recordType, id),
      referrer: location.pathname,
    },
  ];

  const rows = getRelatedPartyRows(adults, students, record);

  return (
    <>
      <SubPageHeader title="Related Parties" actions={<PageActions actions={actions} />} />
      {record && <Table id="related-users" headerLabels={headerLabels} rows={rows} />}
    </>
  );
};

RelatedParties.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    relationships: PropTypes.shape(),
  }).isRequired,
  recordType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
};

export default RelatedParties;
