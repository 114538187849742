import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from 'components/formElements';

const InformationList = (props) => {
  const { listData } = props;
  const listItems = listData.map((it) => {
    const className = it.className || '';
    return !it.isToggle ? (
      <li key={it.label} className={className}>
        <span className="label">{it.label}</span>
        {it.value}
      </li>
    ) : (
      <li key={it.label} className={className}>
        <Toggle
          className={it.toggleClassnames}
          id={it.label}
          labelText={it.label}
          isChecked={it.value}
          onChange={it.toggleAction}
        />
      </li>
    );
  });
  return <ul className="list--info">{listItems}</ul>;
};

InformationList.propTypes = {
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
  ).isRequired,
};

export default InformationList;
