import { createSelector } from 'reselect';
import { DateTime } from 'luxon';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import groupBy from 'lodash/fp/groupBy';
import { round2Precision, isEmpty } from 'utils/index';
import { CHECKED_OUT, CHECKED_IN, NO_ACTION_SELECTED, OTHER_START, OTHER_END } from 'constants/index';
import config from 'conf/index';

const moment = extendMoment(Moment);
const getItems = (state) => state.items;
const getParams = (state) => state.searchParams;
const getIsAllMainExpanded = (state) => state.isAllMainExpanded;
const getIsAllXeptionExpanded = (state) => state.isAllXeptionExpanded;
const getToggleExpandId = (state) => state.toggleExpandId;
const getToggleExpand = (state) => state.toggleExpand;

const getActionLabel = (action, hasDetails) => {
  if (hasDetails) {
    return action === CHECKED_IN ? OTHER_START : OTHER_END;
  }
  return action;
};

const mapEntries = (e, i, list) => {
  const { action, actionDetails, dateTime, reason } = e;
  const previous = list[i - 1];
  if (previous && previous.action === CHECKED_IN && action === CHECKED_OUT) {
    const { dateTime: currentDateTime } = e;
    const { dateTime: previousDateTime } = previous;
    const cO = moment(currentDateTime);
    const cI = moment(previousDateTime);
    const duration = moment.duration(cO.diff(cI));
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const totalHours = round2Precision(days * 24 + hours + (minutes === 0 ? 0 : minutes / 60), 1);
    return {
      ...e,
      reason: reason || null,
      actionLabel: getActionLabel(action, !isEmpty(actionDetails)),
      totalHours,
      m: moment(DateTime.fromISO(dateTime).setZone(config.timeZone).toISO({ includeOffset: false })),
    };
  }
  return {
    ...e,
    actionLabel: getActionLabel(action, !isEmpty(actionDetails)),
    totalHours: '0.0',
    m: moment(DateTime.fromISO(dateTime).setZone(config.timeZone).toISO({ includeOffset: false })),
  };
};

export const getTimesheets = createSelector([getItems, getParams], (items, { startDate, endDate }) => {
  if (!items.length) return [];
  const [sY, sM, sD] = startDate.split('-');
  const [eY, eM, eD] = endDate.split('-');
  const sDate = DateTime.fromObject({
    year: sY,
    month: sM,
    day: sD,
    hour: 0,
    minute: 0,
    second: 0,
    zone: config.timeZone,
  });
  const eDate = DateTime.fromObject({
    year: eY,
    month: eM,
    day: eD,
    hour: 23,
    minute: 59,
    second: 59,
    zone: config.timeZone,
  });
  const s = moment(sDate.toISO());
  const e = moment(eDate.toISO());
  // only show non-ignored entries
  const filtered = items.filter((item) => !item.isIgnored);
  const people = groupBy('fullName', filtered);
  const range = Array.from(moment.range(s, e).by('day'));
  // for each person in the results
  const result = Object.keys(people).map((it) => {
    let isFlagged = false;
    const person = people[it];
    const days = range.map((r) => {
      let isDayFlagged = false;
      // for each day in the range
      const today = r.format('MM/DD/Y');
      // get the person's entries for today
      const todayEntries = person.filter((p) => p.date === today);
      const { memberId, firstName, lastName, hasSubsidy, type } = person[0];

      const entryCount = todayEntries.length;

      // even entries should be sign in, odd entries should be sign out
      const oddCheckIns = todayEntries.filter((_, i) => i % 2 !== 0).filter((el) => el.action === CHECKED_IN);
      const evenCheckOuts = todayEntries.filter((_, i) => i % 2 === 0).filter((el) => el.action === CHECKED_OUT);

      // add a flag if uneven number of entries
      // or there exists odd-indexed entries whose status is CHECKED_IN
      // or there exists even-indexed entries whose status is CHECKED_OUT
      if (entryCount % 2 !== 0 || oddCheckIns.length || evenCheckOuts.length) {
        isFlagged = true;
        isDayFlagged = true;
      }
      let entries = todayEntries;

      // if there are no entries today,
      // or an uneven number entries today
      // add a blank entry for quick record insertion
      if (!todayEntries.length || entryCount % 2 !== 0) {
        entries = entries.concat({
          id: null,
          memberId,
          firstName,
          lastName,
          date: r.format('Y-MM-DD'),
          time: null,
          dateTime: r.toISOString(),
          action: null,
          actionDetails: null,
          isIgnored: false,
          isManualEntry: false,
          reason: null,
          isBestGuess: false,
          hasSubsidy,
          type,
          totalHours: '0.0',
          m: r.clone(),
        });
      }

      entries = entries.map(mapEntries);

      // if even number of entries, but entries have no action selected
      // flag it
      if (!isFlagged && !isDayFlagged) {
        const shouldFlag = !!entries.filter((entry) => entry.action === NO_ACTION_SELECTED).length;
        isDayFlagged = shouldFlag;
        isFlagged = shouldFlag;
      }

      return {
        date: r.format('ddd MM/DD/Y'),
        entries,
        totalHours: entries.reduce((acc, next) => acc + parseFloat(next.totalHours), 0.0),
        isFlagged: isDayFlagged,
      };
    });

    return {
      id: `TS-${it.toLowerCase().replace(', ', '-')}`,
      fullName: it,
      days,
      totalHours: days.reduce((acc, next) => acc + parseFloat(next.totalHours), 0.0),
      isFlagged,
      isExpanded: false,
    };
  });
  return result;
});

export const getExceptions = createSelector([getItems], (items) => {
  const filtered = items.filter((item) => item.isIgnored);
  const people = groupBy('fullName', filtered);
  const result = Object.keys(people).map((it) => {
    const days = people[it].map((d) => {
      const { dateTime } = d;
      const dateFmt = DateTime.fromISO(dateTime).setZone(config.timeZone).toFormat('ccc LL/dd/yyyy');
      return {
        ...d,
        dateFmt,
      };
    });
    return {
      id: `EX-${it.toLowerCase().replace(', ', '-')}`,
      fullName: it,
      days,
      count: days.length,
      isExpanded: false,
    };
  });
  return result;
});

const expandStatuses = {
  MAIN: {},
  EXCEPTION: {},
};

/* eslint-disable no-prototype-builtins, no-param-reassign */
const getExpandState = (thisId, idToExpand, shouldIdExpand, isAllExpanded, allStatuses) => {
  // check if this is a "one" vs an "all" situation
  if (idToExpand) {
    // check if this id is changing state and return that state
    if (thisId === idToExpand) {
      allStatuses[thisId] = shouldIdExpand;
      return shouldIdExpand;
    }
    // check if this id has a stored state, return it if so
    if (allStatuses.hasOwnProperty(thisId)) {
      return allStatuses[thisId];
    }
    // this item has no specific state change so return the global state
    return isAllExpanded;
  }
  // this is an "all" situation, return global state
  return isAllExpanded;
};

export const getExpandedTimesheets = createSelector(
  [getTimesheets, getIsAllMainExpanded, getToggleExpand, getToggleExpandId],
  (items, isAllExpanded, toggleExpand, toggleExpandId) => {
    return items.map((it) => {
      const { id, isExpanded, ...rest } = it;
      return {
        ...rest,
        id,
        isExpanded: getExpandState(id, toggleExpandId, toggleExpand, isAllExpanded, expandStatuses.MAIN),
      };
    });
  },
);

export const getExpandedExceptions = createSelector(
  [getExceptions, getIsAllXeptionExpanded, getToggleExpand, getToggleExpandId],
  (items, isAllExpanded, toggleExpand, toggleExpandId) => {
    return items.map((it) => {
      const { id, isExpanded, ...rest } = it;
      return {
        ...rest,
        id,
        isExpanded: getExpandState(id, toggleExpandId, toggleExpand, isAllExpanded, expandStatuses.EXCEPTION),
      };
    });
  },
);
