import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ActingSubmitButton } from 'components/buttons';
import { InputText } from 'components/formElements';
import { Form, Error } from 'components/layout';

const defaultState = {
  values: {
    isIgnored: true,
    isIgnoredReason: '',
  },
  didSubmit: false,
};

class IgnoreTimesheetEntryForm extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    cancelForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    isLoading: PropTypes.bool,
    data: PropTypes.shape(),
    error: PropTypes.string,
  };

  static defaultProps = {
    isSubmitting: false,
    isLoading: false,
    data: {},
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  onSubmit = () => {
    const { submitForm } = this.props;
    const { values } = this.state;
    submitForm(values);
  };

  onFieldChange = (e) => {
    const { id: formId } = this.props;
    const { id, value } = e.target;
    const fieldId = id.replace(`${formId}-`, '');

    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [fieldId]: value,
      },
    }));
  };

  reset = () => {
    this.setState(() => ({
      ...defaultState,
    }));
  };

  render() {
    const { id, isLoading, isSubmitting, error, cancelForm } = this.props;
    const { values } = this.state;
    const { isIgnoredReason } = values;

    const submitProps = {
      btnClassName: 'button',
      actionBtnClassName: 'button button--icon--loading',
      btnText: 'Ignore Entry',
      actionText: isLoading ? 'Loading...' : 'Saving...',
      isActing: isSubmitting || isLoading,
    };

    return (
      <Form onSubmit={this.onSubmit} className="form--ignore-entry">
        {!isSubmitting && !!error && <Error error={error} className="form-error-msg" />}
        <fieldset className="fieldset" disabled={isLoading}>
          <InputText
            id={`${id}-isIgnoredReason`}
            labelText="Reason this entry should be ignored"
            value={isIgnoredReason}
            onChange={this.onFieldChange}
            isRequired
          />
        </fieldset>
        <div className="form-actions">
          <Button onClick={cancelForm} className="button--text--cancel">
            Cancel
          </Button>
          <ActingSubmitButton {...submitProps} />
        </div>
      </Form>
    );
  }
}

export default IgnoreTimesheetEntryForm;
