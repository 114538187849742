import { combineReducers } from 'redux';
import { createAPIReducer, createRecordReducer } from 'utils/index';

const initialAPIState = {
  isRequesting: false,
  didRequestWithSuccess: false,
  requestError: null,
  id: null,
};

const initialRecordState = {
  isRequesting: false,
  didRequestWithSuccess: false,
  requestError: null,
  data: {},
};

export default combineReducers({
  update: createAPIReducer('TENANT_UPDATE_', initialAPIState),
  get: createRecordReducer('TENANT_GET_', initialRecordState),
});
