import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { insertIf } from 'utils/index';

export class TabsNav extends PureComponent {
  static propTypes = {
    navItems: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        tabLabel: PropTypes.string.isRequired,
        tabUrl: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired,
        referrer: PropTypes.string,
        referrerType: PropTypes.string,
      }),
    ).isRequired,
  };

  render() {
    const { navItems } = this.props;
    const items = navItems.map((it) => {
      const toProps = {
        pathname: it.tabUrl,
        ...insertIf(it.referrer && it.referrerType, 'state', { referrer: it.referrer, referrerType: it.referrerType }),
      };
      return (
        <Link className={it.isActive ? 'active' : ''} key={it.key} to={toProps}>
          {it.tabLabel}
        </Link>
      );
    });

    return <nav className="tabs-nav">{items}</nav>;
  }
}
