import React from 'react';
import PropTypes from 'prop-types';
import {
  PROFILE_TYPE_STAFF,
  PROFILE_TYPE_ADULT,
  PROFILE_TYPE_STUDENT,
  PROFILE_ACTIVE,
  PROFILE_INACTIVE,
  PROFILE_ARCHIVED,
} from 'constants/index';

export const ProfileInfo = (props) => {
  const { profileType, profileStatus } = props;
  return (
    <div className="profile-info">
      <span className="profile-type">{profileType}</span>
      <span className={`profile-status ${!!profileStatus && profileStatus}`}>{profileStatus}</span>
    </div>
  );
};

ProfileInfo.propTypes = {
  profileType: PropTypes.oneOf([PROFILE_TYPE_ADULT, PROFILE_TYPE_STUDENT, PROFILE_TYPE_STAFF]).isRequired,
  profileStatus: PropTypes.oneOf([PROFILE_ACTIVE, PROFILE_INACTIVE, PROFILE_ARCHIVED]),
};

ProfileInfo.defaultProps = {
  profileStatus: null,
};
