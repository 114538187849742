import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { ScheduleForm } from 'components/form';
import { PageFocusview } from 'components/layout';
import {
  ROUTE_STUDENT_VIEW,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_ADULT_VIEW,
  SCHEDULE_TYPE_PERSON,
  TYPE_SCHEDULE,
  TYPE_STUDENT,
  TYPE_ADULT,
  TYPE_STAFF,
} from 'constants/index';

import { addSchedule } from 'actions';
import scheduleDefaultDocData from '__data/scheduleDefaultDocData.js';

const addScheduleData = {
  EffectiveDate: null,
  IsArchived: false,
  ...scheduleDefaultDocData,
};

const closeUrl = (recordType, id) => {
  if (recordType === TYPE_STUDENT) {
    return `${ROUTE_STUDENT_VIEW}${id}/schedule/`;
  }
  if (recordType === TYPE_STAFF) {
    return `${ROUTE_STAFFMEMBER_VIEW}${id}/schedule/`;
  }
  return `${ROUTE_ADULT_VIEW}${id}/schedule/`;
};

const ScheduleAdd = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { originType } = props;
  const { isRequesting, didRequestWithSuccess, requestError } = useSelector((state) => state.schedule.add);
  // use didRequestWithSuccess to redirect to the student url
  if (!requestError && didRequestWithSuccess) {
    if (originType === TYPE_STUDENT) {
      return <Redirect to={`${ROUTE_STUDENT_VIEW}${id}/schedule`} />;
    }
    if (originType === TYPE_STAFF) {
      return <Redirect to={`${ROUTE_STAFFMEMBER_VIEW}${id}/schedule`} />;
    }
  }

  const wrapperClassNames = [];
  if (originType === TYPE_STAFF || originType === TYPE_STUDENT) {
    wrapperClassNames.push('width--m');
  } else wrapperClassNames.push('width--s');
  // show the error if there is one
  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={wrapperClassNames}
      pageTitle="Add a schedule"
      closeButtonUrl={closeUrl(originType, id)}
      showCloseButton
    >
      <ScheduleForm
        submitFn={(values) => dispatch(addSchedule(id, values, TYPE_SCHEDULE))}
        schedule={addScheduleData}
        isSubmitting={isRequesting}
        cancelUrl={closeUrl(originType, id)}
        scheduleType={SCHEDULE_TYPE_PERSON}
        originType={originType}
      />
    </PageFocusview>
  );
};

ScheduleAdd.propTypes = {
  originType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
};

export default ScheduleAdd;
