import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const API_ID = 'SCHEDULE_UPDATE_';

const scheduleUpdateRequest = (memberId) => ({
  type: `${API_ID}${API_REQUEST}`,
  payload: {
    id: memberId,
  },
});

const scheduleUpdateSuccess = (memberId) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id: memberId,
  },
});

const scheduleUpdateReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const scheduleUpdateFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const updateSchedule = (id, data) => (dispatch) => {
  dispatch(scheduleUpdateRequest());
  const { memberId, IsInEffect, IsLocked, ...rest } = data;
  api
    .updateSchedule(id, {
      ...rest,
    })
    .then(() => {
      dispatch(scheduleUpdateSuccess(memberId));
    })
    .then(() => dispatch(scheduleUpdateReset()))
    .catch((err) => {
      dispatch(scheduleUpdateFailure(err));
    });
};
