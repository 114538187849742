import { BILLING_TYPE_FIXED, BILLING_TYPE_PERC } from 'constants/index';

export * from './common';
export * from './dateTime';
export * from './createReducer';
export * from './data';
export * from './content';
export * from './formUtils';
export * from './validationChangeForm';
/**
 * Noop function to use as a placeholder
 * or when a function should do nothing
 */
export const noop = () => {};

/**
 * Retrieve 'tenant' domain name from the current url
 * IE. if domain is https://test-center.easyadmin.app, returns test-center
 *
 * @param defaultDomain The app's default domain (ie. https://admin.easyadmin.app)
 * @param defaultTenant The default tenant (ie. test-center)
 * @param baseDomain The base TLD to compare against (ie. easyadmin.app)
 * @param url Url to parse
 * @returns {String} The tenant's id, or the default tenant
 */
export const getTenantId = (defaultDomain, defaultTenant, baseDomain, url) => {
  if (url === defaultDomain || url.indexOf('localhost') > -1) return defaultTenant;
  const protocol = 'https://';
  const protoLen = protocol.length;
  const urlNoProto = url.indexOf(protocol) > -1 ? url.substr(protoLen) : url;
  const urlWithoutBase = urlNoProto.substr(0, urlNoProto.indexOf(baseDomain) - 1);
  // remove sub-domain of base
  return urlWithoutBase.indexOf('.') > -1 ? urlWithoutBase.substr(urlWithoutBase.indexOf('.') + 1) : urlWithoutBase;
};

/**
 * Select a tenant based on a possibly null tenantId
 *
 * @param {*} userTenants The list of tenants the user has access to
 * @param {*} tenantId The possibly null tenantId to return if valid
 */
export const getSelectedTenant = (userTenants, tenantId) => {
  const isAdmin = userTenants.includes('*');
  const isSelectedTenantUserTenant = isAdmin || userTenants.includes(tenantId);
  if (isSelectedTenantUserTenant) return tenantId;
  // if user is Admin and has not made a selection, set null so tenant selection is shown
  if (isAdmin) return null;
  // if user has single tenant, select it, else set null so tenant selection is shown
  return userTenants.length === 1 ? userTenants[0] : null;
};

/**
 * Return a possibly empty object containing the key value pair if condition is met
 * Helper function intended to be used with object spread operator to conditionally add
 * properties to an existing object
 *
 * @param cond Condition to be met
 * @param key
 * @param val
 * @returns {{}}
 */
export const insertIf = (cond, key, val) => (cond ? { [`${key}`]: val } : {});

/**
 * Format 8-digit pin to 2 groups of 4 digits
 * @param pin The pin to format
 * @returns {string} The formatted pin
 */
export const getFormattedPin = (pin) => (pin ? `${pin.substr(0, 4)} ${pin.substr(4)}` : pin);

export const getFormattedBilling = (billing) => {
  if (!billing) return 0;
  const { type, amount } = billing;
  switch (type) {
    case BILLING_TYPE_PERC:
      return amount
        ? `${Number(amount / 100).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 0 })}`
        : 'N/A';
    case BILLING_TYPE_FIXED:
    default:
      return amount
        ? `${Number(amount).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}`
        : 'N/A';
  }
};

/**
 * Format a boolean value to Yes or No
 * @param bool Value to test
 * @returns {string} Yes if true, No if false
 */
export const boolToYesNo = (bool) => (bool ? 'Yes' : 'No');
