import React from 'react';
import PropTypes from 'prop-types';
import { AbsenceForm } from 'components/form';
import { PageFocusview } from 'components/layout';
import {
  ROUTE_STUDENT_VIEW,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_ADULT_VIEW,
  SCHEDULE_TYPE_PERSON,
  TYPE_PLANNED_ABSENCE,
  TYPE_STUDENT,
  TYPE_ADULT,
  TYPE_STAFF,
} from 'constants/index';
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addAbsence } from 'actions';

const absenceData = {
  startDate: null,
  endDate: null,
  reason: '',
};
const closeUrl = (recordType, id) => {
  if (recordType === TYPE_STUDENT) {
    return `${ROUTE_STUDENT_VIEW}${id}/schedule/`;
  }
  if (recordType === TYPE_STAFF) {
    return `${ROUTE_STAFFMEMBER_VIEW}${id}/schedule/`;
  }
  return `${ROUTE_ADULT_VIEW}${id}/schedule/`;
};

const PlannedAbsenceAdd = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { originType } = props;
  const { isRequesting, didRequestWithSuccess, requestError } = useSelector((state) => state.absence.add);

  if (!requestError && didRequestWithSuccess) {
    if (originType === TYPE_STUDENT) {
      return <Redirect to={`${ROUTE_STUDENT_VIEW}${id}/schedule`} />;
    }
    if (originType === TYPE_STAFF) {
      return <Redirect to={`${ROUTE_STAFFMEMBER_VIEW}${id}/schedule`} />;
    }
  }

  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle="Add a planned absence"
      closeButtonUrl={closeUrl(originType, id)}
      showCloseButton
    >
      <AbsenceForm
        submitFn={(values) => {
          const val = { ...values, IsArchived: false };
          dispatch(addAbsence(id, val, TYPE_PLANNED_ABSENCE));
          console.log(val);
        }}
        absence={absenceData}
        isSubmitting={isRequesting}
        cancelUrl={closeUrl(originType, id)}
        scheduleType={SCHEDULE_TYPE_PERSON}
      />
    </PageFocusview>
  );
};

PlannedAbsenceAdd.propTypes = {
  originType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
};

export default PlannedAbsenceAdd;
