import { combineReducers } from 'redux';
import { createAPIReducer, createReducer } from 'utils/index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const GET_PREFIX = 'CENTER_SCHEDULE_GET_';
const UPDATE_PREFIX = 'CENTER_SCHEDULE_UPDATE_';

const initialGetState = {
  isRequesting: false,
  data: {},
  requestError: null,
};
const initialAPIState = {
  isRequesting: false,
  didRequestWithSuccess: false,
  requestError: null,
};

const scheduleGet = createReducer(initialGetState, {
  [`${GET_PREFIX}${API_REQUEST}`]: (state) => ({
    ...state,
    isRequesting: true,
  }),
  [`${GET_PREFIX}${API_SUCCESS}`]: (_, { data }) => ({
    isRequesting: false,
    data,
    requestError: null,
  }),
  [`${GET_PREFIX}${API_FAILURE}`]: (state, { message }) => ({
    ...state,
    isRequesting: false,
    requestError: message,
  }),
  [`${GET_PREFIX}${API_RESET}`]: () => ({
    ...initialGetState,
  }),
});

export default combineReducers({
  get: scheduleGet,
  update: createAPIReducer(UPDATE_PREFIX, initialAPIState),
});
