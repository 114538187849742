import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ListItemEmergencyContact extends PureComponent {
  static propTypes = {
    emergencyContactName: PropTypes.string.isRequired,
    emergencyContactRelationship: PropTypes.string.isRequired,
  };

  render() {
    const { emergencyContactName, emergencyContactRelationship } = this.props;
    return (
      <li>
        <h5>{emergencyContactName}</h5>
        <span className="relationship">{emergencyContactRelationship}</span>
      </li>
    );
  }
}

export default ListItemEmergencyContact;
