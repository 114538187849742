import { api } from 'index';
import { sortByString } from 'utils/index';
import {
  STUDENT_LIST_SUBSCRIBE,
  STUDENT_LIST_SUCCESS,
  STUDENT_LIST_FAILURE,
  STUDENT_LIST_UNSUBSCRIBE,
  TYPE_STUDENT,
} from 'constants/index';

let listUnsubscriber = null;

export const studentListSubscribe = () => ({
  type: STUDENT_LIST_SUBSCRIBE,
});

export const studentListUnsubscribe = () => ({
  type: STUDENT_LIST_UNSUBSCRIBE,
});

const studentListSuccess = ({ items }) => ({
  type: STUDENT_LIST_SUCCESS,
  payload: {
    items,
  },
});

const studentListFailure = (message) => ({
  type: STUDENT_LIST_FAILURE,
  payload: {
    message,
  },
});

export const subscribeToStudents = () => (dispatch) => {
  dispatch(studentListSubscribe());
  listUnsubscriber = api.subscribeToMemberList(TYPE_STUDENT, ({ docs, size }) => {
    if (size) {
      const items = docs
        .map((it) => {
          const { id, ...rest } = it.data();
          return {
            id: it.id,
            fullName: `${rest.lastName}, ${rest.firstName}`,
            ...rest,
          };
        })
        .sort((a, b) => sortByString(a.fullName, b.fullName))
        .reduce((acc, next) => {
          return {
            ...acc,
            [next.id]: next,
          };
        }, {});
      dispatch(studentListSuccess({ items }));
    } else {
      dispatch(studentListFailure('No records available'));
    }
  });
};

export const unsubscribeFromStudents = () => (dispatch) => {
  dispatch(studentListUnsubscribe());
  api.unsubscribeListener(listUnsubscriber);
  listUnsubscriber = null;
};
