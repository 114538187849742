import React from 'react';
import PropTypes from 'prop-types';

export const TabContent = (props) => {
  const { id, isShowing, children } = props;

  return (
    <div key={id} className={`tab-content${isShowing && ' visible'}`} id={id}>
      {children}
    </div>
  );
};

TabContent.propTypes = {
  id: PropTypes.string.isRequired,
  isShowing: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
