import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ActionLinkIcon } from 'components/links';
import { LIST_TYPE_RELATED, LIST_TYPE_EMERGENCY } from 'constants/index';

class ListAdd extends PureComponent {
  static propTypes = {
    listType: PropTypes.oneOf([LIST_TYPE_RELATED, LIST_TYPE_EMERGENCY]).isRequired,
    linkUrl: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    itemType: PropTypes.string.isRequired,
    children: PropTypes.node,
    linkState: PropTypes.shape(),
  };

  static defaultProps = {
    children: null,
    linkState: null,
  };

  render() {
    const { listType, linkUrl, linkState, action, itemType, children } = this.props;

    return (
      <div className="list--add">
        {children && <ul className={`list--${listType}`}>{children}</ul>}
        <ActionLinkIcon
          type="add"
          url={linkUrl}
          linkState={linkState}
          linkText={`${action}${children ? ' another ' : ' '}${itemType}`}
        />
      </div>
    );
  }
}

export default ListAdd;
