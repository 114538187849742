import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE } from 'constants/index';
import scheduleDefaultDocData from '__data/scheduleDefaultDocData.js';

const API_ID = 'CENTER_SCHEDULE_GET_';

const scheduleGetRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const sortDayData = (data) => {
  return {
    Monday: { ...data.Monday },
    Tuesday: { ...data.Tuesday },
    Wednesday: { ...data.Wednesday },
    Thursday: { ...data.Thursday },
    Friday: { ...data.Friday },
    Saturday: { ...data.Saturday },
    Sunday: { ...data.Sunday },
  };
};

const scheduleGetSuccess = (data) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    data: sortDayData(data),
  },
});

const scheduleGetFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const getCenterSchedule = () => (dispatch) => {
  dispatch(scheduleGetRequest());
  api
    .getCenterSchedule()
    .then((doc) => {
      dispatch(scheduleGetSuccess(!doc.exists ? scheduleDefaultDocData : doc.data()));
    })
    .catch((err) => {
      dispatch(scheduleGetFailure(err));
    });
};
