import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SubmitButton } from 'components/buttons';
import { InputEmail, InputPassword } from 'components/formElements';
import { Form, Error } from 'components/layout';

class LoginForm extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isAuthenticating: PropTypes.bool.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    error: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  onChangeEmail = (e) => this.setState({ email: e.target.value });

  onChangePassword = (e) => this.setState({ password: e.target.value });

  onSubmit = () => {
    const { login } = this.props;
    const { email, password } = this.state;
    login(email, password);
  };

  render() {
    const { isAuthenticated, isAuthenticating, error } = this.props;
    const { email, password } = this.state;
    const inputEmail = {
      id: 'loginEmail',
      labelText: 'Email Address',
      onChange: this.onChangeEmail,
      isRequired: true,
    };

    const inputPassword = {
      id: 'passwordfield',
      labelText: 'Password',
      onChange: this.onChangePassword,
      isRequired: true,
      showByDefault: false,
    };

    const button = {
      btnText: 'Log In',
      isDisabled: !email || !password,
    };

    return (
      <Form className="form form--full-width" onSubmit={this.onSubmit}>
        {!isAuthenticated && !isAuthenticating && error && <Error error={error} className="form-error-msg" />}
        <InputEmail {...inputEmail} />
        <InputPassword {...inputPassword} />
        <div className="form-actions">
          <SubmitButton {...button} />
        </div>
      </Form>
    );
  }
}

export default LoginForm;
