import React from 'react';

const SubPageHeader = (props) => {
  // eslint-disable-next-line react/prop-types
  const { title, actions } = props;
  return (
    <div className="page-header">
      <h4 className="page-title">{title}</h4>
      {!!actions && actions}
    </div>
  );
};

export default SubPageHeader;
