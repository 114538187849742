import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AbsenceForm } from 'components/form';
import { PageFocusview, Error } from 'components/layout';
import {
  ROUTE_STUDENT_VIEW,
  SCHEDULE_TYPE_PERSON,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_ADULT_VIEW,
  TYPE_STUDENT,
  TYPE_ADULT,
  TYPE_STAFF,
} from 'constants/index';
import { useSubscription } from 'utils/useSubscription';
import { subscribeToAbsences, unsubscribeFromAbsences, updateAbsence } from 'actions';

const closeUrl = (recordType, id) => {
  if (recordType === TYPE_STUDENT) {
    return `${ROUTE_STUDENT_VIEW}${id}/schedule/`;
  }
  if (recordType === TYPE_STAFF) {
    return `${ROUTE_STAFFMEMBER_VIEW}${id}/schedule/`;
  }
  return `${ROUTE_ADULT_VIEW}${id}/schedule/`;
};

const PlannedAbsenceUpdate = (props) => {
  const { id, absenceId } = useParams();
  const dispatch = useDispatch();
  const { originType } = props;
  const { items, fetchError, size } = useSubscription(
    {
      subscribeFn: subscribeToAbsences,
      unsubscribeFn: unsubscribeFromAbsences,
      selectorKey: 'absences',
    },
    false,
    [id],
  );
  const { isFetching } = useSelector((state) => state.absences);
  const { isRequesting, didRequestWithSuccess, requestError } = useSelector((state) => state.absence.update);

  if (!requestError && didRequestWithSuccess) {
    if (originType === TYPE_STUDENT) {
      return <Redirect to={`${ROUTE_STUDENT_VIEW}${id}/schedule`} />;
    }
    if (originType === TYPE_STAFF) {
      return <Redirect to={`${ROUTE_STAFFMEMBER_VIEW}${id}/schedule`} />;
    }
  }

  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle="Edit a planned absence"
      closeButtonUrl={closeUrl(originType, id)}
      showCloseButton
    >
      {!!fetchError && <Error error={fetchError} />}
      {!fetchError && !!requestError && <Error error={requestError} />}
      {!size && <div className="loading" />}
      {!!size && !isFetching && (
        <AbsenceForm
          submitFn={(values) => {
            const data = { ...items[absenceId], ...values };
            dispatch(updateAbsence(absenceId, data));
          }}
          absence={{
            startDate: items[absenceId].startDate,
            endDate: items[absenceId].endDate,
            reason: items[absenceId].reason,
          }}
          isSubmitting={isRequesting}
          cancelUrl={closeUrl(originType, id)}
          scheduleType={SCHEDULE_TYPE_PERSON}
        />
      )}
    </PageFocusview>
  );
};

PlannedAbsenceUpdate.propTypes = {
  originType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
};

export default PlannedAbsenceUpdate;
