import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const API_ID = 'ADULT_DELETE_';

const adultDeleteRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const adultDeleteSuccess = (id) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id,
  },
});

const adultDeleteReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const adultDeleteFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const deleteAdult = (id) => (dispatch, getState) => {
  const {
    adults: { items },
  } = getState();
  dispatch(adultDeleteRequest());
  api
    .deleteMember(id, items[id])
    .then(() => {
      dispatch(adultDeleteSuccess(id));
    })
    .then(() => dispatch(adultDeleteReset()))
    .catch((err) => {
      dispatch(adultDeleteFailure(err));
    });
};
