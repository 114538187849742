import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  subscribeToStudents,
  subscribeToAdults,
  subscribeToTimesheets,
  unsubscribeFromTimesheets,
  toggleModal,
  toggleAll,
  toggleRow,
  downloadCSVTimesheetReport,
  // getTenants,
} from 'actions';
import { getDisplayHours, stringToMoment, getInterimId } from 'utils/index';
import { getExpandedTimesheets, getExpandedExceptions } from 'selectors/index';
import { TYPE_STUDENT } from 'constants/index';
import { Header, PageActions, PageHeader, PageTitle, Wrapper } from 'components/layout';
import { NavUser, GlobalNav } from 'components/nav';
import { TimesheetSearchForm } from 'components/form';
import {
  AddTimesheetEntry,
  AddPresetTimesheetEntry,
  UpdateTimesheetEntry,
  IgnoreTimesheetEntry,
} from 'components/modal';
import {
  TableTimesheet,
  TableRowTimesheet,
  TimesheetResults,
  TableRowTimesheetEntry,
  TableRowExceptionEntry,
} from 'components/timesheetTable';
import { Table } from 'components/table';

const outerHeaderLabels = [
  {
    label: 'Name',
    className: 'name',
  },
  {
    label: 'Total Hours',
    className: 'total',
  },
];
const outerHeaderLabelsException = [
  {
    label: 'Name',
    className: 'name',
  },
  {
    label: 'Ignored',
    className: 'total',
  },
];
const innerHeaderLabels = [
  {
    label: '',
    className: 'flag',
  },
  {
    label: 'Date',
    className: 'date',
  },
  {
    label: 'Time',
    className: 'time',
  },
  {
    label: 'Action',
    className: 'action',
  },
  {
    label: 'Details',
    className: 'details',
  },
  {
    label: 'Hours',
    className: 'hours',
  },
  {
    label: ' ',
    className: 'action--left',
  },
  {
    label: ' ',
    className: 'action--right',
  },
];
const innerHeaderLabelsException = [
  {
    label: 'Date',
    className: 'date',
  },
  {
    label: 'Time',
    className: 'time',
  },
  {
    label: 'Action',
    className: 'action',
  },
  {
    label: 'Details',
    className: 'details',
  },
  {
    label: 'Reason for ignoring',
    className: 'reason',
  },
];

class Timesheets extends PureComponent {
  static propTypes = {
    doToggleAll: PropTypes.func.isRequired,
    doToggleRow: PropTypes.func.isRequired,
    doToggleModal: PropTypes.func.isRequired,
    doSubscribe: PropTypes.func.isRequired,
    doUnsubscribe: PropTypes.func.isRequired,
    doDownloadCSVTimesheetReport: PropTypes.func.isRequired,
    doSubscribeToAdults: PropTypes.func.isRequired,
    doSubscribeToStudents: PropTypes.func.isRequired,
    // doGetTenants: PropTypes.func.isRequired,
    isUpdateOpen: PropTypes.bool.isRequired,
    isAddOpen: PropTypes.bool.isRequired,
    isPresetAddOpen: PropTypes.bool.isRequired,
    isIgnoreOpen: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()),
    exceptionItems: PropTypes.arrayOf(PropTypes.shape()),
    studentList: PropTypes.shape().isRequired,
    adultList: PropTypes.shape().isRequired,
    tenants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        refId: PropTypes.string,
      }),
    ).isRequired,
    fetchError: PropTypes.string,
  };

  static defaultProps = {
    items: [],
    exceptionItems: [],
    fetchError: null,
  };

  constructor(props) {
    super(props);

    this.pageActions = [
      {
        key: 'printTimesheets',
        classNames: 'button--icon--print',
        text: 'Print Timesheets',
        onClick: this.printTimesheets,
        isVisible: false,
        isLink: false,
      },
      {
        key: 'toggleAddTimesheetEntry',
        classNames: 'button--action button--icon--add',
        text: 'Add Missing Entry',
        onClick: this.showAddTimesheetModal,
        isVisible: true,
        isLink: false,
      },
    ];

    this.state = {
      addPresetData: {},
      updateData: {},
      updateId: null,
      ignoreData: {},
      ignoreId: null,
      startDate: null,
      endDate: null,
      type: null,
      person: null,
      isSubsidized: false,
    };
  }

  componentDidMount() {
    const {
      adultList,
      studentList,
      doSubscribeToAdults,
      doSubscribeToStudents /* , tenants, doGetTenants */,
    } = this.props;
    if (!Object.keys(adultList).length) {
      doSubscribeToAdults();
    }
    if (!Object.keys(studentList).length) {
      doSubscribeToStudents();
    }
    // if (!tenants.length) doGetTenants();
  }

  componentWillUnmount() {
    const { doUnsubscribe } = this.props;
    doUnsubscribe();
  }

  showAddTimesheetModal = () => {
    const { doToggleModal } = this.props;
    doToggleModal('TIMESHEET_ADD', true);
  };

  showAddPresetTimesheetModal = (data) => {
    const { doToggleModal } = this.props;
    this.setState(
      (prevState) => ({
        ...prevState,
        addPresetData: data,
      }),
      () => {
        doToggleModal('TIMESHEET_PRESET_ADD', true);
      },
    );
  };

  showUpdateTimesheetModal = (id, data) => {
    const { doToggleModal } = this.props;
    this.setState(
      (prevState) => ({
        ...prevState,
        updateId: id,
        updateData: data,
      }),
      () => {
        doToggleModal('TIMESHEET_UPDATE', true);
      },
    );
  };

  showIgnoreTimesheetModal = (id, data) => {
    const { doToggleModal } = this.props;
    this.setState(
      (prevState) => ({
        ...prevState,
        ignoreId: id,
        ignoreData: data,
      }),
      () => {
        doToggleModal('TIMESHEET_IGNORE', true);
      },
    );
  };

  onCloseModal = (modalId) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        addPresetData: {},
        updateId: null,
        updateData: {},
        ignoreId: null,
        ignoreData: {},
      }),
      () => {
        const { doToggleModal } = this.props;
        doToggleModal(modalId);
      },
    );
  };

  doSearch = (params) => {
    const { doUnsubscribe, doSubscribe } = this.props;
    const { startDate, endDate, type, person, isSubsidized } = params;
    this.setState(
      (prevState) => ({
        ...prevState,
        startDate,
        endDate,
        type,
        person,
        isSubsidized,
      }),
      () => {
        doUnsubscribe();
        doSubscribe(params);
      },
    );
  };

  doUpdateEntry = (id, data) => {
    this.showUpdateTimesheetModal(id, data);
  };

  doIgnoreEntry = (id, data) => {
    this.showIgnoreTimesheetModal(id, data);
  };

  doAddEntry = (m, memberId, type) => {
    this.showAddPresetTimesheetModal({ m, memberId, type });
  };

  printTimesheets = () => window.print();

  mapVisibleActions = (action) => {
    const { items } = this.props;
    const { isVisible, ...rest } = action;
    return {
      ...rest,
      isVisible: !isVisible ? !!items && !!items.length : isVisible,
    };
  };

  expandAllRows = (tableID) => {
    const { doToggleAll } = this.props;
    doToggleAll(tableID, true);
  };

  collapseAllRows = (tableID) => {
    const { doToggleAll } = this.props;
    doToggleAll(tableID, false);
  };

  expandRow = (rowId) => {
    const { doToggleRow } = this.props;
    doToggleRow(rowId, true);
  };

  collapseRow = (rowId) => {
    const { doToggleRow } = this.props;
    doToggleRow(rowId, false);
  };

  downloadCSV = (tableId) => {
    const { doDownloadCSVTimesheetReport, items, exceptionItems, tenants } = this.props;
    const { startDate, endDate, type, person, isSubsidized } = this.state;
    doDownloadCSVTimesheetReport(
      tableId === 'MAIN' ? items : exceptionItems,
      tableId,
      {
        startDate,
        endDate,
        type,
        person,
        isSubsidized,
      },
      tenants,
    );
  };

  render() {
    const {
      items,
      exceptionItems,
      fetchError,
      adultList,
      studentList,
      isAddOpen,
      isUpdateOpen,
      isPresetAddOpen,
      isIgnoreOpen,
    } = this.props;
    const { startDate, endDate, type, addPresetData, updateId, updateData, ignoreId, ignoreData } = this.state;
    const staffMemberList = Object.keys(adultList)
      .filter((it) => adultList[it].isStaffMember)
      .map((it) => adultList[it])
      .reduce((acc, next) => ({ ...acc, [next.id]: next }), {});
    const s = stringToMoment(startDate).format('ddd MM/DD/Y');
    const e = stringToMoment(endDate).format('ddd MM/DD/Y');
    const searchType = type === TYPE_STUDENT ? 'Attendance' : 'Payroll';
    const mainTableOptions = [
      {
        key: 'expandAll',
        classNames: 'button--texticon--expand-all',
        text: 'Expand All',
        onClick: () => this.expandAllRows('MAIN'),
      },
      {
        key: 'collapseAll',
        classNames: 'button--texticon--collapse-all',
        text: 'Collapse All',
        onClick: () => this.collapseAllRows('MAIN'),
      },
    ];
    const exceptionTableOptions = [
      {
        key: 'expandAll',
        classNames: 'button--texticon--expand-all',
        text: 'Expand All',
        onClick: () => this.expandAllRows('EXCEPTION'),
      },
      {
        key: 'collapseAll',
        classNames: 'button--texticon--collapse-all',
        text: 'Collapse All',
        onClick: () => this.collapseAllRows('EXCEPTION'),
      },
    ];
    const mainTableActions = [
      {
        key: 'downloadCsvMain',
        classNames: 'button--link',
        text: 'Download CSV',
        onClick: () => this.downloadCSV('MAIN'),
      },
    ];
    const exceptionTableActions = [
      {
        key: 'downloadCsvEx',
        classNames: 'button--link',
        text: 'Download CSV',
        onClick: () => this.downloadCSV('EX'),
      },
    ];
    const headerChildren = (
      <>
        <NavUser />
      </>
    );
    const tableRows = items.map((it) => {
      const { id, fullName, days, totalHours, isFlagged, isExpanded } = it;
      const innerRows = days.map((d) => {
        const { date, entries, isFlagged: isDayFlagged } = d;
        const dayRows = entries.map((entry) => {
          const data = {
            ...entry,
            dateFmt: date,
          };
          return (
            <TableRowTimesheetEntry
              key={data.id || getInterimId()}
              data={data}
              onAddClick={this.doAddEntry}
              onUpdateClick={this.doUpdateEntry}
              onIgnoreClick={this.doIgnoreEntry}
            />
          );
        });
        return (
          <div className={`day${isDayFlagged ? ' flag--attention' : ''}`} key={`${id}-${date}`}>
            {dayRows}
          </div>
        );
      });
      return (
        <TableRowTimesheet
          key={id}
          id={id}
          fullName={fullName}
          totalCount={`${getDisplayHours(totalHours)}`}
          isFlagged={isFlagged}
          isExpanded={isExpanded}
          doCollapse={this.collapseRow}
          doExpand={this.expandRow}
        >
          <Table id={`${getInterimId()}`} headerLabels={innerHeaderLabels} rows={innerRows} />
        </TableRowTimesheet>
      );
    });
    const exceptionRows = exceptionItems.map((it) => {
      const { id, fullName, days, count, isExpanded } = it;
      const inExRows = days.map((d) => <TableRowExceptionEntry key={d.id} data={d} />);
      return (
        <TableRowTimesheet
          key={id}
          id={id}
          fullName={fullName}
          totalCount={`${count}`}
          isExpanded={isExpanded}
          doCollapse={this.collapseRow}
          doExpand={this.expandRow}
        >
          <Table id={`${getInterimId()}`} headerLabels={innerHeaderLabelsException} rows={inExRows} />
        </TableRowTimesheet>
      );
    });
    return (
      <>
        <Header>{headerChildren}</Header>
        <GlobalNav />
        <Wrapper>
          <PageHeader>
            <PageTitle title="Timesheets" />
            <PageActions actions={this.pageActions.map(this.mapVisibleActions)} />
          </PageHeader>
          <TimesheetSearchForm submitForm={this.doSearch} isSubmitting={false} />
          {!tableRows.length && fetchError && <p>{fetchError}</p>}
          {!!tableRows.length && (
            <TableTimesheet
              headerLabels={outerHeaderLabels}
              rows={tableRows}
              resultsRow={
                <TimesheetResults
                  start={s}
                  end={e}
                  type={searchType}
                  tableOptions={mainTableOptions}
                  itemCount={items.length}
                />
              }
              tableActions={mainTableActions}
            />
          )}
          {!!exceptionRows.length && (
            <TableTimesheet
              id="timesheet-exceptions"
              headerLabels={outerHeaderLabelsException}
              rows={exceptionRows}
              resultsRow={
                <TimesheetResults
                  start={s}
                  end={e}
                  type={`Ignored ${searchType.toLowerCase()}`}
                  tableOptions={exceptionTableOptions}
                  ignoredItemCount={exceptionRows.length}
                />
              }
              tableActions={exceptionTableActions}
            />
          )}
          <AddTimesheetEntry
            onClose={() => {
              this.onCloseModal('TIMESHEET_ADD');
            }}
            isShowing={isAddOpen}
            staffMembers={staffMemberList}
            studentMembers={studentList}
            type={type}
          />
          <AddPresetTimesheetEntry
            onClose={() => {
              this.onCloseModal('TIMESHEET_PRESET_ADD');
            }}
            isShowing={isPresetAddOpen}
            staffMembers={staffMemberList}
            studentMembers={studentList}
            type={type}
            data={addPresetData}
          />
          <UpdateTimesheetEntry
            onClose={() => {
              this.onCloseModal('TIMESHEET_UPDATE');
            }}
            isShowing={isUpdateOpen}
            staffMembers={staffMemberList}
            studentMembers={studentList}
            recordId={updateId}
            record={updateData}
          />
          <IgnoreTimesheetEntry
            onClose={() => {
              this.onCloseModal('TIMESHEET_IGNORE');
            }}
            isShowing={isIgnoreOpen}
            recordId={ignoreId}
            record={ignoreData}
          />
        </Wrapper>
      </>
    );
  }
}

const mapDispatchToProps = {
  doToggleAll: toggleAll,
  doToggleRow: toggleRow,
  doToggleModal: toggleModal,
  doSubscribe: subscribeToTimesheets,
  doUnsubscribe: unsubscribeFromTimesheets,
  doDownloadCSVTimesheetReport: downloadCSVTimesheetReport,
  doSubscribeToAdults: subscribeToAdults,
  doSubscribeToStudents: subscribeToStudents,
  // doGetTenants: getTenants,
};

const mapStateToProps = ({ timesheets, ui: { modals }, adults, students, tenants }) => {
  const { fetchError } = timesheets;
  const { TIMESHEET_ADD, TIMESHEET_UPDATE, TIMESHEET_PRESET_ADD, TIMESHEET_IGNORE } = modals;
  const { isOpen: isUpdateOpen } = TIMESHEET_UPDATE;
  const { isOpen: isAddOpen } = TIMESHEET_ADD;
  const { isOpen: isPresetAddOpen } = TIMESHEET_PRESET_ADD;
  const { isOpen: isIgnoreOpen } = TIMESHEET_IGNORE;
  const { items: adultList } = adults;
  const { items: studentList } = students;
  const { items: tenantList } = tenants;
  return {
    items: getExpandedTimesheets(timesheets),
    exceptionItems: getExpandedExceptions(timesheets),
    fetchError,
    isUpdateOpen,
    isAddOpen,
    isPresetAddOpen,
    isIgnoreOpen,
    adultList,
    studentList,
    tenants: tenantList,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Timesheets));
