import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';
import { getClassrooms } from './classroomList';

const API_ID = 'CLASSROOM_UPDATE_';

const classroomUpdateRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const classroomUpdateSuccess = (id) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id,
  },
});

const classroomUpdateFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const classroomUpdateReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

export const updateClassroom = (classroomId, data) => (dispatch) => {
  dispatch(classroomUpdateRequest());
  api
    .updateClassroom(classroomId, data)
    .then(() => {
      dispatch(classroomUpdateSuccess());
      dispatch(getClassrooms());
    })
    .then(() => dispatch(classroomUpdateReset()))
    .catch((err) => {
      dispatch(classroomUpdateFailure(err));
    });
};
