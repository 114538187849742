import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const API_ID = 'ABSENCE_ADD_';

const absenceAddRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const absenceAddSuccess = (memberId) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id: memberId,
  },
});

const absenceAddReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const absenceAddFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const addAbsence = (memberId, data, type) => (dispatch) => {
  dispatch(absenceAddRequest());
  const { IsInEffect, IsLocked, ...rest } = data;
  api
    .addSchedule({
      ...rest,
      memberId,
      type,
    })
    .then(() => {
      dispatch(absenceAddSuccess(memberId));
    })
    .then(() => dispatch(absenceAddReset()))
    .catch((err) => {
      dispatch(absenceAddFailure(err));
    });
};
