import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  StudentView,
  ScheduleAdd,
  ScheduleUpdate,
  PlannedAbsenceAdd,
  PlannedAbsenceUpdate,
  EmergencyContactSelect,
  EmergencyContactAdd,
  EmergencyContactUpdate,
} from 'components/memberProfiles';
import {
  ROUTE_STUDENT_PROFILE,
  TYPE_STUDENT,
  ROUTE_STUDENT_SCHEDULE_UPDATE,
  ROUTE_STUDENT_PLANNED_ABSENCE_ADD,
  ROUTE_STUDENT_PLANNED_ABSENCE_UPDATE,
  ROUTE_STUDENT_EMERGENCY_CONTACT_SELECT,
  ROUTE_STUDENT_EMERGENCY_CONTACT_ADD,
  ROUTE_STUDENT_EMERGENCY_CONTACT_UPDATE,
} from 'constants/index';

export const Student = () => {
  window.scrollTo(0, 0);
  return (
    <Switch>
      <Route exact path={ROUTE_STUDENT_EMERGENCY_CONTACT_UPDATE}>
        <EmergencyContactUpdate originType={TYPE_STUDENT} />
      </Route>
      <Route exact path={ROUTE_STUDENT_EMERGENCY_CONTACT_ADD}>
        <EmergencyContactAdd originType={TYPE_STUDENT} />
      </Route>
      <Route exact path={ROUTE_STUDENT_EMERGENCY_CONTACT_SELECT}>
        <EmergencyContactSelect originType={TYPE_STUDENT} />
      </Route>
      <Route exact path={ROUTE_STUDENT_PLANNED_ABSENCE_UPDATE}>
        <PlannedAbsenceUpdate originType={TYPE_STUDENT} />
      </Route>
      <Route exact path={ROUTE_STUDENT_PLANNED_ABSENCE_ADD}>
        <PlannedAbsenceAdd originType={TYPE_STUDENT} />
      </Route>
      <Route exact path={ROUTE_STUDENT_SCHEDULE_UPDATE}>
        <ScheduleUpdate originType={TYPE_STUDENT} />
      </Route>
      <Route exact path={`${ROUTE_STUDENT_PROFILE}schedule/add`}>
        <ScheduleAdd originType={TYPE_STUDENT} />
      </Route>
      <Route path={ROUTE_STUDENT_PROFILE}>
        <StudentView originType={TYPE_STUDENT} />
      </Route>
    </Switch>
  );
};
