import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/buttons';
import { TableRow } from 'components/table';
import { CHECKED_IN, CHECKED_OUT, NO_ACTION_SELECTED } from 'constants/index';
import { round2Precision, getInterimId, isEmpty } from 'utils/index';

const getActionClassName = (action) => {
  switch (action) {
    case CHECKED_IN:
      return ' action--1';
    case CHECKED_OUT:
      return ' action--2';
    case NO_ACTION_SELECTED:
      return ' action--3';
    default:
      return '';
  }
};

const getFlagClassName = (isIgnored, isManualEntry) => {
  if (isIgnored) return ' flag--ignore';
  if (isManualEntry) return ' flag--manual';
  return '';
};

const getFlagTitle = (isIgnored, isManualEntry) => {
  if (isIgnored) return 'Ignored in timesheets';
  if (isManualEntry) return 'Added manually';
  return '';
};

const getDisplayHours = (totalHours, action) => {
  if (totalHours === '0.0' || totalHours === Infinity) {
    return action === CHECKED_OUT ? '0.0' : '';
  }
  const rounded = round2Precision(totalHours, 1);
  return parseFloat(rounded) % 1 === 0 ? `${rounded}.0` : rounded;
};

class TableRowTimesheetEntry extends PureComponent {
  static propTypes = {
    data: PropTypes.shape(),
    onUpdateClick: PropTypes.func.isRequired,
    onAddClick: PropTypes.func.isRequired,
    onIgnoreClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: {},
  };

  render() {
    const { data, onUpdateClick, onAddClick, onIgnoreClick } = this.props;
    const {
      id,
      memberId,
      dateFmt,
      time,
      action,
      actionLabel,
      actionDetails,
      isIgnored,
      isManualEntry,
      type,
      totalHours,
      m,
    } = data;
    const hasData = !isEmpty(data);

    return hasData ? (
      <TableRow key={id || getInterimId()}>
        <li
          className={`tc--flag${getFlagClassName(isIgnored, isManualEntry)}`}
          title={getFlagTitle(isIgnored, isManualEntry)}
        />
        <li className="tc--date">{dateFmt}</li>
        <li className="tc--time">{time}</li>
        <li className={`tc--action${getActionClassName(action)}`}>{actionLabel}</li>
        <li className="tc--details">{actionDetails}</li>
        <li className="tc--hours">{getDisplayHours(totalHours, action)}</li>
        <li className="tc--action--left">
          {!!id && (
            <Button className="button--text" onClick={() => onUpdateClick(id, data)}>
              Edit
            </Button>
          )}
        </li>
        <li className="tc--action--right">
          {id && (
            <Button className="button--text--neg" onClick={() => onIgnoreClick(id, data)}>
              Ignore
            </Button>
          )}
        </li>
        {!id && (
          <li className="tc--action--right">
            <Button className="button--text" onClick={() => onAddClick(m, memberId, type)}>
              Add
            </Button>
          </li>
        )}
      </TableRow>
    ) : null;
  }
}

export default TableRowTimesheetEntry;
