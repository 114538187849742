import { api } from 'index';
import { getTodayAsServerDate } from 'utils';
import { LIST_SUBSCRIBE, LIST_SUCCESS, LIST_FAILURE, LIST_UNSUBSCRIBE } from 'constants/index';

let listUnsubscriber = null;

const apiPrefix = 'ABSENCE_';

export const absenceListSubscribe = () => ({
  type: `${apiPrefix}${LIST_SUBSCRIBE}`,
});

export const absenceListUnsubscribe = () => ({
  type: `${apiPrefix}${LIST_UNSUBSCRIBE}`,
});

const absenceListSuccess = ({ items }) => ({
  type: `${apiPrefix}${LIST_SUCCESS}`,
  payload: {
    items,
  },
});

const absenceListFailure = (message) => ({
  type: `${apiPrefix}${LIST_FAILURE}`,
  payload: {
    message,
  },
});

export const subscribeToAbsences = (memberId) => (dispatch) => {
  dispatch(absenceListSubscribe());
  const today = getTodayAsServerDate();
  listUnsubscriber = api.subscribeToAbsences(memberId, ({ docs, size }) => {
    if (size) {
      const items = docs
        .map((it) => {
          const { id, ...rest } = it.data();
          return {
            id: it.id,
            ...rest,
          };
        })
        .filter((it) => {
          return it.endDate >= today || it.startDate >= today;
        })
        .reduce(
          (acc, next) => ({
            ...acc,
            [next.id]: next,
          }),
          {},
        );
      dispatch(absenceListSuccess({ items }));
    } else {
      dispatch(absenceListFailure('No entries available for this party'));
    }
  });
};

export const unsubscribeFromAbsences = () => (dispatch) => {
  dispatch(absenceListUnsubscribe());
  api.unsubscribeListener(listUnsubscriber);
  listUnsubscriber = null;
};
