import React from 'react';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeToAdults, unsubscribeFromAdults, updateAdult } from 'actions';
import { useSubscription } from 'utils/useSubscription';
import { TYPE_STAFF, ROUTE_STAFFMEMBER_PROFILE } from 'constants/index';
import { PageFocusview } from 'components/layout';
import { FormAdultPersonalInformation } from 'components/form';
import { MODE_UPDATE } from '../../../constants/index';

const StaffMemberUpdate = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const { state } = location;
  const { referrer } = state;
  const completeUrl = ROUTE_STAFFMEMBER_PROFILE.replace(':id', id);

  const { items, size } = useSubscription({
    subscribeFn: subscribeToAdults,
    unsubscribeFn: unsubscribeFromAdults,
    selectorKey: 'adults',
  });

  const isLoadingData = !size;

  const { error, isRequesting, didRequestWithSuccess } = useSelector((s) => s.adult.update);

  if (didRequestWithSuccess && id) {
    return <Redirect to={completeUrl} />;
  }

  const record = size ? items[id] : {};

  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle="Edit Personal Information"
      closeButtonUrl={completeUrl}
      showCloseButton
    >
      {isLoadingData ? (
        <div className="loading" />
      ) : (
        <FormAdultPersonalInformation
          origin={referrer}
          record={record}
          type={TYPE_STAFF}
          mode={MODE_UPDATE}
          onSubmit={(values) => dispatch(updateAdult(id, values))}
          isSubmitting={isRequesting}
          error={error}
        />
      )}
    </PageFocusview>
  );
};

export default StaffMemberUpdate;
