import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/button-has-type */
class Button extends PureComponent {
  static propTypes = {
    /* Function to call when button is clicked */
    onClick: PropTypes.func,
    /* Overriding class name(s) */
    className: PropTypes.string,
    /* Type of button: button/submit */
    btnType: PropTypes.string,
    /* Boolean to disable/enable button */
    isDisabled: PropTypes.bool,
    /* Text, spans, child components, etc... */
    children: PropTypes.node,
  };

  static defaultProps = {
    onClick: null,
    isDisabled: false,
    btnType: 'button',
    className: 'btn',
    children: null,
  };

  render() {
    const { onClick, className, children, btnType, isDisabled } = this.props;
    const classNames = [className];
    if (isDisabled) classNames.push('button--disabled');
    return (
      <button className={classNames.join(' ')} onClick={onClick} type={btnType} disabled={isDisabled}>
        {children}
      </button>
    );
  }
}
/* eslint-enable */

export default Button;
