import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const ERROR_TEXT = 'You have to check at least one item.';

class CheckboxGroup extends PureComponent {
  /* eslint-disable react/forbid-prop-types */
  static propTypes = {
    id: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        hint: PropTypes.string,
        error: PropTypes.string,
      }).isRequired,
    ).isRequired,
    values: PropTypes.array,
    labelText: PropTypes.string,
    hintText: PropTypes.string,
    errorText: PropTypes.string,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func,
    labelClassName: PropTypes.string,
    noneOfAbove: PropTypes.bool,
    noneOfAboveText: PropTypes.string,
  };
  /* eslint-enable */

  static defaultProps = {
    values: [],
    onChange: null,
    labelClassName: 'label',
    labelText: '',
    hintText: '',
    errorText: '',
    isDisabled: false,
    isRequired: false,
    noneOfAbove: false,
    noneOfAboveText: 'None of the above',
  };

  constructor(props) {
    super(props);
    this.state = {
      values: [],
    };
  }

  componentDidMount() {
    const { values } = this.props;
    this.setState({
      values,
    });
  }

  handleOnChange = (e) => {
    const { onChange, noneOfAboveText } = this.props;
    const { value, checked } = e.target;
    this.setState(
      (prevState) => ({
        values: checked
          ? [...prevState.values.filter((val) => val !== noneOfAboveText), value]
          : prevState.values.filter((val) => !prevState.values.includes(val)),
      }),
      () => {
        const { values } = this.state;
        onChange(values);
      },
    );
  };

  noneOfAboveChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      this.setState({
        values: [value],
      });
    }
  };

  render() {
    const {
      id,
      list,
      labelClassName,
      labelText,
      hintText,
      errorText,
      isDisabled,
      isRequired,
      noneOfAbove,
      noneOfAboveText,
    } = this.props;
    const { values } = this.state;
    const classNames = ['form-group', 'form-group--checkbox'];
    if (isDisabled) classNames.push('form-group--disabled');
    if (isRequired) classNames.push('form-group--required');

    let error = errorText;
    if (isRequired && !values.length && !noneOfAbove) {
      error = ERROR_TEXT;
    }
    if (error) classNames.push('form-group--error');

    let inputProps = {};
    /* eslint-disable jsx-a11y/label-has-for */
    /* eslint-disable jsx-a11y/label-has-associated-control */
    const checkboxList = list.map((it, i) => {
      inputProps = {
        id: `${id}-${i + 1}`,
        name: id,
        value: it.value,
        type: 'checkbox',
        checked: values.includes(it.value),
        onChange: this.handleOnChange,
        ...(it.hint && { 'aria-describedby': `${id}-hint` }),
      };
      return (
        <div className="form-element form-element--checkbox" key={`${id}-${i + 1}`}>
          <input {...inputProps} />
          {it.label && (
            <label className={labelClassName} htmlFor={`${id}-${i + 1}`} id={`${id}-label-${i + 1}`}>
              {it.label}
            </label>
          )}
          {it.hint && (
            <span id={`${id}-hint-${i + 1}`} className="form-element-hint">
              {it.hint}
            </span>
          )}
          {it.error && <span className="form-element-error-msg">{it.error}</span>}
        </div>
      );
    });

    return (
      <fieldset className={classNames.join(' ')}>
        {labelText && (
          <legend className="label">
            {labelText}
            {isRequired && <span className="required">*</span>}
          </legend>
        )}
        {hintText && (
          <span id={`${id}-hint`} className="form-group-hint">
            {hintText}
          </span>
        )}
        {error && <span className="form-group--error-msg">{error}</span>}
        {!!list.length && checkboxList}
        {noneOfAbove && !!list.length && (
          <div className="form-element form-element--checkbox">
            <input
              id={`${id}-${list.length + 1}`}
              name={id}
              type="checkbox"
              value={noneOfAboveText}
              checked={values.length === 1 && values.includes(noneOfAboveText)}
              onChange={this.noneOfAboveChange}
            />
            <label className="label" htmlFor={`${id}-${list.length + 1}`}>
              <strong>{noneOfAboveText}</strong>
            </label>
          </div>
        )}
      </fieldset>
    );
    /* eslint-enable */
  }
}

export default CheckboxGroup;
