import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ActingSubmitButton } from 'components/buttons';
import { InputText, ClassroomSelect } from 'components/formElements';
import { Form, Error } from 'components/layout';
import { SingleDatePickerWrapper } from 'components/dates';
import { isEmpty, onFieldChange, onDateChange, validationChangeForm } from 'utils/index';
import config from 'conf';
import { MODE_CREATE, MODE_UPDATE, DATE_PICKER_OPEN_DIRECTION } from 'constants/index';

const getDefaultState = (record) => {
  return !isEmpty(record)
    ? record
    : {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        startDate: '',
        classroom: '',
      };
};

// @TODO: add prop for "save and continue vs save" (initial workflow vs just updating someone)

const FormChildPersonalInformation = (props) => {
  const { onSubmit, isSubmitting, error, record, origin, mode } = props;
  const [values, setValues] = useState(getDefaultState(record));
  const [copyValues] = useState({ ...values });
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const { firstName, lastName, dateOfBirth, startDate } = values;

  const dob = dateOfBirth ? moment(dateOfBirth, config.formats.momentDateSystem) : null;
  const sd = startDate ? moment(startDate, config.formats.momentDateSystem) : null;

  useEffect(() => {
    validationChangeForm(values, copyValues, setDisabledSubmit);
  }, [values]);

  const submitProps = {
    btnClassName: mode === MODE_CREATE ? 'button button--icon--proceed' : 'button',
    actionBtnClassName: 'button button--icon--loading',
    btnText: mode === MODE_CREATE ? 'Save and continue' : 'Save',
    actionText: 'Saving...',
    isActing: isSubmitting,
    isDisabled: disabledSubmit,
  };

  return (
    <Form onSubmit={() => onSubmit(values)}>
      {!isSubmitting && !!error && <Error error={error} className="form-error-msg" />}
      <InputText
        id="firstName"
        value={firstName}
        labelText="First Name"
        isRequired
        onChange={(e) => onFieldChange(values, setValues, e)}
      />
      <InputText
        id="lastName"
        value={lastName}
        labelText="Last Name"
        isRequired
        onChange={(e) => onFieldChange(values, setValues, e)}
      />
      <SingleDatePickerWrapper
        id="dateOfBirth"
        labelText="Date of Birth"
        hintText="Or expected date of birth if child is unborn"
        placeholder={null}
        onClose={(date) => onDateChange(values, setValues, 'dateOfBirth', date)}
        onChange={(date) => onDateChange(values, setValues, 'dateOfBirth', date)}
        initialDate={dob}
        displayFormat="MMM Do, Y"
        numberOfMonths={2}
        appendToBody={false}
        isRequired
        block
      />
      <SingleDatePickerWrapper
        id="startDate"
        labelText="Start Date"
        hintText="Or expected start date if child is unborn"
        placeholder={null}
        onClose={(date) => onDateChange(values, setValues, 'startDate', date)}
        onChange={(date) => onDateChange(values, setValues, 'startDate', date)}
        initialDate={sd}
        displayFormat="MMM Do, Y"
        numberOfMonths={2}
        openDirection={DATE_PICKER_OPEN_DIRECTION.Up}
        appendToBody={false}
        isRequired
        block
      />
      <ClassroomSelect
        id="classroom"
        labelText="Classroom"
        onChange={(e) => onFieldChange(values, setValues, e)}
        isRequired
      />
      <div className="form-actions">
        <Link className="button--text--cancel" to={origin}>
          Cancel
        </Link>
        <ActingSubmitButton {...submitProps} />
      </div>
    </Form>
  );
};

FormChildPersonalInformation.propTypes = {
  origin: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf([MODE_UPDATE, MODE_CREATE]).isRequired,
  error: PropTypes.string,
  record: PropTypes.shape(),
};

FormChildPersonalInformation.defaultProps = {
  error: null,
  record: {},
};

export default FormChildPersonalInformation;
