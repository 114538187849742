import { combineReducers } from 'redux';
import ui from './ui';
import auth from './auth';
import student from './student';
import students from './students';
import adult from './adult';
import adults from './adults';
import staffMember from './staffMember';
import relationship from './relationship';
import timesheets from './timesheets';
import timesheet from './timesheet';
import schedules from './schedules';
import staffSchedules from './staffSchedules';
import schedule from './schedule';
import tenants from './tenants';
import tenant from './tenant';
import sms from './sms';
import centerSchedule from './centerSchedule';
import ratios from './ratios';
import classroom from './classroom';
import absence from './absence';
import absences from './absences';
import contact from './contact';

export default combineReducers({
  ui,
  auth,
  student,
  students,
  staffMember,
  adult,
  adults,
  schedules,
  staffSchedules,
  schedule,
  timesheets,
  timesheet,
  tenants,
  tenant,
  relationship,
  sms,
  centerSchedule,
  ratios,
  classroom,
  absence,
  absences,
  contact,
});
