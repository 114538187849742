export const ROUTE_HOME = '/';

// Auth and Tenant Selection
export const ROUTE_AUTH = '/auth/';
export const ROUTE_CHOOSE_TENANT = '/choose-center/';

// Admin routes
export const ROUTE_ADMIN = '/admin/';
export const ROUTE_ADMIN_INFO = '/admin/info/';
export const ROUTE_ADMIN_INFO_UPDATE = '/admin/info/update/';
export const ROUTE_ADMIN_SCHEDULE = '/admin/schedule/';
export const ROUTE_ADMIN_SCHEDULE_UPDATE = '/admin/schedule/update/';
export const ROUTE_ADMIN_CLASSROOMS = '/admin/classrooms/';
export const ROUTE_ADMIN_CLASSROOMS_ADD = '/admin/classrooms/add/';
export const ROUTE_ADMIN_CLASSROOMS_UPDATE = '/admin/classrooms/:classroomId/update/';
export const ROUTE_ADMIN_NOTIFY = '/admin/notify/';

// Attendance Tracking (Timesheets)
export const ROUTE_TIMESHEET = '/timesheets/';

// Create/Update (Adult, StaffMember, Student)
export const ROUTE_CREATE = '/add-party/:type/';
export const ROUTE_UPDATE = '/update-party/:type/:id/';

// Relationship
export const ROUTE_RELATIONSHIP = '/relationship';
export const ROUTE_RELATIONSHIP_LIST = '/relationship/list/:type/:id/';
export const ROUTE_RELATIONSHIP_SELECT_TYPE = '/relationship/select/:type/:id/';
export const ROUTE_RELATIONSHIP_ADD = '/relationship/add/:typeA/:typeB/:idA/';
export const ROUTE_RELATIONSHIP_UPDATE = '/relationship/update/:typeA/:typeB/:idA/:idB/';

// Student
export const ROUTE_STUDENT_LIST = ROUTE_HOME;
export const ROUTE_STUDENT_VIEW = '/student/';
export const ROUTE_STUDENT_CREATE = '/student/add/';
export const ROUTE_STUDENT_CREATE_ID = '/student/add/:id/';
export const ROUTE_STUDENT_PROFILE = '/student/:id/';
export const ROUTE_STUDENT_UPDATE = '/student/:id/update/';
export const ROUTE_STUDENT_SCHEDULE = '/student/:id/schedule/';
export const ROUTE_STUDENT_SCHEDULE_ADD = '/student/:id/schedule/add/';
export const ROUTE_STUDENT_SCHEDULE_UPDATE = '/student/:id/schedule/update/:scheduleId/';
export const ROUTE_STUDENT_PLANNED_ABSENCE_ADD = '/student/:id/planned-absence/add/';
export const ROUTE_STUDENT_PLANNED_ABSENCE_UPDATE = '/student/:id/planned-absence/:absenceId/update/';
export const ROUTE_STUDENT_EMERGENCY_CONTACTS = '/student/:id/emergency-contacts/';
export const ROUTE_STUDENT_EMERGENCY_CONTACT_SELECT = '/student/:id/emergency-contact/select/';
export const ROUTE_STUDENT_EMERGENCY_CONTACT_ADD = '/student/:id/emergency-contact/add/';
export const ROUTE_STUDENT_EMERGENCY_CONTACT_UPDATE = '/student/:id/emergency-contact/:entryId/update/';

// StaffMember
export const ROUTE_STAFF_LIST = '/staff-members/';
export const ROUTE_STAFFMEMBER_VIEW = '/staff-member/';
export const ROUTE_STAFFMEMBER_CREATE = '/staff-member/add/';
export const ROUTE_STAFFMEMBER_CREATE_ID = '/staff-member/add/:id/';
export const ROUTE_STAFFMEMBER_PROFILE = '/staff-member/:id/';
export const ROUTE_STAFFMEMBER_UPDATE = '/staff-member/:id/update/';
export const ROUTE_STAFFMEMBER_SCHEDULE_ADD = '/staff-member/:id/schedule/add/';
export const ROUTE_STAFFMEMBER_SCHEDULE_UPDATE = '/staff-member/:id/schedule/update/:scheduleId/';
export const ROUTE_STAFFMEMBER_PLANNED_ABSENCE_ADD = '/staff-member/:id/planned-absence/add/';
export const ROUTE_STAFFMEMBER_PLANNED_ABSENCE_UPDATE = '/staff-member/:id/planned-absence/:absenceId/update/';

// Adult
export const ROUTE_ADULT_LIST = '/adults/';
export const ROUTE_ADULT_VIEW = '/adult/';
export const ROUTE_ADULT_CREATE = '/adult/add/';
export const ROUTE_ADULT_CREATE_ID = '/adult/add/:id';
export const ROUTE_ADULT_PROFILE = '/adult/:id/';
export const ROUTE_ADULT_UPDATE = '/adult/:id/update/';
