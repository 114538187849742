import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from 'components/table';
import { getFormattedTime, stringToMoment } from 'utils/index';

const TableRowScheduleData = (props) => {
  const { Day, TimeIn, TimeOut, Classroom } = props;
  return (
    <TableRow>
      <li className="tc--day">{Day}</li>
      <li className="tc--time-in">{getFormattedTime(stringToMoment(TimeIn, 'hh:mm'))}</li>
      <li className="tc--time-out">{getFormattedTime(stringToMoment(TimeOut, 'hh:mm'))}</li>
      <li className="tc--classroom">{Classroom || 'N/A'}</li>
    </TableRow>
  );
};
TableRowScheduleData.propTypes = {
  Day: PropTypes.string.isRequired,
  TimeIn: PropTypes.string.isRequired,
  TimeOut: PropTypes.string.isRequired,
  Classroom: PropTypes.string.isRequired,
};

export default TableRowScheduleData;
