import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getFormattedPin } from 'utils/index';
import { ROUTE_ADULT_VIEW } from 'constants/index';
import { TableRow } from '.';

const TableRowParentGuardian = (props) => {
  const { data } = props;
  const { id, fullName, pin, isLocked } = data;
  const hasData = Object.keys(data).length;

  return hasData ? (
    <TableRow key={id}>
      <li className="tc--name">{fullName}</li>
      <li className="tc--pin">{getFormattedPin(pin)}</li>
      <li className={isLocked ? 'tc--locked locked' : 'tc--locked'}>{isLocked ? 'Yes' : 'No'}</li>
      <li className="tc--view">
        <Link className="button--text" to={`${ROUTE_ADULT_VIEW}${id}/`}>
          View
        </Link>
      </li>
    </TableRow>
  ) : null;
};

TableRowParentGuardian.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
    pin: PropTypes.string,
    isLocked: PropTypes.bool,
  }),
};

TableRowParentGuardian.defaultProps = {
  data: {},
};

export default TableRowParentGuardian;
