import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { updateTimesheet } from 'actions';
import { Modal } from 'components/modal';
import { UpdateTimesheetEntryForm } from 'components/form';
import { insertIf, isString, isEmpty } from 'utils/index';
import config from 'conf/index';

class UpdateTimesheetEntry extends Component {
  static propTypes = {
    doUpdateRecord: PropTypes.func.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    didRequestWithSuccess: PropTypes.bool.isRequired,
    isShowing: PropTypes.bool.isRequired,
    author: PropTypes.string,
    recordId: PropTypes.string,
    record: PropTypes.shape(),
    requestError: PropTypes.string,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    author: null,
    recordId: null,
    record: {},
    requestError: null,
    onClose: null,
  };

  componentDidUpdate(prevProps) {
    const { isShowing: willShow, didRequestWithSuccess } = this.props;
    const { isShowing } = prevProps;
    if (didRequestWithSuccess && willShow) {
      this.modal.closeModal();
      return;
    }
    if (isShowing === willShow) return;
    if (!isShowing && willShow) {
      this.modal.openModal();
    } else if (isShowing && !willShow) {
      this.modal.closeModal();
    }
  }

  onCancel = () => {
    this.modal.closeModal();
  };

  onConfirm = (data) => {
    const { doUpdateRecord, recordId, record, author } = this.props;
    if (recordId && data) {
      const { action, actionDetails, time, date, reason, isManualEntry } = data;
      const { dateFmt, fullName, totalHours, id, m, ...rest } = record;
      // TODO standardize storing of dateTime objects
      const [month, day, year] = date.split('/');
      const [hour, minute] = time.split(':');
      const dt = DateTime.fromObject({
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        day: parseInt(day, 10),
        hour: parseInt(hour, 10),
        minute: parseInt(minute, 10),
        zone: config.timeZone,
      });
      doUpdateRecord(recordId, {
        ...rest,
        time: dt.toFormat(config.formats.time),
        timeStamp: dt.toMillis(),
        dateTime: dt.toUTC().toISO(),
        actionDetails: isString(actionDetails) && !isEmpty(actionDetails) ? actionDetails : null,
        reason: isString(reason) && !isEmpty(reason) ? reason : null,
        updatedAt: DateTime.local().toUTC().toISO(),
        updatedBy: author,
        isManualEntry,
        ...insertIf(action, 'action', action),
      });
    } else {
      this.modal.closeModal();
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
    this.form.reset();
  };

  render() {
    const { isShowing, isRequesting, requestError, record } = this.props;
    return (
      <Modal
        isShowing={isShowing}
        modalTitle="Update Timesheet Entry"
        onClose={this.onClose}
        ref={(ref) => {
          this.modal = ref;
        }}
      >
        <UpdateTimesheetEntryForm
          id="UpdateTimesheetEntry"
          cancelForm={this.onCancel}
          submitForm={this.onConfirm}
          isSubmitting={isRequesting}
          error={requestError}
          data={record}
          ref={(ref) => {
            this.form = ref;
          }}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  doUpdateRecord: updateTimesheet,
};

const mapStateToProps = ({ auth, timesheet }) => {
  const { email } = auth;
  const { update } = timesheet;
  const { isRequesting, didRequestWithSuccess, requestError } = update;
  return {
    author: email,
    isRequesting,
    didRequestWithSuccess,
    requestError,
  };
};

export { UpdateTimesheetEntry };
export default connect(mapStateToProps, mapDispatchToProps)(UpdateTimesheetEntry);
