import { createReducer } from 'utils/index';
import { LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE } from 'constants/index';

const API_ID = 'SCHEDULE_STAFF_';

const initialListState = {
  items: {},
  fetchError: null,
  isFetching: false,
};

export default createReducer(initialListState, {
  [`${API_ID}${LIST_REQUEST}`]: () => ({
    ...initialListState,
    isFetching: true,
  }),
  [`${API_ID}${LIST_SUCCESS}`]: (state, { items }) => ({
    ...state,
    items,
    isFetching: false,
  }),
  [`${API_ID}${LIST_FAILURE}`]: (_state, { message }) => ({
    items: {},
    fetchError: message,
    isFetching: false,
  }),
});
