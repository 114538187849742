import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import { updateTimesheet } from 'actions';
import { Modal } from 'components/modal';
import { IgnoreTimesheetEntryForm } from 'components/form';

class IgnoreTimesheetEntry extends Component {
  static propTypes = {
    doUpdateRecord: PropTypes.func.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    didRequestWithSuccess: PropTypes.bool.isRequired,
    isShowing: PropTypes.bool.isRequired,
    author: PropTypes.string,
    recordId: PropTypes.string,
    record: PropTypes.shape(),
    requestError: PropTypes.string,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    author: null,
    recordId: null,
    record: {},
    requestError: null,
    onClose: null,
  };

  componentDidUpdate(prevProps) {
    const { isShowing: willShow, didRequestWithSuccess } = this.props;
    const { isShowing } = prevProps;
    if (didRequestWithSuccess && willShow) {
      this.modal.closeModal();
      return;
    }
    if (isShowing === willShow) return;
    if (!isShowing && willShow) {
      this.modal.openModal();
    } else if (isShowing && !willShow) {
      this.modal.closeModal();
    }
  }

  onCancel = () => {
    this.modal.closeModal();
  };

  onConfirm = (data) => {
    const { doUpdateRecord, recordId, record, author } = this.props;
    if (recordId && data) {
      const { isIgnored, isIgnoredReason } = data;
      const { dateFmt, fullName, totalHours, id, m, ...rest } = record;
      doUpdateRecord(recordId, {
        ...rest,
        isIgnored,
        isIgnoredReason,
        updatedAt: DateTime.local().toUTC().toISO(),
        updatedBy: author,
      });
    } else {
      this.modal.closeModal();
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
    this.form.reset();
  };

  render() {
    const { isShowing, isRequesting, requestError, record } = this.props;
    return (
      <Modal
        isShowing={isShowing}
        modalTitle="Ignore Timesheet Entry"
        onClose={this.onClose}
        ref={(ref) => {
          this.modal = ref;
        }}
      >
        <IgnoreTimesheetEntryForm
          id="IgnoreTimesheetEntry"
          cancelForm={this.onCancel}
          submitForm={this.onConfirm}
          isSubmitting={isRequesting}
          error={requestError}
          data={record}
          ref={(ref) => {
            this.form = ref;
          }}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  doUpdateRecord: updateTimesheet,
};

const mapStateToProps = ({ auth, timesheet }) => {
  const { email } = auth;
  const { update } = timesheet;
  const { isRequesting, didRequestWithSuccess, requestError } = update;
  return {
    author: email,
    isRequesting,
    didRequestWithSuccess,
    requestError,
  };
};

export { IgnoreTimesheetEntry };
export default connect(mapStateToProps, mapDispatchToProps)(IgnoreTimesheetEntry);
