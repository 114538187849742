import { combineReducers } from 'redux';
import { createAPIReducer, createReducer } from 'utils/index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const initialGetState = {
  isRequesting: false,
  data: null,
  requestError: null,
};
const initialAPIState = {
  isRequesting: false,
  didRequestWithSuccess: false,
  requestError: null,
  id: null,
};

const staffMemberGet = createReducer(initialGetState, {
  [`STAFF_GET_${API_REQUEST}`]: () => ({
    isRequesting: true,
    requestError: null,
  }),
  [`STAFF_GET_${API_SUCCESS}`]: (state, data) => ({
    isRequesting: false,
    data,
    requestError: null,
  }),
  [`STAFF_GET_${API_FAILURE}`]: (state, { message }) => ({
    isRequesting: false,
    data: null,
    requestError: message,
  }),
  [`STAFF_GET_${API_RESET}`]: () => ({
    isRequesting: false,
    data: null,
    requestError: null,
  }),
});

export default combineReducers({
  get: staffMemberGet,
  add: createAPIReducer('STAFF_ADD_', initialAPIState),
  update: createAPIReducer('STAFF_UPDATE_', initialAPIState),
  del: createAPIReducer('STAFF_DELETE_', initialAPIState),
});
