import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { PageFocusview } from 'components/layout';
import { FormLinkParty } from 'components/form';
import { ROUTE_RELATIONSHIP_SELECT_TYPE, TYPE_ADULT, WORKFLOW_CREATE, ROUTE_RELATIONSHIP_LIST } from 'constants/index';
import { getRecordTypeFromPath } from 'utils';

const getCancelUrl = (id, pathType) => `/${pathType}/${id}/related-parties`;

const getCompleteUrl = (id, pathType, mode) => {
  if (mode && mode === WORKFLOW_CREATE) return ROUTE_RELATIONSHIP_LIST.replace(':type', pathType).replace(':id', id);
  return getCancelUrl(id, pathType);
};

const getBackUrl = (id, pathType) => ROUTE_RELATIONSHIP_SELECT_TYPE.replace(':type', pathType).replace(':id', id);

export const RelationshipAdd = () => {
  const location = useLocation();

  const { typeA, typeB, idA: id } = useParams();

  const recordTypeA = getRecordTypeFromPath(typeA);
  const recordTypeB = getRecordTypeFromPath(typeB);

  const { state } = location || {};
  const { mode } = state || null;

  const closeUrl = getCancelUrl(id, typeA);
  const backUrl = getBackUrl(id, typeA);

  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle={`Link ${recordTypeB === TYPE_ADULT ? 'Person' : 'Child'}`}
      showCloseButton
      showBackButton
      closeButtonUrl={closeUrl}
      backButtonUrl={backUrl}
      backButtonState={{
        mode,
      }}
    >
      <FormLinkParty
        referrerId={id}
        referrerType={recordTypeA}
        linkType={recordTypeB}
        completeUrl={getCompleteUrl(id, typeA, mode)}
        mode={mode}
      />
    </PageFocusview>
  );
};
