import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { login } from 'actions';
import { Header, Wrapper } from 'components/layout';
import { LoginForm } from 'components/form';

class Auth extends PureComponent {
  static propTypes = {
    doLogin: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isAuthenticating: PropTypes.bool.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    error: '',
  };

  render() {
    const { doLogin, isAuthenticated, isAuthenticating, error } = this.props;
    return isAuthenticated ? (
      <Redirect to="/" />
    ) : (
      <>
        <Header />
        <Wrapper>
          <LoginForm
            login={doLogin}
            isAuthenticated={isAuthenticated}
            isAuthenticating={isAuthenticating}
            error={error}
          />
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { isAuthenticated, isAuthenticating, error } = auth;
  return {
    isAuthenticating,
    isAuthenticated,
    error,
  };
};

const mapDispatchToProps = {
  doLogin: login,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
