import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class TableHeader extends PureComponent {
  static propTypes = {
    headerLabels: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        className: PropTypes.string.isRequired,
      }),
    ).isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: 'table-header',
  };

  constructor(props) {
    super(props);
    const { headerLabels } = this.props;
    this.headerCells = headerLabels.map((it) => (
      <li className={`tc--${it.className}`} key={it.className}>
        {it.label}
      </li>
    ));
  }

  render() {
    const { className } = this.props;
    return <ul className={className}>{this.headerCells}</ul>;
  }
}

export default TableHeader;
