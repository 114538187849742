import { api } from 'index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';
import { getContacts } from './contactList';

const API_ID = 'CONTACT_DELETE_';

const contactDeleteRequest = () => ({
  type: `${API_ID}${API_REQUEST}`,
});

const contactDeleteSuccess = (memberId) => ({
  type: `${API_ID}${API_SUCCESS}`,
  payload: {
    id: memberId,
  },
});

const contactDeleteReset = () => ({
  type: `${API_ID}${API_RESET}`,
});

const contactDeleteFailure = (message) => ({
  type: `${API_ID}${API_FAILURE}`,
  payload: {
    message,
  },
});

export const deleteContact = (id, memberId) => (dispatch) => {
  dispatch(contactDeleteRequest());
  api
    .updateContact(id, {
      isArchived: true,
    })
    .then(() => {
      dispatch(contactDeleteSuccess(memberId));
      dispatch(getContacts(memberId));
    })
    .then(() => dispatch(contactDeleteReset()))
    .catch((err) => {
      dispatch(contactDeleteFailure(err));
    });
};
