import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { ClassroomForm } from 'components/form';
import { PageFocusview, Error } from 'components/layout';
import { ROUTE_ADMIN_CLASSROOMS } from 'constants/index';
import { getClassrooms, updateClassroom } from 'actions';

const getClassroom = (classroomId, classroomList) => {
  if (!classroomId) {
    return {
      name: '',
      nickName: '',
      minMonths: 0,
      maxMonths: 0,
      ratio: 0,
      maxSize: 0,
    };
  }
  return classroomList[classroomId];
};

const ClassroomUpdate = () => {
  const { classroomId } = useParams();
  const dispatch = useDispatch();
  const classrooms = useSelector((state) => state.classroom.list.items);
  const { fetchError, isFetching } = useSelector((state) => state.classroom.list);
  const { didRequestWithSuccess, isRequesting, requestError } = useSelector((state) => state.classroom.update);
  useSelector((state) => console.log(state.classroom.update));
  const classroom = getClassroom(classroomId, classrooms);
  const size = Object.keys(classrooms).length;

  useEffect(() => {
    if (!size && !isFetching) {
      dispatch(getClassrooms());
    }
  }, [size, isFetching]);

  if (didRequestWithSuccess) {
    return <Redirect to={ROUTE_ADMIN_CLASSROOMS} />;
  }
  return (
    <PageFocusview
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      pageTitle="Update classroom"
      closeButtonUrl={ROUTE_ADMIN_CLASSROOMS}
      showCloseButton
    >
      {!!requestError && <Error error={requestError} />}
      {!!fetchError && <Error error={fetchError} />}
      {!isFetching && !size && <div className="loading" />}
      {!isFetching && !!size && (
        <ClassroomForm
          submitFn={(values) => {
            const { nickName } = values;
            dispatch(updateClassroom(classroomId, { nickName }));
          }}
          isSubmitting={isRequesting}
          classroom={classroom}
          cancelUrl={ROUTE_ADMIN_CLASSROOMS}
        />
      )}
    </PageFocusview>
  );
};

export default ClassroomUpdate;
