import { combineReducers } from 'redux';
import { createAPIReducer, createReducer } from 'utils/index';
import { API_REQUEST, API_SUCCESS, API_FAILURE, API_RESET } from 'constants/index';

const initialGetState = {
  isRequesting: false,
  items: {},
  requestError: null,
};
const initialAPIState = {
  isRequesting: false,
  didRequestWithSuccess: false,
  requestError: null,
  id: null,
};

const studentGet = createReducer(initialGetState, {
  [`STUDENT_GET_${API_REQUEST}`]: (state) => ({
    ...state,
    isRequesting: true,
    requestError: null,
  }),
  [`STUDENT_GET_${API_SUCCESS}`]: (state, { id, record }) => ({
    isRequesting: false,
    items: {
      ...state.items,
      [id]: record,
    },
    requestError: null,
  }),
  [`STUDENT_GET_${API_FAILURE}`]: (state, { message }) => ({
    ...state,
    isRequesting: false,
    requestError: message,
  }),
  [`STUDENT_GET_${API_RESET}`]: () => ({
    ...initialGetState,
  }),
});

export default combineReducers({
  get: studentGet,
  add: createAPIReducer('STUDENT_ADD_', initialAPIState),
  update: createAPIReducer('STUDENT_UPDATE_', initialAPIState),
  del: createAPIReducer('STUDENT_DELETE_', initialAPIState),
});
