import { combineReducers } from 'redux';
import { createAPIReducer } from 'utils/index';

const API_ID = 'ABSENCE_';

const initialAPIState = {
  isRequesting: false,
  didRequestWithSuccess: false,
  requestError: null,
  id: null,
};

export default combineReducers({
  add: createAPIReducer(`${API_ID}ADD_`, initialAPIState),
  update: createAPIReducer(`${API_ID}UPDATE_`, initialAPIState),
  del: createAPIReducer(`${API_ID}DELETE_`, initialAPIState),
});
