import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  StaffMemberView,
  ScheduleAdd,
  ScheduleUpdate,
  PlannedAbsenceAdd,
  PlannedAbsenceUpdate,
} from 'components/memberProfiles';

import {
  ROUTE_STAFFMEMBER_PROFILE,
  TYPE_STAFF,
  ROUTE_STAFFMEMBER_SCHEDULE_ADD,
  ROUTE_STAFFMEMBER_PLANNED_ABSENCE_ADD,
  ROUTE_STAFFMEMBER_SCHEDULE_UPDATE,
  ROUTE_STAFFMEMBER_PLANNED_ABSENCE_UPDATE,
} from 'constants/index';

export const StaffMember = () => {
  window.scrollTo(0, 0);
  return (
    <Switch>
      <Route exact path={ROUTE_STAFFMEMBER_PLANNED_ABSENCE_UPDATE}>
        <PlannedAbsenceUpdate originType={TYPE_STAFF} />
      </Route>
      <Route exact path={ROUTE_STAFFMEMBER_PLANNED_ABSENCE_ADD}>
        <PlannedAbsenceAdd originType={TYPE_STAFF} />
      </Route>
      <Route exact path={ROUTE_STAFFMEMBER_SCHEDULE_UPDATE}>
        <ScheduleUpdate originType={TYPE_STAFF} />
      </Route>
      <Route exact path={ROUTE_STAFFMEMBER_SCHEDULE_ADD}>
        <ScheduleAdd originType={TYPE_STAFF} />
      </Route>
      <Route path={ROUTE_STAFFMEMBER_PROFILE}>
        <StaffMemberView originType={TYPE_STAFF} />
      </Route>
    </Switch>
  );
};
