import { createReducer } from 'utils/index';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  CHANGE_TENANT,
} from 'constants/index';

const initialState = {
  userId: null,
  fullName: null,
  email: null,
  selectedTenant: null,
  userTenants: [],
  isAuthenticating: false,
  isAuthenticated: false,
  error: null,
};

export default createReducer(initialState, {
  [LOGIN_REQUEST]: (state) => ({
    ...state,
    error: null,
    isAuthenticating: true,
    isAuthenticated: false,
  }),
  [LOGIN_SUCCESS]: (state, payload) => {
    const { userId, email, selectedTenant, fullName, userTenants } = payload;
    return {
      ...state,
      fullName,
      userId,
      email,
      userTenants: userTenants || [],
      selectedTenant,
      error: null,
      isAuthenticating: false,
      isAuthenticated: true,
    };
  },
  [LOGIN_FAILURE]: (state, { message }) => ({
    ...state,
    error: message,
    isAuthenticating: false,
    isAuthenticated: false,
  }),
  [LOGOUT_REQUEST]: () => ({
    ...initialState,
  }),
  [LOGOUT_SUCCESS]: () => ({
    ...initialState,
  }),
  [CHANGE_TENANT]: (state, { selectedTenant }) => ({
    ...state,
    selectedTenant,
  }),
});
