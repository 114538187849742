import { createReducer } from 'utils/index';
import { RATIO_LIST_REQUEST, RATIO_LIST_SUCCESS, RATIO_LIST_FAILURE } from 'constants/index';

const initialState = {
  items: {},
  fetchError: null,
  isFetching: false,
};

export default createReducer(initialState, {
  [RATIO_LIST_REQUEST]: () => ({
    ...initialState,
    isFetching: true,
  }),
  [RATIO_LIST_SUCCESS]: (state, { items }) => ({
    ...state,
    items,
    isFetching: false,
  }),
  [RATIO_LIST_FAILURE]: (_state, { message }) => ({
    items: {},
    fetchError: message,
    isFetching: false,
  }),
});
