import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DropMenu from 'components/dropmenu/DropMenu';
import { Button } from 'components/buttons';
import { logout, changeTenant, toggleMenu, getTenants } from 'actions';

class NavUser extends Component {
  static propTypes = {
    doGetTenants: PropTypes.func.isRequired,
    doLogout: PropTypes.func.isRequired,
    doChangeTenant: PropTypes.func.isRequired,
    doToggleMenu: PropTypes.func.isRequired,
    user: PropTypes.string.isRequired,
    selectedTenant: PropTypes.string.isRequired,
    userNavState: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    tenants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        refId: PropTypes.string,
      }),
    ).isRequired,
    userTenants: PropTypes.arrayOf(PropTypes.string).isRequired,
    fullName: PropTypes.string,
  };

  static defaultProps = {
    fullName: null,
  };

  componentDidMount() {
    const { tenants, doGetTenants, userTenants } = this.props;
    const isAdmin = userTenants.includes('*');
    if (!tenants.length) doGetTenants(userTenants, isAdmin);
  }

  componentDidUpdate(prevProps) {
    const { userNavState } = this.props;
    const { userNavState: oldState } = prevProps;
    if (oldState !== userNavState) {
      if (userNavState) {
        this.navUserMenu.open();
      } else {
        this.navUserMenu.close();
      }
    }
  }

  onTenantNavClick = (newTenantId) => {
    const { doChangeTenant, selectedTenant, doToggleMenu } = this.props;
    doToggleMenu('USER', false);
    if (selectedTenant !== newTenantId) {
      doChangeTenant(newTenantId);
    }
  };

  onLogoutNavClick = () => {
    const { doLogout, doToggleMenu } = this.props;
    doToggleMenu('USER', false);
    doLogout();
  };

  getTenantList = (tenantList, selectedTenant, userTenants) => {
    const logOutItem = [
      <li key="logout">
        <Button className="btn--logout" onClick={this.onLogoutNavClick}>
          Log Out
        </Button>
      </li>,
    ];
    if (!userTenants.length) return logOutItem;
    return (
      tenantList
        // filter out tenants user should not have access to
        // note: actual security is server-side. This just prevents a user
        // from selecting a tenant for which they do not have access
        .filter((it) => userTenants.includes('*') || userTenants.includes(it.id))
        .map((it) => {
          // const tenant = tenantList[it];
          const isActive = it.id === selectedTenant;
          return (
            <li key={it.refId}>
              <Button
                className={`btn--center-select${isActive ? ' active' : ''}`}
                onClick={isActive ? null : () => this.onTenantNavClick(it.id)}
              >
                {it.name}
              </Button>
            </li>
          );
        })
        .concat(logOutItem)
    );
  };

  render() {
    const { user, fullName, selectedTenant, tenants, userTenants, doToggleMenu, isFetching } = this.props;
    const currentTenant = !tenants.length ? selectedTenant : tenants.find((it) => it.id === selectedTenant).name;
    const btnContent = (
      <>
        <span className="name--user">{fullName || user}</span>
        <span className="name--center">{currentTenant}</span>
      </>
    );
    const menuItems = isFetching ? [] : this.getTenantList(tenants, selectedTenant, userTenants);
    return (
      <div className="nav--user">
        <DropMenu
          onOpen={() => doToggleMenu('USER', true)}
          onClose={() => doToggleMenu('USER', false)}
          btnClassName="nav--user-info"
          btnContent={btnContent}
          menuChildren={<ul>{menuItems}</ul>}
          menuClassName="nav--user--secondary"
          ref={(ref) => {
            this.navUserMenu = ref;
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ auth, tenants, ui }) => {
  const { email, selectedTenant, fullName, userTenants } = auth;
  const { items, isFetching } = tenants;
  const { menus } = ui;
  const { USER } = menus;
  return {
    user: email,
    fullName,
    selectedTenant,
    userTenants,
    userNavState: USER.isOpen,
    isFetching,
    tenants: items,
  };
};

const mapDispatchToProps = {
  doLogout: logout,
  doChangeTenant: changeTenant,
  doToggleMenu: toggleMenu,
  doGetTenants: getTenants,
};

export { NavUser };
export default connect(mapStateToProps, mapDispatchToProps)(NavUser);
