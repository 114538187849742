import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

export const useSubscription = (config, autoUnsubscribe = false, params = []) => {
  const dispatch = useDispatch();
  const { subscribeFn, unsubscribeFn, selectorKey } = config;
  const { items, keys, size, fetchError, shouldResubscribe } = useSelector((state) => {
    const { items: i, fetchError: fE, shouldResubscribe: sR } = state[selectorKey];
    const s = Object.keys(i).length;
    return {
      items: i,
      size: s,
      keys: Object.keys(i),
      fetchError: fE,
      shouldResubscribe: sR,
    };
  });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (shouldResubscribe) {
      dispatch(unsubscribeFn());
      dispatch(subscribeFn(...params));
    } else if (size === 0) {
      dispatch(subscribeFn(...params));
    }
    if (autoUnsubscribe) {
      return () => {
        dispatch(unsubscribeFn());
      };
    }
  }, []);

  return {
    items,
    keys,
    size,
    fetchError,
  };
};
