import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ListItemRelatedParty extends PureComponent {
  static propTypes = {
    fullName: PropTypes.string.isRequired,
    rel: PropTypes.string.isRequired,
  };

  render() {
    const { fullName, rel } = this.props;
    return (
      <li>
        <h5>{fullName}</h5>
        <span className="relationship">{rel}</span>
      </li>
    );
  }
}

export default ListItemRelatedParty;
