import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { round2Precision } from 'utils';
import { CHECKED_OUT } from 'constants/index';
import config from 'conf';
// import tenants from '../../__data/tenants';

const contentType = 'text/csv; charset=UTF-8';
const fileDateFormat = 'MM-dd-yyyy';
const TS_HEADERS = 'Name,Date,Time,Action,Details,Manual entry or Change,Reason for Change,Hours,Flagged,Total Hours';
const EX_HEADERS = 'Name,Date,Time,Action,Details,Reason is Ignored,Total Ignored Entries';

const mapExDay = (day) => {
  const { dateFmt, action, actionLabel, actionDetails, isIgnoredReason, time } = day;
  return `,${dateFmt},${time},${actionLabel || action},${actionDetails ? actionDetails.replace(',', ';') : 'N/A'},${
    isIgnoredReason ? isIgnoredReason.replace(',', ';') : 'N/A'
  },`;
};

const mapExceptionEntries = (item) => {
  const { days, count, fullName } = item;
  return [`${fullName.replace(',', ';')},,,,,,${count}`].concat(days.map(mapExDay)).join('\n');
};

const mapDay = (day) => {
  const { date, isFlagged, totalHours, entries } = day;
  return entries
    .map((e) => {
      const { action, actionLabel, actionDetails, reason, isManualEntry, time, m } = e;
      return `,${date},${time || m.format('HH:mm A')},${actionLabel || action},${
        actionDetails ? actionDetails.replace(',', ';') : 'N/A'
      },${isManualEntry ? 'Yes' : 'No'},${reason ? reason.replace(',', ';') : 'N/A'},${
        action === CHECKED_OUT ? totalHours : ''
      },${isFlagged ? 'Yes' : 'No'},`;
    })
    .join('\n');
};

const mapEntries = (item) => {
  const { days, totalHours, fullName } = item;
  const hours = round2Precision(totalHours, 2);
  return [`${fullName.replace(',', ';')},,,,,,,,,${hours}`]
    .concat(days.filter((d) => d.entries.filter((e) => !!e.id).length).map(mapDay))
    .join('\n');
};

export const downloadCSVTimesheetReport = (items, tableId, searchParams, tenants) => (dispatch, getState) => {
  const {
    auth: { selectedTenant },
  } = getState();
  const { startDate, endDate, person, type, isSubsidized } = searchParams;
  const now = DateTime.local().setZone(config.timeZone);
  const start = DateTime.fromFormat(startDate, config.formats.dateSystem);
  const end = DateTime.fromFormat(endDate, config.formats.dateSystem);
  const tenant = tenants.find((it) => it.id === selectedTenant);
  const reportType = tableId === 'MAIN' ? `${type} Timesheets` : `${type} Exceptions`;
  const csv = [
    tableId === 'MAIN' ? TS_HEADERS : EX_HEADERS,
    '',
    tenant.name,
    '',
    `Report parameters:`,
    `Start: ${start.toFormat(config.formats.date)}\nEnd: ${end.toFormat(
      config.formats.date,
    )}\nType: ${reportType}\nPerson: ${person || 'N/A'}\nSubsidized: ${isSubsidized ? 'Yes' : 'No'}`,
    '',
    `Downloaded on ${now.toFormat(config.formats.date)} at ${now.toFormat(config.formats.time)}`,
    '',
  ];
  const results = csv.concat(items.map(tableId === 'MAIN' ? mapEntries : mapExceptionEntries)).join('\n');
  const fileName = `${tenant.name}-${reportType}-Report--${start.toFormat(fileDateFormat)}-${end.toFormat(
    fileDateFormat,
  )}`;
  saveAs(new Blob([results], { type: contentType }), fileName);
};
