import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { TableRow } from 'components/table';
import { Link } from 'react-router-dom';
import { Button } from 'components/buttons';
import { useModal } from 'utils/useModal';
import { isEmpty } from 'utils';
import { ConfirmModal } from 'components/modal';
import { updateClassroom } from 'actions';
import { TYPE_STUDENT, ROUTE_ADMIN_CLASSROOMS, DAYS_OF_WEEK } from 'constants/index';
import { ROUTE_STAFFMEMBER_PROFILE, ROUTE_STUDENT_PROFILE } from 'constants/routes';

function filterMembers(scheduleList, classroomId) {
  const days = Object.values(DAYS_OF_WEEK);
  return Object.keys(scheduleList)
    .filter((id) => {
      const scheduleItem = scheduleList[id];
      return !!days.find((day) => scheduleItem[day].IsSelected && scheduleItem[day].Classroom === classroomId);
    })
    .map((it) => scheduleList[it].memberId)
    .reduce((acc, nx) => {
      if (!acc.includes(nx)) return acc.concat([nx]);
      return acc;
    }, []);
}

const getConfirmationMessage = (classroomName, isClassroomAssigned) => {
  if (!isClassroomAssigned)
    return (
      <p>
        Are you sure you want to delete classroom <strong>{classroomName}</strong>?
      </p>
    );
  return (
    <>
      <p className="alert--error">This classroom cannot be deleted because it is assigned to some parties.</p>
      <p>Before you can delete this classroom the following parties need to be re-assigned:</p>
    </>
  );
};

const getContent = (isClassroomAssigned, memberIds, adults, students, classroomName) => {
  return (
    <div>
      {getConfirmationMessage(classroomName, isClassroomAssigned)}
      {isClassroomAssigned && (
        <div className="table table--assigned-parties">
          <ul className="table-header" key="header">
            <li>Party</li>
            <li>Type</li>
          </ul>
          {!isEmpty(memberIds) && (
            <>
              {memberIds.map((elem) => {
                const member = students[elem] || adults[elem];
                return (
                  <ul className="table-row" key={elem}>
                    <li>
                      <Link
                        to={
                          member.type === TYPE_STUDENT
                            ? `${ROUTE_STUDENT_PROFILE.replace(':id', member.id)}schedule/`
                            : `${ROUTE_STAFFMEMBER_PROFILE.replace(':id', member.id)}schedule/`
                        }
                        target="_blank"
                      >
                        {member.fullName}
                      </Link>
                    </li>
                    <li>{member.type}</li>
                  </ul>
                );
              })}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const TableRowAdminClassrooms = (props) => {
  const { classroomId, name, nickName, minMonths, maxMonths, ratio, maxSize } = props;
  const { isShowing, toggle } = useModal();
  const dispatch = useDispatch();
  const classroomName = nickName && nickName !== '' ? nickName : name;
  const classroom = useSelector((state) => state.classroom.list.items[classroomId]);
  const { isFetching, items } = useSelector((state) => state.staffSchedules);
  const adults = useSelector((state) => state.adults.items);
  const students = useSelector((state) => state.students.items);
  const memberIds = !isFetching && filterMembers(items, classroomId);
  // const studentIds = Object.keys(students).filter((student) => students[student].classroom === classroom.refId);
  const isClassroomAssigned = !isEmpty(memberIds);
  const isAdmin = name === 'Admin/Office';

  return (
    <>
      <TableRow key={classroomId}>
        <li className="tc--name">{classroomName}</li>
        <li className="tc--age-range">{isAdmin ? '' : `${minMonths}-${maxMonths} months`}</li>
        <li className="tc--ratio">{isAdmin ? '' : `1:${ratio}`}</li>
        <li className="tc--max-size">{isAdmin ? '' : `${maxSize}`}</li>
        <li className="tc--edit">
          <Link className="button--text" to={`${ROUTE_ADMIN_CLASSROOMS}${classroomId}/update`}>
            Edit
          </Link>
        </li>
        <li className="tc--delete">
          <Button
            className="button--text--neg"
            onClick={() => {
              if (!isFetching) toggle();
            }}
          >
            Delete
          </Button>
        </li>
      </TableRow>
      <ConfirmModal
        isShowing={isShowing}
        title="Delete Classroom"
        content={getContent(isClassroomAssigned, memberIds, adults, students, classroomName)}
        isRequesting={false}
        doAction={() => {
          if (!isClassroomAssigned) dispatch(updateClassroom(classroomId, { ...classroom, isArchived: true }));
        }}
        hide={toggle}
        disableConfirmation={isClassroomAssigned}
        confirmLabel="Delete Classroom"
        cancelLabel="Cancel"
      />
    </>
  );
};
TableRowAdminClassrooms.propTypes = {
  classroomId: PropTypes.string,
  name: PropTypes.string,
  nickName: PropTypes.string,
  minMonths: PropTypes.number,
  maxMonths: PropTypes.number,
  ratio: PropTypes.number,
  maxSize: PropTypes.number,
};
TableRowAdminClassrooms.defaultProps = {
  classroomId: '',
  name: 'N/A',
  nickName: 'N/A',
  minMonths: 0,
  maxMonths: 0,
  ratio: 1,
  maxSize: 0,
};

export default TableRowAdminClassrooms;
