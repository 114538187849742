import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  ROUTE_STUDENT_VIEW,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_ADULT_VIEW,
  TYPE_STUDENT,
  TYPE_STAFF,
  TYPE_ADULT,
  TYPE_PLANNED_ABSENCE,
} from 'constants/index';
import { Error, SubPageHeader, PageActions } from 'components/layout';
import { Table, TableRowPlannedAbsence } from 'components/table';
import { useSubscription } from 'utils/useSubscription';
import { subscribeToAbsences, unsubscribeFromAbsences } from 'actions';

const getAddAbsenceLinkUrl = (recordType, id) => {
  if (recordType === TYPE_STUDENT) {
    return `${ROUTE_STUDENT_VIEW}${id}/planned-absence/add`;
  }
  if (recordType === TYPE_STAFF) {
    return `${ROUTE_STAFFMEMBER_VIEW}${id}/planned-absence/add`;
  }
  return `${ROUTE_ADULT_VIEW}${id}/planned-absence/add`;
};
const headerLabels = [
  { label: 'Start Date', className: 'start-date' },
  { label: 'End Date', className: 'end-date' },
  { label: 'Reason', className: 'reason' },
  { label: '', className: 'edit' },
  { label: '', className: 'delete' },
];

const getAbsenceRows = (keys, items, recordType) =>
  keys
    .filter((it) => items[it].type === TYPE_PLANNED_ABSENCE)
    .map((it) => {
      const absence = { absenceId: it, ...items[it] };
      return <TableRowPlannedAbsence {...absence} key={it} originType={recordType} />;
    });

const PlannedAbsences = (props) => {
  const location = useLocation();
  const { id } = useParams();
  const { recordType } = props;
  const { items, keys, size, fetchError } = useSubscription(
    {
      subscribeFn: subscribeToAbsences,
      unsubscribeFn: unsubscribeFromAbsences,
      selectorKey: 'absences',
    },
    false,
    [id],
  );
  const { isFetching } = useSelector((state) => state.absences);

  const actions = [
    {
      key: 'Add-Planned-Absence',
      classNames: 'button--action button--icon--add',
      text: 'Add Planned Absence',
      isVisible: true,
      isLink: true,
      url: getAddAbsenceLinkUrl(recordType, id),
      referrer: location.pathname,
    },
  ];
  const tableProps = {
    id: 'planned-absences',
    rows: getAbsenceRows(keys, items, recordType),
    resultsRow: null,
    tableOptions: [],
    tableActions: [],
    headerLabels,
  };
  return (
    <>
      <SubPageHeader title="Planned Absences" actions={<PageActions actions={actions} />} />
      {!!fetchError && <Error error={fetchError} />}
      {!size && !isFetching && !fetchError && <Error error="No absences available for this party" />}
      {isFetching && <div className="loading" />}
      {!!size && <Table {...tableProps} />}
    </>
  );
};

PlannedAbsences.propTypes = {
  recordType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
};

export default PlannedAbsences;
